import React from "react"

import { useFetchProfessionalsData } from "../../hooks";
import { useProfessionalsStore } from "../../store"
import Pagination from "../Common/Pagination"

const ProfessionalsPagination = () => {
  const {
    search,
    totalPages,
    pageNumber,
    setPageNumber,
  } = useProfessionalsStore()
  const fetchProfessionalsData = useFetchProfessionalsData();
  const handlePagination = (e, value) => {
    setPageNumber(value)
    fetchProfessionalsData(search, value);
  }
  return (
    <Pagination.Root>
      <Pagination.Number
        totalPages={totalPages}
        pageNumber={pageNumber}
        action={handlePagination}
      />
    </Pagination.Root>
  )
}

export default ProfessionalsPagination
