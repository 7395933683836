import HeaderButton from "./HeaderButton"
import HeaderRoot from "./HeaderRoot"
import HeaderTitle from "./HeaderTitle"

export const Header = {
  Button: HeaderButton,
  Root: HeaderRoot,
  Title: HeaderTitle,
}

export default Header
