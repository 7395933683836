import { create } from "zustand"

const useAppStore = create((set) => ({
  modalController: "",
  pollingController: false,
  setModalController: (flag) => set({ modalController: flag }),
  setPollingController: (flag) => set({ pollingController: flag }),
  snackbarController: {
    open: false,
    message: "",
    severity: "",
    statusText: "",
    statusCode: "",
  },
  setSnackbarController: (flag) => set({ snackbarController: flag }),
}))

export default useAppStore
