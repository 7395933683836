import ClientsContent from "./ClientsContent"
import ClientsFilter from "./ClientsFilter"
import ClientsModal from "./ClientsModal"
import ClientsPagination from "./ClientsPagination"
import ClientsTable from "./ClientsTable"

const Clients = {
  Content: ClientsContent,
  Filter: ClientsFilter,
  Modal: ClientsModal,
  Pagination: ClientsPagination,
  Table: ClientsTable,
}

export default Clients
