import React from "react"

import Header from "../../components/Common/Header"
import Furnitures from "../../components/Furnitures"
import { useHandleModal } from "../../hooks"
import { useAppStore } from "../../store"

const FurnituresPage = () => {
  const { modalController } = useAppStore()
  const modalKey = "FurnitureCreateModal"
  const handleModal = useHandleModal()
  const title = "Lista de Móveis"
  const textButton = "Cadastrar"

  return (
    <>
      <Header.Root>
        <Header.Title title={title} />
        <Header.Button
          text={textButton}
          action={() => handleModal(modalKey)}
        />
      </Header.Root>
      <Furnitures.Content />
      {modalController === modalKey && <Furnitures.Modal showKey={modalKey} action="create" />}
    </>
  )
}

export default FurnituresPage
