import http from "../http-common"

const getAllClients = (
  company,
  name = "",
  page = 1,
) => {
  return http.get(`/clients?name=${name}&company=${company}&page=${page}`)
}

const getClients = (id) => {
  return http.get(`/clients/${id}`)
}

const createClients = (data) => {
  return http.post("/clients", data)
}

const updateClients = (id, data) => {
  return http.put(`/clients/${id}`, data)
}

const deleteClients = (id, company) => {
  return http.delete(`/clients/${id}?company=${company}`)
}

const ClientsDataService = {
  getAllClients,
  getClients,
  createClients,
  updateClients,
  deleteClients,
}

export default ClientsDataService
