import React from "react"

import { useFetchProfessionalsData } from "../../hooks";
import Filter from "../Common/Filter"

const ProfessionalsFilter = () => {
  const fetchProfessionalsData = useFetchProfessionalsData()

  let timeout
  const handleSearch = (event) => {
    clearTimeout(timeout)
    const searchValue = event.target.value
    timeout = setTimeout(async () => {
      fetchProfessionalsData(searchValue)
    }, 500)
  }
  return (
    <Filter.Root>
      <Filter.InputRoot width="100%">
        <Filter.Title text="Filtros" />
      </Filter.InputRoot>
      <Filter.InputRoot>
        <Filter.Label text="Nome" testId="FilterSearchName" />
        <Filter.Input width="39%" action={handleSearch} />
      </Filter.InputRoot>
    </Filter.Root>
  )
}

export default ProfessionalsFilter
