/* eslint-disable max-len */
/* eslint-disable consistent-return */
import React from "react";

import { useAppStore } from "../../../store";

const SnackbarAlert = () => {
  const { snackbarController } = useAppStore();
  const {
    message,
    severity,
    statusText,
    statusCode,
  } = snackbarController;

  switch (severity) {
  case "success":
    return (
      <div
        className="flex content-center flex-col flex-nowrap items-start"
        data-testid="alertRoot"
      >
        <h4 className="text-white text-lg font-bold block">
          {message}
        </h4>

        {statusCode && (
          <p className="text-white text-base block">
            <strong>Status Code: </strong>
            {statusCode}
          </p>
        )}
        {statusText && (
          <p className="text-white text-base block">
            <strong>Erro: </strong>
            {statusText}
          </p>
        )}
      </div>
    )
  case "error":
    return (
      <div
        className="flex content-center flex-col flex-nowrap items-start"
        data-testid="alertRoot"
      >
        <h4 className="text-white text-lg font-bold block">
          {message}
        </h4>

        {statusCode && (
          <p className="text-white text-base block">
            <strong>Status Code: </strong>
            {statusCode}
          </p>
        )}
        {statusText && (
          <p className="text-white text-base block">
            <strong>Erro: </strong>
            {statusText}
          </p>
        )}
      </div>
    )
  default:
    break;
  }
};

export default SnackbarAlert;
