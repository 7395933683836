/* eslint-disable max-len */
import React from "react";

import PropTypes from "prop-types";

import UtilsComponents from "../UtilsComponents"

const PaginationNumber = ({ action, totalPages, pageNumber }) => {
  const isFinalPage = pageNumber === totalPages;
  return (
    <div className="inline-flex p-4">
      <ul data-testid="ulNavigation" className="flex items-center -mx-[6px]">
        <li className="px-[6px]">
          <button
            type="button"
            aria-label="Previous"
            className="w-9 h-9 flex items-center justify-center rounded-md border border-solid border-neutral800 text-neutral800 text-base hover:bg-primary hover:border-primary hover:opacity-40"
            onClick={() => action(pageNumber - 1)}
            data-testid="paginationPrev"
          >
            <span>
              <UtilsComponents.Icon
                name="ArrowLeft"
                size="24"
                color="#000000"
                className="fill-current stroke-current"
              />
            </span>
          </button>
        </li>
        {Array.from(Array(totalPages).keys()).map((item) => (
          <li key={item} className="px-[6px]">
            <button
              data-testid={`paginationNumber${item + 1}`}
              type="button"
              className="w-9 h-9 flex items-center justify-center rounded-md border border-solid border-neutral800 text-neutral800 text-base hover:bg-primary hover:border-primary hover:opacity-40"
              onClick={() => action(item + 1)}
              style={{
                borderColor: pageNumber === item + 1 ? "rgb(133 77 14)" : "",
                color: pageNumber === item + 1 ? "rgb(133 77 14)" : "",
              }}
            >
              {item + 1}
            </button>
          </li>
        ))}
        {!isFinalPage && (
          <li className="px-[6px]">
            <button
              type="button"
              aria-label="Next"
              className="w-9 h-9 flex items-center justify-center rounded-md border border-solid border-neutral800 text-neutral800 text-base hover:opacity-40"
              onClick={() => action(pageNumber + 1)}
              data-testid="paginationNext"
            >
              <span>
                <UtilsComponents.Icon
                  name="ArrowRight"
                  size="24"
                  color="#000000"
                  className="fill-current stroke-current"
                />
              </span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

PaginationNumber.propTypes = {
  action: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
};

export default PaginationNumber;
