import React from "react"

import { useCompanyStore } from "../../store"

const MenuLogo = () => {
  const { company } = useCompanyStore()
  const { logo, fantasy_name } = company
  return (
    <img
      data-testid="menuLogo"
      className="w-full h-auto block mt-0 mx-auto mb-5"
      style={{
        maxWidth: "100px",
      }}
      src={logo}
      alt={fantasy_name}
    />
  )
}

export default MenuLogo
