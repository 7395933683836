import React from "react"

import { useFetchUsersData } from "../../hooks"
import Filter from "../Common/Filter"

const UsersFilter = () => {
  const fetchUsersData = useFetchUsersData()

  let timeout
  const handleSearch = (event) => {
    clearTimeout(timeout)
    const searchValue = event.target.value
    timeout = setTimeout(async () => {
      fetchUsersData(searchValue)
    }, 500)
  }

  return (
    <Filter.Root>
      <Filter.InputRoot width="100%">
        <Filter.Title text="Filtros" />
      </Filter.InputRoot>
      <Filter.InputRoot width="39%">
        <Filter.Label text="Nome" testId="FilterSearchName" />
        <Filter.Input action={handleSearch} />
      </Filter.InputRoot>
    </Filter.Root>
  )
}

export default UsersFilter
