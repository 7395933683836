/* eslint-disable consistent-return */
import React from "react";

import { useHandleSnackbar } from "../../../hooks"
import { useAppStore } from "../../../store"
import UtilsComponents from "../UtilsComponents"

const SnackbarRoot = ({ children }) => {
  const { snackbarController } = useAppStore()
  const { severity } = snackbarController
  const handleSnackbar = useHandleSnackbar()
  const handleCloseSnackbar = () => {
    handleSnackbar({ open: false, message: "", severity: "" })
  }
  switch (severity) {
  case "success":
    return (
      <div
        className="max-w-xs rounded-md shadow-lg min-w-[360px] fixed top-3 left-1/2 -translate-x-1/2 flex flex-nowrap flex-row content-center items-center bg-green600 p-4 text-white"
        role="alert"
        data-testid="snackbarRoot"
      >
        <UtilsComponents.Icon
          name="CheckCircle"
          size="24"
          color="#ffffff"
          className="mr-2"
        />
        {children}
        <div className="ml-auto">
          <button
            onClick={handleCloseSnackbar}
            type="button"
            className="flex flex-shrink-0 justify-center items-center h-auto w-auto rounded-md text-white/[.5] hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-600 transition-all text-sm dark:focus:ring-offset-gray-900 dark:focus:ring-gray-800"
            data-testid="snackbarClose"
          >
            <span className="sr-only">Close</span>
            <UtilsComponents.Icon
              name="XCircle"
              size="24"
              color="#ffffff"
              className=""
            />
          </button>
        </div>
      </div>
    )
  case "error":
    return (
      <div
        className="max-w-xs rounded-md shadow-lg mb-3 ml-3 min-w-[360px]
        fixed top-3 left-1/2 -translate-x-1/2 flex flex-nowrap flex-row content-center items-center bg-red p-4 text-white"
        role="alert"
        data-testid="snackbarRoot"
      >
        <UtilsComponents.Icon
          name="XCircle"
          size="24"
          color="#ffffff"
          className="mr-2"
        />
        {children}
        <div className="ml-auto">
          <button
            onClick={handleCloseSnackbar}
            type="button"
            className="flex flex-shrink-0 justify-center items-center h-auto w-auto rounded-md text-white/[.5] hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-600 transition-all text-sm dark:focus:ring-offset-gray-900 dark:focus:ring-gray-800"
            data-testid="snackbarClose"
          >
            <span className="sr-only">Close</span>
            <UtilsComponents.Icon
              name="XCircle"
              size="24"
              color="#ffffff"
              className=""
            />
          </button>
        </div>
      </div>
    )
  default:
    break;
  }
}

export default SnackbarRoot
