import React from "react"

import { useFetchBudgetsData } from "../../hooks";
import {
  useClientsStore,
  useBudgetsStore,
  useUserStore,
  useProfessionalsStore,
} from "../../store";
import Filter from "../Common/Filter"

const BudgetsFilter = () => {
  const fetchBudgetsData = useFetchBudgetsData()
  const {
    searchId,
    searchClient,
    searchAttendant,
    searchProfessional,
    searchDate,
  } = useBudgetsStore()
  const { clientsList } = useClientsStore()
  const { usersList } = useUserStore()
  const { professionalsList } = useProfessionalsStore()

  let timeout
  const handleSearch = (event) => {
    clearTimeout(timeout)
    const idValue = event.target.value
    timeout = setTimeout(async () => {
      fetchBudgetsData(
        idValue,
        searchClient,
        searchAttendant,
        searchProfessional,
        searchDate,
      )
    }, 500)
  }

  const handleClients = async (event) => {
    const clientsValue = event.target.value;
    await fetchBudgetsData(
      searchId,
      clientsValue,
      searchAttendant,
      searchProfessional,
      searchDate,
    );
  };

  const handleAttendant = async (event) => {
    const attendantValue = event.target.value;
    await fetchBudgetsData(
      searchId,
      searchClient,
      attendantValue,
      searchProfessional,
      searchDate,
    );
  };

  const handleProfessional = async (event) => {
    const professionalValue = event.target.value;
    await fetchBudgetsData(
      searchId,
      searchClient,
      searchAttendant,
      professionalValue,
      searchDate,
    );
  };

  return (
    <Filter.Root>
      <Filter.InputRoot width="100%">
        <Filter.Title text="Filtros" />
      </Filter.InputRoot>
      <Filter.InputRoot name="id" width="20%">
        <Filter.Label text="Número" testId="FilterSearchNumber" />
        <Filter.Input action={handleSearch} />
      </Filter.InputRoot>
      <Filter.InputRoot name="date" width="20%">
        <Filter.Label text="Data" testId="FilterSearchNumber" />
        <Filter.Input
          action={handleSearch}
          type="date"
        />
      </Filter.InputRoot>
      <Filter.InputRoot
        name="clients"
        width="20%"
      >
        <Filter.Label
          text="Cliente"
          testId="clientsLabel"
        />
        <Filter.Select
          name="client"
          action={handleClients}
          dataList={clientsList}
          testId="clientsInputFilter"
        />
      </Filter.InputRoot>
      <Filter.InputRoot
        name="users"
        width="20%"
      >
        <Filter.Label
          text="Atendente"
          testId="usersLabel"
        />
        <Filter.Select
          name="client"
          action={handleAttendant}
          dataList={usersList}
          testId="usersInputFilter"
        />
      </Filter.InputRoot>
      <Filter.InputRoot
        name="professionals"
        width="20%"
      >
        <Filter.Label
          text="Profissional"
          testId="professionalsLabel"
        />
        <Filter.Select
          name="professionals"
          action={handleProfessional}
          dataList={professionalsList}
          testId="professionalsInputFilter"
        />
      </Filter.InputRoot>
    </Filter.Root>
  )
}

export default BudgetsFilter
