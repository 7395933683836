import React from "react"

const MenuUserRoot = ({ children }) => {
  return (
    <div
      data-testid="menuUserRoot"
      className="w-full border-dotted border-b border-t border-0 border-[#000] flex flex-nowrap flex-row justify-flex-start items-left content-center items-center my-4 py-4"
    >
      {children}
    </div>
  )
}

export default MenuUserRoot
