/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react"
import { useForm } from "react-hook-form"

import FormData from "form-data"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"

import BudgetsViewer from "../"
import { useHandleSnackbar } from "../../../hooks"
import { useUserStore, useClientsStore } from "../../../store"
import {
  maskPhone,
  maskCPF,
  maskCNPJ,
  maskCEP,
} from "../../../utils"
import Input from "../../Common/Modal/Input"

const BudgetsViewerAddClient = ({ actionSave, actionRemove }) => {
  const [typePerson, setTypePerson] = useState({})
  const [client, setClient] = useState({})
  const handleSnackbar = useHandleSnackbar()
  const { createClients } = useClientsStore()
  const { user: userInfo } = useUserStore()
  const { company: userCompany } = userInfo
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      telephone: "",
      cpf: "",
      cnpj: "",
      type_person: "",
      address: "",
      number: "",
      cep: "",
      complement: "",
      history: "",
      company: userCompany,
      city: "",
      state: "",
      neighborhood: "",
      state_registration: "",
    },
  })
  const typeList = [
    {
      id: "0",
      label: "Escolha um tipo",
    },
    {
      id: "PF",
      label: "PF",
    },
    {
      id: "PJ",
      label: "PJ",
    },
  ]

  const handleTypePerson = (e) => {
    setTypePerson(e.target.value)
  }

  const onSubmit = useCallback(async (data) => {
    const clientData = new FormData()
    clientData.append("id", data.id)
    clientData.append("name", data.name)
    clientData.append("telephone", data.telephone)
    clientData.append("cpf", data.cpf)
    clientData.append("cnpj", data.cnpj)
    clientData.append("type_person", data.type_person)
    clientData.append("address", data.address)
    clientData.append("number", data.number)
    clientData.append("cep", data.cep)
    clientData.append("complement", data.complement)
    clientData.append("city", data.city)
    clientData.append("state", data.state)
    clientData.append("neighborhood", data.neighborhood)
    clientData.append("state_registration", data.state_registration)
    clientData.append("history", [])
    clientData.append("company", userCompany)
    try {
      const { message, response } = await createClients(clientData)
      setClient(response)
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      }
      handleSnackbar(snackbarParams)
    } catch (errors) {
      const {
        message = "Erro ao criar Cliente",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
    // reset()
  // eslint-disable-next-line max-len
  }, [createClients, handleSnackbar, reset, userCompany])

  return (
    <BudgetsViewer.Form action={handleSubmit(onSubmit)}>
      <BudgetsViewer.Table
        bgColor="bg-white"
        width="w-full"
        margin="mt-5"
      >
        <BudgetsViewer.Body>

          <BudgetsViewer.Row>
            <BudgetsViewer.HeaderCell
              type="text"
              text="Dados do Cliente"
              textColor="text-white"
              colSpan="7"
            />
          </BudgetsViewer.Row>

          <BudgetsViewer.Row>
            <BudgetsViewer.HeaderCell
              type="text"
              text="Nome"
              textColor="text-white"
              colSpan="2"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Telefone"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="CPF / CNPJ"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Inscrição Estadual"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Estado"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Cidade"
              textColor="text-white"
            />
          </BudgetsViewer.Row>
          <BudgetsViewer.Row>
            <BudgetsViewer.Cell
              type="add"
              colSpan="2"
            >
              <Input.Text
                register={register}
                name="name"
                testId="inputClientName"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.TextWithMask
                errors={errors}
                register={register}
                name="cnpj"
                action={maskPhone}
                testId="inputTelephone"
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              {isEmpty(typePerson) && (
                <Input.Select
                  register={register}
                  name="type_person"
                  testId="inputType"
                  action={handleTypePerson}
                  dataList={typeList}
                />
              )}
              {typePerson === "PF" && (
                <Input.TextWithMask
                  register={register}
                  name="cpf"
                  action={maskCPF}
                  testId="inputCpf"
                />
              )}
              {typePerson === "PJ" && (
                <Input.TextWithMask
                  register={register}
                  name="cnpj"
                  action={maskCNPJ}
                  testId="inputCnpj"
                />
              )}
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.Text
                register={register}
                name="state_registration"
                testId="inputClientStateReg"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.Text
                register={register}
                name="state"
                testId="inputClientState"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.Text
                register={register}
                name="city"
                testId="inputClientCity"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
          </BudgetsViewer.Row>

          <BudgetsViewer.Row>
            <BudgetsViewer.HeaderCell
              type="text"
              text="Endereço"
              textColor="text-white"
              colSpan="2"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Número"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Complemento"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Bairro"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="CEP"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Ações"
              textColor="text-white"
            />
          </BudgetsViewer.Row>
          <BudgetsViewer.Row>
            <BudgetsViewer.Cell
              colSpan="2"
              type="add"
            >
              <Input.Text
                register={register}
                name="address"
                testId="inputClientAddress"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.Text
                register={register}
                name="number"
                testId="inputClientNumber"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.Text
                register={register}
                name="complement"
                testId="inputClientComplemment"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.Text
                register={register}
                name="neighborhood"
                testId="inputClientNeighborhood"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.TextWithMask
                register={register}
                name="cep"
                action={maskCEP}
                testId="inputCep"
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              {isEmpty(client) && (
                <BudgetsViewer.Button
                  text="+"
                  width="w-1/2"
                  bgColor="bg-[#007900]"
                  action={actionSave}
                />
              )}
              <BudgetsViewer.Button
                text="x"
                action={actionRemove}
                bgColor="bg-[#ae3013]"
                width="w-1/2"
              />
            </BudgetsViewer.Cell>
          </BudgetsViewer.Row>

          {/* <BudgetsViewer.Row>
            <BudgetsViewer.HeaderCell
              type="text"
              text="Estado"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Cidade"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="CEP"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Ações"
              textColor="text-white"
            />
          </BudgetsViewer.Row>
          <BudgetsViewer.Row>
            <BudgetsViewer.Cell type="add">
              <Input.Text
                register={register}
                name="state"
                testId="inputClientState"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.Text
                register={register}
                name="city"
                testId="inputClientCity"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.Text
                register={register}
                name="cep"
                testId="inputClientCep"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              {isEmpty(client) && (
                <BudgetsViewer.Button
                  text="+"
                  width="w-1/2"
                  bgColor="bg-[#007900]"
                  action={actionSave}
                />
              )}
              <BudgetsViewer.Button
                text="x"
                action={actionRemove}
                bgColor="bg-[#ae3013]"
                width="w-1/2"
              />
            </BudgetsViewer.Cell>
          </BudgetsViewer.Row> */}

        </BudgetsViewer.Body>
      </BudgetsViewer.Table>
    </BudgetsViewer.Form>
  )
}

BudgetsViewerAddClient.propTypes = {
  actionSave: PropTypes.func.isRequired,
  actionRemove: PropTypes.func.isRequired,
};

export default BudgetsViewerAddClient
