import React from "react"

import PropTypes from "prop-types"

const ContentHeaderCell = ({ text, align }) => {
  return (
    <th
      data-testid="contentHeaderCell"
      align={align}
      className="px-6 py-4 whitespace-nowrap text-base border border-solid border-[#a8a1a1] text-neutral800 font-bold bg-[#c0b7b7]"
    >
      {text}
    </th>
  )
}

ContentHeaderCell.propTypes = {
  align: PropTypes.string,
  text: PropTypes.string,
};

ContentHeaderCell.defaultProps = {
  text: "",
  align: "center",
}
export default ContentHeaderCell
