import React from "react"

import PropTypes from "prop-types"

const FilterInput = ({
  action,
  name,
  type,
}) => {
  const testId = name ? `${name}FilterInput` : "filterInput"
  return (
    <input
      data-testid={testId}
      className="w-full h-10 px-2 rounded-md border border-solid border-neutral800 focus:border-yellow800 hover:border-yellow800 bg-stone200"
      onChange={action}
      name={name}
      type={type}
    />
  )
}

FilterInput.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  action: PropTypes.func.isRequired,
}

FilterInput.defaultProps = {
  name: "",
  type: "text",
}

export default FilterInput
