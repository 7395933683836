import ContentBody from "./ContentBody"
import ContentCell from "./ContentCell"
import ContentDelete from "./ContentDelete"
import ContentEdit from "./ContentEdit"
import ContentHeader from "./ContentHeader"
import ContentHeaderCell from "./ContentHeaderCell"
import ContentHighlight from "./ContentHighlight"
import ContentLink from "./ContentLink"
import ContentRoot from "./ContentRoot"
import ContentRow from "./ContentRow"
import ContentTable from "./ContentTable"

const Content = {
  Body: ContentBody,
  Cell: ContentCell,
  HeaderCell: ContentHeaderCell,
  Delete: ContentDelete,
  Edit: ContentEdit,
  Header: ContentHeader,
  Highlight: ContentHighlight,
  Link: ContentLink,
  Root: ContentRoot,
  Row: ContentRow,
  Table: ContentTable,
}

export default Content
