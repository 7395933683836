import { FurnituresDataService } from "../services"
import { CustomError } from "../utils";

export const getAllFurnitures = async (
  companyUser,
  search = "",
  page = 1,
  category = "",
  factory = "",
  highlight = "",
  type = "",
) => {
  try {
    const { data } = await FurnituresDataService.getAll(
      companyUser,
      search,
      page,
      category,
      factory,
      highlight,
      type,
    )
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao pegar lista de Móveis"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const createFurniture = async (dataFurniture) => {
  try {
    const { data } = await FurnituresDataService.create(dataFurniture)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao criar Móvel"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const updateFurniture = async (furnitureId, dataFurniture) => {
  try {
    const { data } = await FurnituresDataService.update(furnitureId, dataFurniture)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao atualizar Móvel"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const deleteFurniture = async (company, id) => {
  try {
    const { data } = await FurnituresDataService.remove(company, id)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao deletar Móvel"
    throw new CustomError(message, statusText, status, errors)
  }
}
