import React from "react"

import PropTypes from "prop-types"

const ModalBlock = ({ children, width }) => {
  return (
    <div
      data-testid="modalFormBlock"
      className="p-1 flex flex-row content-center justify-around items-start flex-nowrap"
      style={{ width }}
    >
      {children}
    </div>
  )
}

ModalBlock.propTypes = {
  width: PropTypes.string,
};

ModalBlock.defaultProps = {
  width: "100%",
};

export default ModalBlock
