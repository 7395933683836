import http from "../http-common"

const getAllFinishes = (
  company,
  name = "",
  material = "",
  factory = "",
  type = "",
  page = 1,
) => {
  return http.get(`/finishes?name=${name}&company=${company}&material=${material}&factory=${factory}&type=${type}&page=${page}`)
}

const getFinishes = (id) => {
  return http.get(`/finishes/${id}`)
}

const createFinishes = (data) => {
  return http.post("/finishes", data)
}

const updateFinishes = (id, data) => {
  return http.put(`/finishes/${id}`, data)
}

const deleteFinishes = (id, company) => {
  return http.delete(`/finishes/${id}?company=${company}`)
}

const FinishesDataService = {
  getAllFinishes,
  getFinishes,
  createFinishes,
  updateFinishes,
  deleteFinishes,
}

export default FinishesDataService
