import React, { useCallback } from "react"
import { useForm } from "react-hook-form"

import FormData from "form-data"
import PropTypes from "prop-types"

import { useHandleModal, useHandleSnackbar } from "../../hooks"
import { useUserStore, useCategoryStore, useAppStore } from "../../store"
import { ModalHeader, Input, Modal } from "../Common/Modal"

const CategoriesModal = ({ showKey, category, action }) => {
  const handleModal = useHandleModal()
  const handleSnackbar = useHandleSnackbar()
  const { modalController } = useAppStore()
  const { createCategory, updateCategory } = useCategoryStore()
  const { user: userInfo } = useUserStore()
  const { company: userCompany } = userInfo
  const { id = "", label = "", description } = category

  const buttonText = id ? "Atualizar" : "Cadastrar"
  const headerText = id ? "Editar Categoria" : "Cadastrar Categoria"

  const isCreating = action === "create"
  const isUpdating = action === "update"

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id,
      name: label,
      description,
      company: userCompany,
    },
  })

  const onSubmit = useCallback(async (data) => {
    const categoryData = new FormData()
    categoryData.append("id", data.id)
    categoryData.append("name", data.name)
    categoryData.append("description", data.description)
    categoryData.append("company", userCompany)

    if (isUpdating) {
      try {
        const { message } = await updateCategory(id, categoryData)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao atualizar categoria",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    } else if (isCreating) {
      try {
        const { message } = await createCategory(categoryData)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao criar categoria",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    }
    reset()
    handleModal()
  // eslint-disable-next-line max-len
  }, [createCategory, handleModal, handleSnackbar, id, isCreating, isUpdating, reset, updateCategory, userCompany])
  return (
    <Modal.Root
      maxWidth="45%"
      isOpen={modalController === showKey && modalController !== ""}
    >
      <ModalHeader.Root>
        <ModalHeader.Title title={headerText} />
      </ModalHeader.Root>
      <Modal.Form action={handleSubmit(onSubmit)}>
        <Modal.Container>
          <Modal.Block>
            <Input.Root
              name="name"
            >
              <Input.Label
                label="Nome"
                name="name"
              />
              <Input.Text
                type="text"
                defaultValue={label}
                register={register}
                name="name"
                testId="inputName"
                errors={errors}
                required
              />
              {errors.name && <Input.HelperText text="Este campo é obrigatório" /> }
            </Input.Root>
          </Modal.Block>
          <Modal.Block>
            <Input.Root
              name="description"
            >
              <Input.Label
                label="Descrição"
                name="description"
              />
              <Input.Text
                type="text"
                defaultValue={description}
                register={register}
                name="description"
                testId="inputDescription"
              />
            </Input.Root>
          </Modal.Block>
          <Modal.Block>
            <Input.Button text={buttonText} />
          </Modal.Block>
        </Modal.Container>
      </Modal.Form>
      <Modal.Close action={handleModal} />
    </Modal.Root>
  )
}

CategoriesModal.propTypes = {
  showKey: PropTypes.string.isRequired,
  category: PropTypes.object,
  action: PropTypes.string.isRequired,
};

CategoriesModal.defaultProps = {
  category: {},
};

export default CategoriesModal
