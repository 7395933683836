import { useHandleSnackbar } from "."
import { useUserStore, useCategoryStore } from "../store"

const useFetchCategoriesData = () => {
  const { user } = useUserStore()
  const { getCategories } = useCategoryStore()
  const handleSnackbar = useHandleSnackbar()
  const { company } = user
  const fetchCategoriesData = async (searchValue = "") => {
    try {
      await getCategories(company, searchValue)
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Categorias",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
  }
  return fetchCategoriesData
}

export default useFetchCategoriesData
