import { useHandleSnackbar } from "."
import { useUserStore, useBudgetsStore } from "../store"

const useFetchBudgetsData = () => {
  const { user } = useUserStore()
  const {
    getBudgets,
    pageNumber,
    searchId,
  } = useBudgetsStore()
  const handleSnackbar = useHandleSnackbar()
  const { company } = user
  const fetchBudgetsData = async (
    searchValue = searchId,
    searchClient = "",
    searchAttendant = "",
    searchProfessional = "",
    searchDate = "",
  ) => {
    try {
      await getBudgets(
        company,
        searchValue,
        searchClient,
        searchAttendant,
        searchProfessional,
        searchDate,
        pageNumber,
      )
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Orçåmentos",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
  }
  return fetchBudgetsData
}

export default useFetchBudgetsData
