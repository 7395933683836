import React from "react"

import PropTypes from "prop-types"

const BudgetsViewerTable = ({
  children,
  bgColor,
  width,
  margin,
}) => {
  const tableStyle = `table table-striped table-bordered table-hover table-sm rounded-md p-3 overflow-x-auto ${bgColor} ${width} ${margin}`

  return (
    <table
      className={tableStyle}
    >
      {children}
    </table>
  )
}

BudgetsViewerTable.propTypes = {
  bgColor: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
};

BudgetsViewerTable.defaultProps = {
  width: "w-full",
  margin: "",
  bgColor: "",
}

export default BudgetsViewerTable
