import React from "react";
import Select from "react-select";

import { isEmpty } from "lodash"
import PropTypes from "prop-types"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: state.isFocused ? "#854d0e" : "#000",
    backgroundColor: "rgb(231,229,228,1)",
    borderRadius: "0.375rem",
    cursor: "pointer",
    width: "100%",
    height: "3rem",
    padding: "0",
    marginTop: "0.5rem",
    overflow: "overlay",
    position: "relative",
    outline: "none",
    "&:hover": {
      borderColor: "#854d0e",
    },
    boxShadow: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#333" : "rgb(231,229,228,1)",
    color: state.isSelected ? "#fff" : "#333",
    borderRadius: "0.375rem",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#854d0e",
      color: "#fff",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "3rem",
  }),
  multiValue: (provided) => ({
    ...provided,
    border: "1px solid #000",
    width: "100%",
  }),
};

const InputMultiSelect = ({
  errors,
  name,
  required,
  disabled,
  defaultValue,
  dataList,
  testId,
  action,
  margin,
}) => {
  const isError = required && !isEmpty(errors[name])
  const styleDiv = `w-full z-10 ${margin}`
  return (
    <div className={styleDiv}>
      <Select
        defaultValue={defaultValue}
        disabled={disabled}
        id={name}
        data-testid={testId}
        name={name}
        style={{
          borderColor: isError ? "#EF4444" : "",
          maxWidth: "100%",
        }}
        isMulti
        isSearchable
        options={dataList}
        styles={customStyles}
        classNamePrefix="select"
        onChange={action}
      />
    </div>
  );
}

InputMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  testId: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  margin: PropTypes.string,
}

InputMultiSelect.defaultProps = {
  testId: "inputSelect",
  errors: {},
  required: false,
  disabled: false,
  defaultValue: undefined,
  margin: "",
}

export default InputMultiSelect
