import React from "react";

import * as Icons from "lucide-react";
import PropTypes from "prop-types"

const Icon = ({
  name,
  size,
  color,
  ...props
}) => {
  const IconComponent = Icons[name];
  return (
    <IconComponent
      size={size}
      color={color}
      strokeWidth={2}
      {...props}
    />
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}

export default Icon;
