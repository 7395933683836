import React from "react"
import { Link } from "react-router-dom"

import PropTypes from "prop-types"

const ContentLink = ({ children, location, title }) => {
  return (
    <Link
      data-testid="menuListItemLink"
      to={location}
      title={title}
      className="cursor-pointer hover:opacity-70 mx-1"
      target="_blank"
    >
      {children}
    </Link>
  )
}

ContentLink.propTypes = {
  location: PropTypes.string.isRequired,
  title: PropTypes.string,
}

ContentLink.defaultProps = {
  title: "",
}

export default ContentLink
