import UsersContent from "./UsersContent"
import UsersFilter from "./UsersFilter"
import UsersModal from "./UsersModal"
import UsersPagination from "./UsersPagination"
import UsersTable from "./UsersTable"

const Users = {
  Content: UsersContent,
  Filter: UsersFilter,
  Modal: UsersModal,
  Pagination: UsersPagination,
  Table: UsersTable,
}

export default Users
