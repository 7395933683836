import { create } from "zustand"

import { getAllRoles } from "../actions/rolesActions"
import { CustomError } from "../utils"

const useRolesStore = create((set, get) => ({
  rolesList: [],
  setRolesList: (rolesList) => {
    set(() => ({
      rolesList,
    }))
  },
  getRolesList: async (company, name = "") => {
    try {
      const { setRolesList } = get()
      const rolesList = []
      const data = await getAllRoles(company, name)
      data.rows.forEach((element) => {
        const responseRoles = {
          id: element.id,
          label: element.name,
        }
        rolesList.push(responseRoles)
      })
      setRolesList(rolesList)
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Cargos",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  getRoleLabel: (id) => {
    const { rolesList } = get()
    const roleLabel = rolesList.find((element) => element.id == id)
    return roleLabel
  },
}))

export default useRolesStore
