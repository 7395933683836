import React from "react"

const ContentRow = ({ children }) => {
  return (
    <tr
      data-testid="contentRow"
      className="w-full"
    >
      {children}
    </tr>
  )
}

export default ContentRow
