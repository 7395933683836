/* eslint-disable consistent-return */
import React from "react"

import PropTypes from "prop-types"

import { useCompanyStore } from "../../../store"
import { capitalizeFirstLetter } from "../../../utils/transformString"

const ContentCell = ({
  children,
  type,
  align,
  photo,
  text,
  hasBorder,
}) => {
  const { company } = useCompanyStore()
  const { logo, name } = company
  switch (type) {
  case "text":
    return (
      <td
        data-testid="contentCell"
        align={align}
        className="px-6 py-4 whitespace-nowrap text-base border border-solid border-[#a8a1a1] text-neutral800 capitalize"
      >
        {capitalizeFirstLetter(text)}
      </td>
    )
  case "array":
    return (
      <td
        data-testid="contentCell"
        align={align}
        className="px-6 py-4 whitespace-nowrap text-base border border-solid border-[#a8a1a1] text-neutral800"
      >
        {
          text.map((item) => (
            <React.Fragment key={item.id}>
              <p className="font-light text-sm">
                [
                {item.width}
                x
                {item.height}
                x
                {item.depth}
                ]
                {" "}
                :
              </p>
              {" "}
              <p className="font-bold">{item.price}</p>
              <br />
            </React.Fragment>
          ))
        }
      </td>
    )
  case "photo":
    return (
      <td
        data-testid="contentCell"
        align={align}
        className="px-6 py-4 whitespace-nowrap text-base border border-solid border-[#a8a1a1] text-neutral800"
      >
        {photo ? (
          <img
            className="w-full h-auto rounded-full"
            style={{
              borderRadius: hasBorder ? "100%" : "0%",
              minWidth: "100px",
              maxWidth: "150px",
            }}
            src={photo}
            alt={capitalizeFirstLetter(text)}
            data-testid={`cellPhoto${capitalizeFirstLetter(text)}`}
          />
        ) : (
          <img
            className="h-auto w-full rounded-full bg-brown700 p-3"
            style={{
              borderRadius: hasBorder ? "100%" : "0%",
              minWidth: "100px",
              maxWidth: "150px",
            }}
            src={logo}
            alt={name}
            data-testid="cellPhotoDefault"
          />
        )}
      </td>
    )
  case "button":
    return (
      <td
        data-testid="contentCell"
        align={align}
        className="w-10 px-6 py-4 whitespace-nowrap text-base border border-solid border-[#a8a1a1] text-neutral800"
      >
        <div className=" flex flex-row justify-end items-center">
          {children}
        </div>
      </td>
    )
  default:
    break
  }
}

ContentCell.propTypes = {
  type: PropTypes.any.isRequired,
  align: PropTypes.string,
  photo: PropTypes.string,
  text: PropTypes.string,
  hasBorder: PropTypes.bool,
};

ContentCell.defaultProps = {
  photo: "",
  text: "",
  align: "center",
  hasBorder: false,
}

export default ContentCell
