/* eslint-disable max-len */
import React from "react";

import PropTypes from "prop-types";

const FilterInputSelect = ({
  dataList,
  width,
  action,
  name,
}) => {
  const testId = name ? `${name}FilterInputSelect` : "filterInputSelect";
  return (
    <select
      data-testid={testId}
      className="w-full h-10 px-2 rounded-md border border-solid border-neutral800 focus:border-yellow800 hover:border-yellow800 bg-stone200 cursor-pointer"
      style={{ width: `${width}` }}
      onChange={action}
      name={name}
    >
      {dataList.map((item) => (
        <option
          data-testid={`filterInputItem${item.id}`}
          key={item.id}
          value={item.id}
        >
          {item.label}
        </option>
      ))}
    </select>
  )
};

FilterInputSelect.propTypes = {
  width: PropTypes.string,
  name: PropTypes.string,
  action: PropTypes.func,
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
};

FilterInputSelect.defaultProps = {
  width: "100%",
  name: "",
  action: () => {},
};

export default FilterInputSelect;
