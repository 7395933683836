import React from "react"

import PropTypes from "prop-types"

const ModalRoot = ({
  children,
  maxWidth,
}) => {
  return (
    <div
      className="z-10 fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50"
      data-testid="modalRootBg"
    >
      <div
        className="bg-brown600 w-full absolute top-0 right-0 border-l-lg p-4 shadow-[0px_0px_25px_2px_rgba(0,0,0,0.75)] z-50 h-screen"
        data-testid="modalRoot"
        style={{ maxWidth: `${maxWidth}` }}
      >
        {children}
      </div>
    </div>
  )
}

ModalRoot.propTypes = {
  maxWidth: PropTypes.string,
};

ModalRoot.defaultProps = {
  maxWidth: "710px",
};

export default ModalRoot
