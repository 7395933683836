import { create } from "zustand"

import {
  getAllFurnitures,
  createFurniture,
  updateFurniture,
  deleteFurniture,
} from "../actions/furnituresActions"
import { sortListBy, CustomError } from "../utils"

const useFurnituresStore = create((set, get) => ({
  search: "",
  searchCategory: "",
  searchFactory: "",
  searchHighlight: "",
  searchType: "",
  pageNumber: 1,
  furnitureList: [],
  totalPages: 1,
  totalItems: "0",

  setSearch: (search) => {
    set({ search })
  },
  setSearchCategory: (searchCategory) => {
    set({ searchCategory })
  },
  setSearchFactory: (searchFactory) => {
    set({ searchFactory })
  },
  setSearchHighlight: (searchHighlight) => {
    set({ searchHighlight })
  },
  setSearchType: (searchType) => {
    set({ searchType })
  },
  setPageNumber: (pageNumber) => {
    set({ pageNumber })
  },
  setFurnitureList: (furnitureList) => {
    set({ furnitureList })
  },
  setTotalPages: (totalPages) => {
    set({ totalPages })
  },
  setTotalItems: (totalItems) => {
    set({ totalItems })
  },

  getFurnitureList: async (
    companyUser,
    search = "",
    categoryValue = "",
    factoryValue = "",
    highlightValue = "",
    typeValue = "",
    page = "1",
  ) => {
    try {
      let newFurnitureList = []
      const {
        setFurnitureList,
        setTotalPages,
        setTotalItems,
        setPageNumber,
        setSearch,
        setSearchCategory,
        setSearchFactory,
        setSearchHighlight,
        setSearchType,
      } = get()
      const data = await getAllFurnitures(
        companyUser,
        search,
        page,
        categoryValue,
        factoryValue,
        highlightValue,
        typeValue,
      )
      const {
        rows,
        totalPages,
        totalItems,
        currentPage,
      } = data
      rows.forEach((element) => {
        newFurnitureList.push(element)
      })
      newFurnitureList = sortListBy(newFurnitureList, "name")
      setFurnitureList(newFurnitureList)

      setSearch(search)
      setSearchCategory(categoryValue)
      setSearchFactory(factoryValue)
      setSearchHighlight(highlightValue)
      setSearchType(typeValue)
      setTotalPages(totalPages)
      setTotalItems(totalItems)
      setPageNumber(currentPage)
    } catch (errors) {
      const {
        message = "Erro ao pegar a lista de Móveis!",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  createFurniture: async (dataFurniture) => {
    try {
      const responseFurniture = await createFurniture(dataFurniture)
      const { data } = responseFurniture
      let orderedFurnitureList = []
      const { furnitureList, setFurnitureList } = get()

      furnitureList.push(data)
      orderedFurnitureList = sortListBy(furnitureList, "name")
      setFurnitureList(orderedFurnitureList)

      const message = "Móvel criado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao criar o Móvel!",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  updateFurniture: async (id = "", newFurniture = {}) => {
    try {
      const responseFurniture = await updateFurniture(id, newFurniture)
      let orderedFurnitureList = []
      const { furnitureList, setFurnitureList } = get()

      const index = furnitureList.findIndex((furniture) => furniture.id === id)
      furnitureList[index] = responseFurniture
      orderedFurnitureList = sortListBy(furnitureList, "name")
      setFurnitureList(orderedFurnitureList)

      const message = "Móvel atualizado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao atualizar o Móvel!",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  deleteFurniture: async (company, id) => {
    try {
      await deleteFurniture(company, id)
      const { furnitureList, setFurnitureList } = get()
      let orderedFurnitureList = []
      const index = furnitureList.findIndex((furniture) => furniture.id === id)
      furnitureList.splice(index, 1)
      orderedFurnitureList = sortListBy(furnitureList, "name")
      setFurnitureList(orderedFurnitureList)
      const message = "Móvel deletado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao deletar o Móvel!",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },
}))

export default useFurnituresStore
