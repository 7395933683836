import React from "react"
import { Link } from "react-router-dom"

import PropTypes from "prop-types"

import { useMenuStore, useUserStore, useCompanyStore } from "../../store"

const MenuListLogout = ({ children, title, location }) => {
  const { isMenuOpen } = useMenuStore()
  const { logoutUser, setUser } = useUserStore()
  const { setCompany } = useCompanyStore()
  const handleLogout = () => {
    logoutUser()
    setUser({})
    setCompany({})
  }
  return (
    <Link
      data-testid="menuListLogout"
      to={location}
      title={title}
      className="flex items-left font-bold text- content-center justify-flex-start w-full hover:opacity-70"
      style={{
        justifyContent: isMenuOpen ? "flex-start" : "center",
        fontSize: isMenuOpen ? "1.125rem" : "0",
      }}
      onClick={handleLogout}
    >
      {children}
      {title}
    </Link>
  )
}

MenuListLogout.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string,
}

MenuListLogout.defaultProps = {
  location: "/",
}

export default MenuListLogout
