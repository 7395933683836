import React from "react";

import Table from ".";
import { ADMIN_TYPES } from "../../constants/roleTypes";
import {
  useHandleModal,
  useHandleSnackbar,
} from "../../hooks";
import {
  useTablesStore,
  useAppStore,
  useUserStore,
} from "../../store";
import Content from "../Common/Content";
import UtilsComponents from "../Common/UtilsComponents"

const UsersTable = () => {
  const { tablesList, deleteTable } = useTablesStore();
  const { modalController } = useAppStore();
  const { user } = useUserStore();

  const handleModal = useHandleModal();
  const handleSnackbar = useHandleSnackbar();

  const { company: companyUser, user_roles: rolesUser } = user || {};

  const isAdmin = ADMIN_TYPES.includes(rolesUser);

  const handleDeleteTable = async (user) => {
    try {
      const { message } = await deleteTable(companyUser, user.email, user.id);
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      };
      handleSnackbar(snackbarParams);
    } catch (errors) {
      const {
        message = "Erro ao deletar Tabela",
        statusText = "",
        statusCode = 422,
        error,
      } = errors;
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      };
      handleSnackbar(snackbarParams);
      console.error("Erro ao deletar tabela!", error);
    }
  };

  return (
    <Content.Table ariaLabel="Table Tables">
      <Content.Header>
        <Content.Row key="Tables Table Header">
          <Content.HeaderCell text="Fábrica" />
          <Content.HeaderCell text="Representante" />
          <Content.HeaderCell text="Telefone" />
          <Content.HeaderCell text="Data" />
          {isAdmin && <Content.HeaderCell text="Ações" />}
        </Content.Row>
      </Content.Header>
      <Content.Body>
        {tablesList.map((table) => {
          const modalKey = `TablesUpdateModalKey${table.id}`;
          return (
            <Content.Row key={table.id}>
              <Content.Cell type="text" text={table.factory} />
              <Content.Cell type="text" text={table.representative} />
              <Content.Cell type="text" text={table.telephone} />
              <Content.Cell type="text" text={table.date} />
              <Content.Cell type="button" align="right">
                <Content.Link
                  location={table.table}
                  title={`Tabela da Fábrica ${table.factory}`}
                >
                  <UtilsComponents.Icon
                    name="ExternalLink"
                    size="24"
                    color="#000000"
                    data-testid="contentLinkIcon"
                    className="cursor-pointer hover:opacity-70 mx-1"
                  />
                </Content.Link>
                <Content.Edit action={() => handleModal(modalKey)} />
                <Content.Delete action={() => handleDeleteTable(table)} />
              </Content.Cell>
              {modalController === modalKey && (
                <Table.Modal showKey={modalKey} table={table} action="update" />
              )}
            </Content.Row>
          );
        })}
      </Content.Body>
    </Content.Table>
  );
};

export default UsersTable;
