import React from "react";

import Finishes from ".";
import { ADMIN_TYPES } from "../../constants/roleTypes";
import { TypeList } from "../../constants/typeList"
import {
  useHandleModal,
  useHandleSnackbar,
} from "../../hooks";
import { useFinishesStore, useUserStore, useAppStore } from "../../store";
import Content from "../Common/Content";

const FinishesTable = () => {
  const { finishesList, deleteFinishes } = useFinishesStore();
  const { user } = useUserStore();
  const { modalController } = useAppStore();

  const handleModal = useHandleModal();
  const handleSnackbar = useHandleSnackbar();

  const { company: companyUser, user_roles: rolesUser } = user;

  const isAdmin = ADMIN_TYPES.includes(rolesUser);

  const handleDeleteFinishes = async (id) => {
    try {
      const { message } = await deleteFinishes(id, companyUser);
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      };
      handleSnackbar(snackbarParams);
    } catch (errors) {
      const {
        message = "Erro ao deletar Acabamento",
        statusText = "",
        statusCode = 422,
        error,
      } = errors;
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      };
      handleSnackbar(snackbarParams);
      console.error(message, error);
    }
  };

  return (
    <Content.Table ariaLabel="Table Finishes">
      <Content.Header>
        <Content.Row key="Finishes Table Header">
          <Content.HeaderCell text="Foto" />
          <Content.HeaderCell text="Nome" />
          <Content.HeaderCell text="Material" />
          <Content.HeaderCell text="Fábrica" />
          <Content.HeaderCell text="Tipo" />
          {isAdmin && <Content.HeaderCell text="Ações" />}
        </Content.Row>
      </Content.Header>
      <Content.Body>
        {finishesList.map((finishes) => {
          const modalKey = `finishesUpdateModal${finishes.id}`;
          const typeLabel = TypeList.find(
            (element) => element.id == finishes.type,
          )
          return (
            <Content.Row key={finishes.id}>
              <Content.Cell
                type="photo"
                text={finishes.name}
                photo={finishes.main_photo}
                hasBorder
              />
              <Content.Cell type="text" text={finishes.name} />
              <Content.Cell type="text" text={finishes.material} />
              <Content.Cell type="text" text={finishes.factory} />
              <Content.Cell type="text" text={typeLabel.label} />
              {ADMIN_TYPES.includes(rolesUser) && (
                <Content.Cell type="button" align="right">
                  <Content.Edit action={() => handleModal(modalKey)} />
                  <Content.Delete
                    action={() => handleDeleteFinishes(finishes.id)}
                  />
                </Content.Cell>
              )}
              {modalController === modalKey && (
                <Finishes.Modal
                  showKey={modalKey}
                  finishes={finishes}
                  action="update"
                />
              )}
            </Content.Row>
          );
        })}
      </Content.Body>
    </Content.Table>
  );
};

export default FinishesTable;
