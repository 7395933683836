import http from "../http-common"

const getAllBudgets = (
  company,
  name = "",
  page = 1,
) => {
  return http.get(`/budgets?name=${name}&company=${company}&page=${page}`)
}

const getBudgets = (id) => {
  return http.get(`/budgets/${id}`)
}

const createBudgets = (data) => {
  return http.post("/budgets", data)
}

const updateBudgets = (id, data) => {
  return http.put(`/budgets/${id}`, data)
}

const deleteBudgets = (id, company) => {
  return http.delete(`/budgets/${id}?company=${company}`)
}

const BudgetsDataService = {
  getAllBudgets,
  getBudgets,
  createBudgets,
  updateBudgets,
  deleteBudgets,
}

export default BudgetsDataService
