import { useHandleSnackbar } from "."
import { useUserStore, useProfessionalsStore } from "../store"

const useFetchProfessionalsData = () => {
  const { user } = useUserStore()
  const { getProfessionals, search } = useProfessionalsStore()
  const handleSnackbar = useHandleSnackbar()
  const { company } = user
  const fetchProfessionalsData = async (
    searchValue = search,
    pageNumber = 1,
  ) => {
    try {
      await getProfessionals(
        company,
        searchValue,
        pageNumber,
      )
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Profissionais",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
  }
  return fetchProfessionalsData
}

export default useFetchProfessionalsData
