import Button from "./button";
import Icon from "./icons";
import Root from "./root"

const UtilsComponents = {
  Button,
  Icon,
  Root,
}

export default UtilsComponents;
