import React from "react"

import BudgetsViewer from "../../components/BudgetsViewer"

const BudgetsViewerPage = () => {
  return (
    <BudgetsViewer.Content />
  )
}

export default BudgetsViewerPage
