import React from "react"

import PropTypes from "prop-types"

const InputActionButton = ({ text, action, testId }) => {
  return (
    <button
      data-testid={testId}
      onClick={action}
      className="bg-primary500 hover:bg-primary700 text-white font-bold py-2 px-4 rounded w-full"
      type="submit"
    >
      {text}
    </button>
  )
}

InputActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  testId: PropTypes.string,
}

InputActionButton.defaultProps = {
  testId: "inputActionButton",
}

export default InputActionButton
