import React from "react"

import { isEmpty } from "lodash"
import PropTypes from "prop-types"

const InputSelect = ({
  errors,
  register,
  name,
  required,
  disabled,
  defaultValue,
  dataList,
  testId,
  action,
}) => {
  const registerType = required
    ? { ...register(`${name}`, { required: `Recipe ${name} is required` }) }
    : { ...register(`${name}`) }
  const isError = required && !isEmpty(errors[name])
  return (
    <select
      {...registerType}
      id={name}
      data-testid={testId}
      name={name}
      className="w-full h-12 p-2 mt-2 border border-solid border-black rounded-md bg-stone200 focus:border-yellow800 hover:border-yellow800 cursor-pointer"
      style={{
        borderColor: isError ? "#EF4444" : "",
      }}
      defaultValue={defaultValue}
      disabled={disabled}
      onChange={action}
    >
      {dataList.map((item) => (
        <option key={item.id} value={item.id}>
          {item.label}
        </option>
      ))}
    </select>
  )
}

InputSelect.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    }),
  ).isRequired,
  testId: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  action: PropTypes.func,
}

InputSelect.defaultProps = {
  testId: "inputSelect",
  errors: {},
  required: false,
  disabled: false,
  defaultValue: undefined,
  action: () => {},
}

export default InputSelect
