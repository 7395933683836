/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"

import { isEmpty, set } from "lodash"

import {
  useFetchProfessionalsData,
  useFetchClientsData,
  useFetchFurnituresData,
} from "../../../hooks"
import {
  useCompanyStore,
  useUserStore,
  useProfessionalsStore,
  useClientsStore,
  useFurnituresStore,
} from "../../../store"
import Input from "../../Common/Modal/Input"

import BudgetsViewer from ".."

const BudgetsViewerContent = () => {
  const [professionalState, setProfessionalState] = useState({})
  const [professionalValue, setProfessionalValue] = useState({})
  const [clientState, setClientState] = useState({})
  const [clientValue, setClientValue] = useState({})
  const [furnituresState, setFurnituresState] = useState({})
  const [furnituresValue, setFurnituresValue] = useState([])

  const [attendantValue, setAttendantValue] = useState("")
  const [budgetId, setBudgetId] = useState("")

  const { company } = useCompanyStore()
  const { getCurrentUser } = useUserStore()
  const { professionalsList } = useProfessionalsStore()
  const { clientsList } = useClientsStore()
  const { furnitureList } = useFurnituresStore()

  const fetchProfessionalsData = useFetchProfessionalsData();
  const fetchClientsData = useFetchClientsData();
  const fetchFurnituresData = useFetchFurnituresData();

  useEffect(() => {
    const user = getCurrentUser()
    setAttendantValue(user)
    fetchProfessionalsData()
    fetchClientsData()
    fetchFurnituresData()
  }, [])

  const dataProfessionalList = professionalsList.map((element) => {
    return {
      value: element.id,
      label: element.name,
      telephone: element.telephone,
      type: "registred",
    }
  })
  dataProfessionalList.unshift(
    {
      value: "add",
      label: "Adicionar Profissional",
      type: "add",
    },
    {
      value: "none",
      label: "Sem Profissional",
      type: "none",
    },
  )

  const dataClientList = clientsList.map((element) => {
    return {
      value: element.id,
      label: element.name,
      telephone: element.telephone,
      cpf: element.cpf,
      cnpj: element.cnpj,
      state_registration: element.state_registration,
      address: element.address,
      neighborhood: element.neighborhood,
      city: element.city,
      state: element.state,
      cep: element.cep,
      number: element.number,
      complement: element.complement,
      type: "registred",
    }
  })
  dataClientList.unshift(
    {
      value: "add",
      label: "Adicionar Cliente",
      type: "add",
    },
  )

  const dataFurnituresList = furnitureList.map((element) => {
    return {
      value: element.id,
      label: element.name,
    }
  })
  dataFurnituresList.unshift(
    {
      value: "add",
      label: "Adicionar Móvel",
      type: "add",
    },
  )

  const handleProfessionalState = (value) => {
    setProfessionalState(value[0])
  }
  const handleClientState = (value) => {
    setClientState(value[0])
  }
  const handleFurnitureState = (value) => {
    setFurnituresState(value[0])
  }

  return (
    <BudgetsViewer.Root>
      <BudgetsViewer.Company
        budgetId={budgetId}
        company={company}
        attendant={attendantValue}
      />
      {isEmpty(professionalState) && (
        <Input.Root>
          <Input.Label
            label="Escolha um Profissional"
          />
          <Input.MultiSelect
            testId="inputProfessinal"
            action={handleProfessionalState}
            dataList={dataProfessionalList}
          />
        </Input.Root>
      )}
      {professionalState.type === "none" && (
        <BudgetsViewer.Block>
          <BudgetsViewer.Professional
            budgetId={budgetId}
            professional={{}}
            actionRemove={setProfessionalState}
          />
        </BudgetsViewer.Block>

      )}
      {professionalState.type === "registred" && (
        <BudgetsViewer.Block>
          <BudgetsViewer.Professional
            budgetId={budgetId}
            professional={professionalState}
            actionRemove={setProfessionalState}
          />
        </BudgetsViewer.Block>
      )}
      {professionalState.type === "add" && (
        <BudgetsViewer.Block>
          <BudgetsViewer.AddProfessional
            budgetId={budgetId}
            actionRemove={setProfessionalState}
            actionSave={setProfessionalValue}
          />
        </BudgetsViewer.Block>
      )}

      {isEmpty(clientState) && (
        <Input.Root>
          <Input.Label
            label="Escolha um Cliente"
          />
          <Input.MultiSelect
            testId="inputClient"
            action={handleClientState}
            dataList={dataClientList}
          />
        </Input.Root>
      )}
      {clientState.type === "registred" && (
        <BudgetsViewer.Client
          budgetId={budgetId}
          client={clientState}
          actionRemove={setClientState}
        />
      )}
      {clientState.type === "add" && (
        <BudgetsViewer.Block>
          <BudgetsViewer.AddClient
            budgetId={budgetId}
            actionRemove={setClientState}
            actionSave={setClientValue}
          />
        </BudgetsViewer.Block>
      )}

      {isEmpty(furnituresState) && (
        <Input.Root>
          <Input.Label
            label="Escolha um Móvel"
          />
          <Input.MultiSelect
            testId="inputClient"
            action={handleFurnitureState}
            dataList={dataFurnituresList}
          />
        </Input.Root>
      )}

      {furnituresState.type === "add" && (
        <BudgetsViewer.Block>
          <BudgetsViewer.AddFurniture
            budgetId={budgetId}
            actionRemove={setFurnituresState}
            actionSave={setFurnituresValue}
          />
        </BudgetsViewer.Block>
      )}
      {/* <BudgetsViewer.Furnitures
        budgetId={id}
        company={companyId}
      /> */}

    </BudgetsViewer.Root>
  )
}

export default BudgetsViewerContent
