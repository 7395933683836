/* eslint-disable no-unused-vars */
import React from "react"

import PropTypes from "prop-types"

import BudgetsViewer from "../"

const BudgetsViewerTableCompany = ({ budgetId, company, attendant }) => {
  const date = new Date()
  const dateNow = date.toLocaleDateString("pt-BR")
  const {
    fantasy_name,
    logo,
    address,
    cep,
    city,
    state,
    neighborhood,
    number,
    instagram,
  } = company

  const {
    telephone,
    email,
    name,
  } = attendant

  const infoAddress = [
    fantasy_name,
    city,
    state,
    neighborhood,
    cep,
    address,
    number,
  ]

  const infoUser = [
    {
      value: `Nº Pedido: ${budgetId}`,
      icon: null,
    },
    {
      value: name,
      icon: "User",
    },
    {
      value: telephone,
      icon: "MessageCircleMore",
    },
    {
      value: instagram,
      icon: "Instagram",
    },
    {
      value: email,
      icon: "Mail",
    },
    {
      value: dateNow,
      icon: "Calendar",
    },
  ]
  return (
    <BudgetsViewer.Table
      bgColor="bg-brown700"
      width="w-full"
    >
      <BudgetsViewer.Header>
        <BudgetsViewer.Row>
          <BudgetsViewer.HeaderCell
            textColor="text-neutral800"
            type="image"
            bgColor="bg-brown700"
            altTitle={fantasy_name}
            srcImg={logo}
          />
          <BudgetsViewer.HeaderCell
            textColor="text-neutral800"
            type="infoAddress"
            bgColor="bg-brown700"
            data={infoAddress}
            textAlign="text-right"
          />
          <BudgetsViewer.HeaderCell
            textColor="text-neutral800"
            type="infoUser"
            bgColor="bg-brown700"
            data={infoUser}
            textAlign="text-right"
          />
        </BudgetsViewer.Row>
      </BudgetsViewer.Header>
    </BudgetsViewer.Table>
  )
}

BudgetsViewerTableCompany.propTypes = {
  company: PropTypes.object.isRequired,
  attendant: PropTypes.object.isRequired,
  budgetId: PropTypes.string,
};

BudgetsViewerTableCompany.defaultProps = {
  budgetId: "",
};
export default BudgetsViewerTableCompany
