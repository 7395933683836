/* eslint-disable camelcase */
import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"

import FormData from "form-data"
import PropTypes from "prop-types"

import { useHandleModal, useHandleSnackbar } from "../../hooks"
import { useUserStore, useProfessionalsStore, useAppStore } from "../../store"
import {
  maskPhone,
  maskCPF,
  maskCNPJ,
  maskCEP,
} from "../../utils";
import { ModalHeader, Input, Modal } from "../Common/Modal"

const ProfessionalsModal = ({ showKey, professional, action }) => {
  const handleModal = useHandleModal()
  const handleSnackbar = useHandleSnackbar()
  const { modalController } = useAppStore()
  const { createProfessionals, updateProfessionals } = useProfessionalsStore()
  const { user: userInfo } = useUserStore()
  const { company: userCompany } = userInfo
  const {
    id = "",
    name = "",
    telephone = "",
    cpf = "",
    address = "",
    number = "",
    cep = "",
    cnpj = "",
    type_person = "",
    complement = "",
    history = [],
  } = professional

  const [typePerson, setTypePerson] = useState(type_person);

  const buttonText = id ? "Atualizar" : "Cadastrar"
  const headerText = id ? "Editar Profissional" : "Cadastrar Profissional"

  const isCreating = action === "create"
  const isUpdating = action === "update"

  const typeList = [
    {
      id: "0",
      label: "Escolha um tipo",
    },
    {
      id: "PF",
      label: "PF",
    },
    {
      id: "PJ",
      label: "PJ",
    },
  ]

  const handleTypePerson = (e) => {
    setTypePerson(e.target.value)
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id,
      name,
      telephone,
      cpf,
      cnpj,
      type_person: typePerson,
      address,
      number,
      cep,
      complement,
      history,
      company: userCompany,
    },
  })

  const onSubmit = useCallback(async (data) => {
    const professionalData = new FormData()
    professionalData.append("id", data.id)
    professionalData.append("name", data.name)
    professionalData.append("telephone", data.telephone)
    professionalData.append("cpf", data.cpf)
    professionalData.append("cnpj", data.cnpj)
    professionalData.append("type_person", data.type_person)
    professionalData.append("address", data.address)
    professionalData.append("number", data.number)
    professionalData.append("cep", data.cep)
    professionalData.append("complement", data.complement)
    professionalData.append("history", history)
    professionalData.append("company", userCompany)

    if (isUpdating) {
      try {
        const { message } = await updateProfessionals(id, professionalData)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao atualizar Profissional",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    } else if (isCreating) {
      try {
        const { message } = await createProfessionals(professionalData)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao criar Profissional",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    }
    reset()
    handleModal()
  // eslint-disable-next-line max-len
  }, [createProfessionals, handleModal, handleSnackbar, id, isCreating, isUpdating, reset, updateProfessionals, userCompany])

  return (
    <Modal.Root
      maxWidth="45%"
      isOpen={modalController === showKey && modalController !== ""}
    >
      <ModalHeader.Root>
        <ModalHeader.Title title={headerText} />
      </ModalHeader.Root>
      <Modal.Form action={handleSubmit(onSubmit)}>
        <Modal.Container>
          <Modal.Block>
            <Input.Root
              name="name"
            >
              <Input.Label
                label="Nome"
                name="name"
              />
              <Input.Text
                type="text"
                defaultValue={name}
                register={register}
                name="name"
                testId="inputName"
                errors={errors}
                required
              />
              {errors.name && <Input.HelperText text="Este campo é obrigatório" /> }
            </Input.Root>
            <Input.Root
              name="telephone"
            >
              <Input.Label
                label="Telefone"
                name="telephone"
              />
              <Input.TextWithMask
                register={register}
                name="telephone"
                value={telephone}
                action={maskPhone}
                testId="inputTelephone"
              />
            </Input.Root>
          </Modal.Block>
          <Modal.Block>
            <Input.Root
              name="type_person"
              width="45%"
            >
              <Input.Label
                label="Tipo"
                name="type_person"
              />
              <Input.Select
                register={register}
                name="type_person"
                testId="inputType"
                action={handleTypePerson}
                defaultValue={type_person}
                dataList={typeList}
              />
            </Input.Root>
            {typePerson === "PF" && (
              <Input.Root
                name="cpf"
              >
                <Input.Label
                  label="CPF"
                  name="cpf"
                />
                <Input.TextWithMask
                  value={cpf}
                  errors={errors}
                  register={register}
                  name="cpf"
                  action={maskCPF}
                  testId="inputCpf"
                />
              </Input.Root>
            )}
            {typePerson === "PJ" && (
              <Input.Root
                name="cnpj"
              >
                <Input.Label
                  label="CNPJ"
                  name="cnpj"
                />
                <Input.TextWithMask
                  value={cnpj}
                  errors={errors}
                  register={register}
                  name="cnpj"
                  action={maskCNPJ}
                  testId="inputCnpj"
                />
              </Input.Root>
            )}
            <Input.Root
              name="address"
            >
              <Input.Label
                label="Endereço"
                name="address"
              />
              <Input.Text
                type="text"
                defaultValue={address}
                register={register}
                name="address"
                testId="inputAdress"
              />
            </Input.Root>
          </Modal.Block>
          <Modal.Block>
            <Input.Root
              name="number"
              width="20%"
            >
              <Input.Label
                label="Número"
                name="number"
              />
              <Input.Text
                type="text"
                defaultValue={number}
                register={register}
                name="number"
                testId="inputNumber"
              />
            </Input.Root>
            <Input.Root
              name="complement"
              width="30%"
            >
              <Input.Label
                label="Complemento"
                name="complement"
              />
              <Input.Text
                type="text"
                defaultValue={complement}
                register={register}
                name="complement"
                testId="inputComplemento"
              />
            </Input.Root>
            <Input.Root
              name="cep"
              width="50%"
            >
              <Input.Label
                label="CEP"
                name="cep"
              />
              <Input.TextWithMask
                value={cep}
                errors={errors}
                register={register}
                name="cep"
                action={maskCEP}
                testId="inputCep"
              />
            </Input.Root>
          </Modal.Block>
          <Modal.Block>
            <Input.Button text={buttonText} />
          </Modal.Block>
        </Modal.Container>
      </Modal.Form>
      <Modal.Close action={handleModal} />
    </Modal.Root>
  )
}

ProfessionalsModal.propTypes = {
  showKey: PropTypes.string.isRequired,
  professional: PropTypes.object,
  action: PropTypes.string.isRequired,
};

ProfessionalsModal.defaultProps = {
  professional: {},
};

export default ProfessionalsModal
