import React from "react"

import PropTypes from "prop-types"

import BudgetsViewer from "../"

const BudgetsViewerTableClient = ({ client, actionRemove }) => {
  return (
    <BudgetsViewer.Table
      bgColor="bg-white"
      width="w-full"
      margin="mt-5"
    >
      <BudgetsViewer.Body>

        <BudgetsViewer.Row>
          <BudgetsViewer.HeaderCell
            type="text"
            text="Dados do Cliente"
            bgColor="lightGray"
            textColor="text-white"
            colSpan="5"
          />
        </BudgetsViewer.Row>

        <BudgetsViewer.Row>
          <BudgetsViewer.HeaderCell
            type="text"
            text="Nome"
            bgColor="lightGray"
            textColor="text-white"
            width="w-1/2"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Telefone"
            bgColor="lightGray"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="CPF / CNPJ"
            bgColor="lightGray"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Inscrição Estadual"
            bgColor="lightGray"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Ações"
            bgColor="lightGray"
            textColor="text-white"
          />
        </BudgetsViewer.Row>
        <BudgetsViewer.Row>
          <BudgetsViewer.Cell
            type="text"
            text={client.label}
          />
          <BudgetsViewer.Cell
            type="text"
            text={client.telephone}
          />
          <BudgetsViewer.Cell
            type="text"
            text={client.cpf}
          />
          <BudgetsViewer.Cell
            type="text"
            text={client.state_registration}
          />
          <BudgetsViewer.Cell
            type="add"
            width="w-[10%]"
          >
            <BudgetsViewer.Button
              text="x"
              action={actionRemove}
              bgColor="bg-[#ae3013]"
              width="w-full"
            />
          </BudgetsViewer.Cell>
        </BudgetsViewer.Row>

        <BudgetsViewer.Row>
          <BudgetsViewer.HeaderCell
            type="text"
            text="Endereço"
            bgColor="lightGray"
            textColor="text-white"
            width="w-1/2"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Bairro"
            bgColor="lightGray"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Cidade / Estado"
            bgColor="lightGray"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="CEP"
            bgColor="lightGray"
            textColor="text-white"
          />
        </BudgetsViewer.Row>
        <BudgetsViewer.Row>
          <BudgetsViewer.Cell
            type="text"
            text={`${client.address}, ${client.number}, ${client.complement}`}
          />
          <BudgetsViewer.Cell
            type="text"
            text={client.neighborhood}
          />
          <BudgetsViewer.Cell
            type="text"
            text={`${client.city}, ${client.state}`}
          />
          <BudgetsViewer.Cell
            type="text"
            text={client.cep}
          />
        </BudgetsViewer.Row>

      </BudgetsViewer.Body>
    </BudgetsViewer.Table>
  )
}

BudgetsViewerTableClient.propTypes = {
  client: PropTypes.object.isRequired,
  actionRemove: PropTypes.func.isRequired,
};

export default BudgetsViewerTableClient
