import BudgetsContent from "./BudgetsContent"
import BudgetsFilter from "./BudgetsFilter"
import BudgetsPagination from "./BudgetsPagination"
import BudgetsTable from "./BudgetsTable"

const Budgets = {
  Content: BudgetsContent,
  Filter: BudgetsFilter,
  Pagination: BudgetsPagination,
  Table: BudgetsTable,
}

export default Budgets
