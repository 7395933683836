import React from "react"

import PropTypes from "prop-types"

const FilterLabel = ({ text, testId }) => {
  return (
    <label
      data-testid={testId}
      className="block font-bold text-neutral800 mb-2 text-base"
      htmlFor={testId}
    >
      {text}
    </label>
  )
}

FilterLabel.propTypes = {
  text: PropTypes.string.isRequired,
  testId: PropTypes.string,
}

FilterLabel.defaultProps = {
  testId: "filterLabel",
}

export default FilterLabel
