import ModalClose from "./ModalClose"
import ModalContainer from "./ModalContainer"
import ModalForm from "./ModalForm"
import ModalFormBlock from "./ModalFormBlock"
import ModalRoot from "./ModalRoot"

export const Modal = {
  Close: ModalClose,
  Container: ModalContainer,
  Form: ModalForm,
  Block: ModalFormBlock,
  Root: ModalRoot,
}

export default Modal
