import React from "react"

import { useFetchFurnituresData } from "../../hooks";
import { useFurnituresStore } from "../../store"
import Pagination from "../Common/Pagination"

const FurnituresPagination = () => {
  const {
    pageNumber,
    totalPages,
    setPageNumber,
    search,
    searchCategory,
    searchFactory,
    searchHighlight,
    searchType,
  } = useFurnituresStore()
  const fetchFurnituresData = useFetchFurnituresData();

  const handlePagination = (value) => {
    setPageNumber(value)
    fetchFurnituresData(
      search,
      searchCategory,
      searchFactory,
      searchHighlight,
      searchType,
      value,
    )
  }

  return (
    <Pagination.Root spacing={2}>
      <Pagination.Number
        totalPages={totalPages}
        pageNumber={pageNumber}
        action={handlePagination}
      />
    </Pagination.Root>
  )
}

export default FurnituresPagination
