import React from "react"

const InputFileGridGallery = ({ children }) => {
  return (
    <div
      className="w-full flex flex-wrap justify-center items-center"
      data-testid="InputFileGridGalleryRoot"
    >
      {children}
    </div>
  )
}

export default InputFileGridGallery
