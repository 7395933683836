import React from "react"

import { useMenuStore, useUserStore, useCompanyStore } from "../../store"

const MenuUserPhoto = () => {
  const { user } = useUserStore()
  const { isMenuOpen } = useMenuStore()
  const { company } = useCompanyStore()
  const { photo, fantasy_name } = user
  const { logo } = company

  const srcImg = photo || logo
  return (
    <img
      data-testid="menuUserPhoto"
      className="w-10 h-10 rounded-full max-w-[45px]"
      style={{
        margin: isMenuOpen ? "0px auto" : "0px 12px 0px 0px",
        flex: isMenuOpen ? "0 0 0" : "63px",
        width: isMenuOpen ? "100%" : "2.5rem",
      }}
      src={srcImg}
      alt={`Usuário ${fantasy_name}`}
    />
  )
}

export default MenuUserPhoto
