/* eslint-disable camelcase */
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import FormData from "form-data";
import PropTypes from "prop-types";

import { useHandleModal, useHandleSnackbar } from "../../hooks";
import {
  useUserStore,
  useAppStore,
  useTablesStore,
} from "../../store";
import { maskPhone } from "../../utils";
import { ModalHeader, Input, Modal } from "../Common/Modal";

const TablesModal = ({ showKey, table, action }) => {
  const { modalController } = useAppStore();
  const { user: userInfo } = useUserStore();
  const { createTable, updateTable } = useTablesStore();

  const handleModal = useHandleModal();
  const handleSnackbar = useHandleSnackbar();

  const { company: userCompany } = userInfo;
  const {
    id = "",
    factory = "",
    representative = "",
    table: tableUrl = "",
    telephone = "",
    date = Date.now(),
    company = userCompany,
  } = table;

  const [tableValue, setTableValue] = useState(tableUrl)
  const headerText = id ? "Editar Tabela" : "Cadastrar Tabela";
  const buttonText = id ? "Atualizar" : "Cadastrar";

  const isCreating = action === "create";
  const isUpdating = action === "update";

  const handleTable = async (e) => {
    const file = e.target.files[0]
    setTableValue(file)
  }
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id,
      factory,
      representative,
      table: tableUrl,
      telephone,
      date,
      company,
    },
  });

  const onSubmit = async (data) => {
    const tableData = new FormData();

    tableData.append("id", data.id);
    tableData.append("factory", data.factory);
    tableData.append("table", tableValue);
    tableData.append("telephone", data.telephone);
    tableData.append("date", data.date);
    tableData.append("company", data.company);
    tableData.append("representative", data.representative);

    if (isUpdating) {
      try {
        const { message } = await updateTable(id, tableData)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao atualizar Tabela",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    } else if (isCreating) {
      try {
        const { message } = await createTable(tableData)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao criar Tabela",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    }
    reset();
    handleModal();
  };

  return (
    <Modal.Root isOpen={modalController === showKey && modalController !== ""}>
      <ModalHeader.Root>
        <ModalHeader.Title title={headerText} />
      </ModalHeader.Root>
      <Modal.Form action={handleSubmit(onSubmit)}>
        <Modal.Container>
          <Modal.Block>
            <Input.Root
              name="factory"
            >
              <Input.Label
                label="Nome"
                name="factory"
              />
              <Input.Text
                register={register}
                name="factory"
                defaultValue={factory}
                errors={errors}
                required
                testId="inputFactory"
              />
              {errors.factory && <Input.HelperText text="Este campo é obrigatório" /> }
            </Input.Root>
            <Input.Root
              name="date"
            >
              <Input.Label
                label="Data de Inserção"
                name="date"
              />
              <Input.Text
                type="date"
                register={register}
                name="date"
                defaultValue={date}
                testId="inputDate"
              />
            </Input.Root>
          </Modal.Block>
          <Modal.Block>
            <Input.Root
              name="representative"
            >
              <Input.Label
                label="Representante"
                name="representative"
              />
              <Input.Text
                register={register}
                name="representative"
                defaultValue={representative}
                errors={errors}
                testId="inputRepresentative"
              />
            </Input.Root>
            <Input.Root
              name="telephone"
            >
              <Input.Label
                label="Telefone"
                name="telephone"
              />
              <Input.TextWithMask
                register={register}
                name="telephone"
                value={telephone}
                action={maskPhone}
                testId="inputTelephone"
              />
            </Input.Root>
            <Input.Root
              name="table"
            >
              <Input.Label
                label="Tabela"
                name="table"
              />
              <Input.File
                register={register}
                name="table"
                testId="inputTable"
                action={handleTable}
              />
              {tableValue && (
                <Input.Label label="Contém um arquivo." name="table" />
              )}
            </Input.Root>
          </Modal.Block>
          <Modal.Block>
            <Input.Button type="submit" text={buttonText} />
          </Modal.Block>
        </Modal.Container>
      </Modal.Form>
      <Modal.Close action={handleModal} />
    </Modal.Root>
  );
};

TablesModal.propTypes = {
  showKey: PropTypes.string.isRequired,
  table: PropTypes.object,
  action: PropTypes.string,
};

TablesModal.defaultProps = {
  table: {},
  action: "",
};

export default TablesModal;
