import React from "react"

const FilterRoot = ({ children }) => {
  return (
    <div
      data-testid="filterRoot"
      className="w-full flex flex-row flex-wrap justify-between items-center py-4 mb-4 border-black border-0 border-b border-solid"
    >
      {children}
    </div>
  )
}

export default FilterRoot
