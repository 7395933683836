/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react"
import { useForm } from "react-hook-form"

import FormData from "form-data"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"

import BudgetsViewer from ".."

import { useHandleSnackbar } from "../../../hooks"
import { useUserStore, useClientsStore } from "../../../store"
import {
  maskPhone,
  maskCPF,
  maskCNPJ,
  maskCEP,
} from "../../../utils"
import Input from "../../Common/Modal/Input"

const BudgetsViewerAddFurniture = ({ actionSave, actionRemove }) => {
  const [typePerson, setTypePerson] = useState({})
  const [client, setClient] = useState({})
  const handleSnackbar = useHandleSnackbar()
  const { createClients } = useClientsStore()
  const { user: userInfo } = useUserStore()
  const { company: userCompany } = userInfo
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      main_photo: "",
      factory: "",
      category: undefined,
      designer: "",
      model: "",
      gallery: [],
      showInWeb: false,
      highlight: false,
      type: undefined,
      finishes: [],
      sizes: [
        {
          keyValue: "1",
          quantity: "0",
          width: "0",
          height: "0",
          depth: "0",
          price: "R$ 0,00",
        },
      ],
    },
  })
  const typeList = [
    {
      id: "0",
      label: "Escolha um tipo",
    },
    {
      id: "PF",
      label: "PF",
    },
    {
      id: "PJ",
      label: "PJ",
    },
  ]

  const handleTypePerson = (e) => {
    setTypePerson(e.target.value)
  }

  const onSubmit = useCallback(async (data) => {
    const clientData = new FormData()
    clientData.append("id", data.id)
    clientData.append("name", data.name)
    clientData.append("telephone", data.telephone)
    clientData.append("cpf", data.cpf)
    clientData.append("cnpj", data.cnpj)
    clientData.append("type_person", data.type_person)
    clientData.append("address", data.address)
    clientData.append("number", data.number)
    clientData.append("cep", data.cep)
    clientData.append("complement", data.complement)
    clientData.append("city", data.city)
    clientData.append("state", data.state)
    clientData.append("neighborhood", data.neighborhood)
    clientData.append("state_registration", data.state_registration)
    clientData.append("history", [])
    clientData.append("company", userCompany)
    try {
      const { message, response } = await createClients(clientData)
      setClient(response)
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      }
      handleSnackbar(snackbarParams)
    } catch (errors) {
      const {
        message = "Erro ao criar Cliente",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
    // reset()
  // eslint-disable-next-line max-len
  }, [createClients, handleSnackbar, reset, userCompany])

  return (
    <BudgetsViewer.Form action={handleSubmit(onSubmit)}>
      <BudgetsViewer.Table
        bgColor="bg-white"
        width="w-full"
        margin="mt-5"
      >
        <BudgetsViewer.Body>

          <BudgetsViewer.Row>
            <BudgetsViewer.HeaderCell
              type="text"
              text="Lista de Produtos"
              textColor="text-white"
              colSpan="10"
            />
          </BudgetsViewer.Row>

          <BudgetsViewer.Row>
            <BudgetsViewer.HeaderCell
              type="text"
              text="Foto"
              textColor="text-white"
              colSpan="2"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Nome"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Descrição"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Qtd"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Valor Un."
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Desconto (%)"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Valor Desconto"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Valor Total"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Ações"
              textColor="text-white"
            />
          </BudgetsViewer.Row>
          <BudgetsViewer.Row>
            <BudgetsViewer.Cell type="add">
              {isEmpty(client) && (
                <BudgetsViewer.Button
                  text="+"
                  width="w-1/2"
                  bgColor="bg-[#007900]"
                  action={actionSave}
                />
              )}
              <BudgetsViewer.Button
                text="x"
                action={actionRemove}
                bgColor="bg-[#ae3013]"
                width="w-1/2"
              />
            </BudgetsViewer.Cell>
          </BudgetsViewer.Row>

        </BudgetsViewer.Body>
      </BudgetsViewer.Table>
    </BudgetsViewer.Form>
  )
}

BudgetsViewerAddFurniture.propTypes = {
  actionSave: PropTypes.func.isRequired,
  actionRemove: PropTypes.func.isRequired,
};

export default BudgetsViewerAddFurniture
