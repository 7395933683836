import { useHandleSnackbar } from "."
import { useTablesStore, useUserStore } from "../store"

const useFetchTablesData = () => {
  const handleSnackbar = useHandleSnackbar()
  const {
    getTablesList,
  } = useTablesStore()
  const { user } = useUserStore()
  const { company } = user
  const fetchTablesData = async (searchValue, pageNumber) => {
    try {
      await getTablesList(company, searchValue, pageNumber)
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Tabelas",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
  }
  return fetchTablesData
}

export default useFetchTablesData
