import React from "react"

import PropTypes from "prop-types"

const LoginRoot = ({ children, action }) => {
  return (
    <form
      className="w-[300px] flex-1 p-4 absolute rounded-lg bg-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-[0px_0px_25px_2px_rgba(0,0,0,0.75)]"
      data-testid="loginRoot"
      onSubmit={action}
    >
      {children}
    </form>
  )
}

LoginRoot.propTypes = {
  action: PropTypes.func.isRequired,
}

export default LoginRoot
