/* eslint-disable consistent-return */
import { create } from "zustand"

import {
  getAllBudgets,
  createBudgets,
  updateBudgets,
  deleteBudgets,
} from "../actions/budgetsActions"
import { sortListBy, CustomError } from "../utils"

const useBudgetsStore = create((set, get) => ({
  budget: {},
  budgetsList: [
    {
      id: "1",
      name: "teste2",
      client: "Cliente2",
      attendant: "Atendente2",
      professional: "Profissional2",
      date: "2024-02-10",
      value: "R$ 180.000,00",
    },
    {
      id: "2",
      name: "teste",
      client: "Cliente",
      attendant: "Atendente",
      professional: "Profissional",
      date: "2021-02-05",
      value: "R$ 180.000,00",
    },
  ],
  budgetsSelect: [],
  totalPages: 1,
  totalItems: 0,
  pageNumber: 1,
  searchId: "",
  searchClient: "",
  searchAttendant: "",
  searchProfessional: "",
  searchDate: "",

  setSearch: (searchId) => {
    set(() => ({
      searchId,
    }))
  },
  setSearchClient: (searchClient) => {
    set(() => ({
      searchClient,
    }))
  },
  setSearchAttendant: (searchAttendan) => {
    set(() => ({
      searchAttendan,
    }))
  },
  setSearchProfessional: (searchProfessional) => {
    set(() => ({
      searchProfessional,
    }))
  },
  setSearchDate: (searchDate) => {
    set(() => ({
      searchDate,
    }))
  },
  setPageNumber: (pageNumber) => {
    set(() => ({
      pageNumber,
    }))
  },
  setTotalPages: (totalPages) => {
    set(() => ({
      totalPages,
    }))
  },
  setTotalItems: (totalItems) => {
    set(() => ({
      totalItems,
    }))
  },
  setBudgetsList: (budgetsData) => {
    set(() => ({
      budgetsList: budgetsData,
    }))
  },
  setBudgetsSelect: (budgetsData) => {
    set(() => ({
      budgetsSelect: budgetsData,
    }))
  },

  getBudgets: async (
    company,
    name = "",
    client = "",
    attendant = "",
    professional = "",
    date = "",
    page = 1,
  ) => {
    try {
      const {
        setSearch,
        setSearchClient,
        setSearchProfessional,
        setSearchAttendant,
        setSearchDate,
        setBudgetsList,
        setBudgetsSelect,
        setTotalPages,
        setTotalItems,
        setPageNumber,
      } = get()

      const response = await getAllBudgets(
        company,
        name,
        client,
        attendant,
        professional,
        date,
        page,
      )
      const {
        totalItems = "0",
        totalPages = "1",
        rows = [],
        currentPage = "1",
      } = response
      let orderedList = []
      let orderedListToSelect = []
      rows.forEach((element) => {
        const responseBudgets = {
          value: element.id,
          label: element.name,
        }
        orderedList.push(element)
        orderedListToSelect.push(responseBudgets)
      })
      orderedList = sortListBy(orderedList, "id", "desc")
      orderedListToSelect = sortListBy(orderedListToSelect, "id", "desc")
      setBudgetsList(orderedList)
      setBudgetsSelect(orderedListToSelect)
      setSearch(name)
      setSearchClient(client)
      setSearchProfessional(attendant)
      setSearchAttendant(professional)
      setSearchDate(date)
      setTotalItems(totalItems)
      setTotalPages(totalPages)
      setPageNumber(currentPage)
      const message = "Lista de Clientes retornada com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Clientes",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  createBudgets: async (budgetsData) => {
    try {
      const { budgetsList, setBudgetsList } = get()
      const response = await createBudgets(budgetsData)
      let orderedList = []
      budgetsList.push(response)
      orderedList = sortListBy(budgetsList, "name")
      setBudgetsList(orderedList)
      const message = "Cliente criado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao criar Cliente",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  updateBudgets: async (id, finishesData) => {
    try {
      const { budgetsList, setBudgetsList } = get()
      const response = await updateBudgets(id, finishesData)
      const { budget } = response
      let orderedList = []
      const index = budgetsList.findIndex((finishesItem) => finishesItem.id === id)
      budgetsList[index] = budget
      orderedList = sortListBy(budgetsList, "name")
      setBudgetsList(orderedList)
      const message = "Cliente atualizado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao atualizar Cliente",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  deleteBudgets: async (id, company) => {
    try {
      await deleteBudgets(id, company)
      const { setBudgetsList, budgetsList } = get()
      const newBudgetsList = budgetsList.filter(
        (finishes) => finishes.id !== id,
      )
      setBudgetsList(newBudgetsList)
      const message = "Cliente deletado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao deletar Clientes",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },
}))

export default useBudgetsStore
