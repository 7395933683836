import React from "react"

const BudgetsViewerRow = ({
  children,
}) => {
  return (
    <tr
      className="w-full"
    >
      {children}
    </tr>
  )
}

export default BudgetsViewerRow
