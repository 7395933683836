/* eslint-disable consistent-return */
import { create } from "zustand"

import {
  getAllFinishes,
  createFinishes,
  updateFinishes,
  deleteFinishes,
} from "../actions/finishesActions"
import { sortListBy, CustomError } from "../utils"

const useFinishesStore = create((set, get) => ({
  finishes: {},
  finishesList: [],
  finishesSelect: [],
  totalPages: 1,
  totalItems: 0,
  pageNumber: 1,
  search: "",
  searchMaterial: "",
  searchFactory: "",
  searchType: "",

  setSearch: (search) => {
    set(() => ({
      search,
    }))
  },
  setSearchMaterial: (searchMaterial) => {
    set(() => ({
      searchMaterial,
    }))
  },
  setSearchFactory: (searchFactory) => {
    set(() => ({
      searchFactory,
    }))
  },
  setSearchType: (searchType) => {
    set(() => ({
      searchType,
    }))
  },
  setPageNumber: (pageNumber) => {
    set(() => ({
      pageNumber,
    }))
  },
  setTotalPages: (totalPages) => {
    set(() => ({
      totalPages,
    }))
  },
  setTotalItems: (totalItems) => {
    set(() => ({
      totalItems,
    }))
  },
  setFinishesList: (finishesData) => {
    set(() => ({
      finishesList: finishesData,
    }))
  },
  setFinishesSelect: (finishesData) => {
    set(() => ({
      finishesSelect: finishesData,
    }))
  },

  getFinishes: async (
    company,
    name = "",
    material = "",
    factory = "",
    type = "",
    page = 1,
  ) => {
    try {
      const {
        setSearch,
        setSearchMaterial,
        setSearchFactory,
        setSearchType,
        setFinishesList,
        setFinishesSelect,
        setTotalPages,
        setTotalItems,
        setPageNumber,
      } = get()

      const response = await getAllFinishes(
        company,
        name,
        material,
        factory,
        type,
        page,
      )
      const {
        totalItems = "0",
        totalPages = "1",
        rows = [],
        currentPage = "1",
      } = response
      let orderedFinishesList = []
      let orderedFinishesSelect = []
      rows.forEach((element) => {
        const responseFinishes = {
          value: element.id,
          label: element.name,
        }
        orderedFinishesList.push(element)
        orderedFinishesSelect.push(responseFinishes)
      })
      orderedFinishesList = sortListBy(orderedFinishesList, "name")
      orderedFinishesSelect = sortListBy(orderedFinishesSelect, "label")
      setFinishesList(orderedFinishesList)
      setFinishesSelect(orderedFinishesSelect)
      setSearch(name)
      setSearchMaterial(material)
      setSearchFactory(factory)
      setSearchType(type)
      setTotalItems(totalItems)
      setTotalPages(totalPages)
      setPageNumber(currentPage)
      const message = "Lista de Acabamentos retornada com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Acabamentos",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  createFinishes: async (finishesData) => {
    try {
      const { finishesList, setFinishesList } = get()
      const response = await createFinishes(finishesData)
      let orderedFinishesList = []
      finishesList.push(response)
      orderedFinishesList = sortListBy(finishesList, "name")
      setFinishesList(orderedFinishesList)
      const message = "Acabamento criado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao criar Acabamento",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  updateFinishes: async (id, finishesData) => {
    try {
      const { finishesList, setFinishesList } = get()
      const response = await updateFinishes(id, finishesData)
      const { finishes } = response
      let orderedFinishesList = []
      const index = finishesList.findIndex((finishesItem) => finishesItem.id === id)
      finishesList[index] = finishes
      orderedFinishesList = sortListBy(finishesList, "name")
      setFinishesList(orderedFinishesList)
      const message = "Acabamento atualizado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao atualizar Acabamento",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  deleteFinishes: async (id, company) => {
    try {
      await deleteFinishes(id, company)
      const { setFinishesList, finishesList } = get()
      const newfinishesList = finishesList.filter(
        (finishes) => finishes.id !== id,
      )
      setFinishesList(newfinishesList)
      const message = "Acabamento deletado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao deletar Acabamentos",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },
}))

export default useFinishesStore
