import { useHandleSnackbar } from "."
import { useUserStore } from "../store"

const useFetchUserData = () => {
  const handleSnackbar = useHandleSnackbar()
  const {
    getUsersList,
    user,
    page,
  } = useUserStore()
  const { company } = user
  const fetchUsersData = async (searchValue) => {
    try {
      await getUsersList(company, searchValue, page)
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Usuários",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
  }
  return fetchUsersData
}

export default useFetchUserData
