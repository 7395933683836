/* eslint-disable consistent-return */
import React from "react"

import PropTypes from "prop-types"

import { useMenuStore } from "../../store"
import UtilsComponents from "../Common/UtilsComponents"

const MenuListItemIcon = ({ icon, size, color }) => {
  const { isMenuOpen } = useMenuStore()
  return (
    <UtilsComponents.Icon
      name={icon}
      size={size}
      color={color}
      style={{
        marginRight: isMenuOpen ? "12px" : "0px",
      }}
    />
  )
}

MenuListItemIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
}

export default MenuListItemIcon
