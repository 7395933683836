/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"

import PropTypes from "prop-types"

const BudgetsViewerCell = ({
  children,
  bgColor,
  width,
  margin,
  textColor,
  colSpan,
  data,
  text,
  type,
  altTitle,
  srcImg,
  textAlign,
}) => {
  const tableStyle = `px-3 py-2 whitespace-nowrap text-base border border-solid border-[#a8a1a1] ${bgColor} ${width} ${margin} ${textColor} ${textAlign}`
  switch (type) {
  case "infoAddress":
    return (
      <td
        className={tableStyle}
        colSpan={colSpan}
      >
        {data.map((item) => {
          return (
            <span>
              {item}
              <br />
            </span>
          )
        })}
      </td>
    )
  case "text":
    return (
      <td
        className={tableStyle}
        colSpan={colSpan}
      >
        {text}
      </td>
    )
  case "image":
    return (
      <td
        className={tableStyle}
        colSpan={colSpan}
      >
        <img src={srcImg} alt={altTitle} width="200" />
      </td>
    )
  case "add":
    return (
      <th
        className={tableStyle}
        colSpan={colSpan}
      >
        {children}
      </th>
    )
  default:
    return null
  }
}

BudgetsViewerCell.propTypes = {
  bgColor: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  textColor: PropTypes.string,
  colSpan: PropTypes.string,
  data: PropTypes.array,
  text: PropTypes.string,
  type: PropTypes.string,
  altTitle: PropTypes.string,
  srcImg: PropTypes.string,
  textAlign: PropTypes.string,
};

BudgetsViewerCell.defaultProps = {
  type: "",
  bgColor: "",
  width: "",
  margin: "",
  textColor: "text-neutral800",
  colSpan: "1",
  data: [],
  text: "",
  altTitle: "",
  srcImg: "",
  textAlign: "text-left",
}

export default BudgetsViewerCell
