import { FinishesDataService } from "../services"
import { CustomError } from "../utils"

export const getAllFinishes = async (
  company,
  name = "",
  material = "",
  factory = "",
  type = "",
  page = 1,
) => {
  try {
    const { data } = await FinishesDataService.getAllFinishes(
      company,
      name,
      material,
      factory,
      type,
      page,
    )
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao pegar lista de Acabamentos"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const createFinishes = async (dataFinishes) => {
  try {
    const { data } = await FinishesDataService.createFinishes(dataFinishes)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao criar Acabamento"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const updateFinishes = async (id, data) => {
  try {
    const response = await FinishesDataService.updateFinishes(id, data)
    return response.data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao atualizar Acabamento"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const deleteFinishes = async (id, company) => {
  try {
    const response = await FinishesDataService.deleteFinishes(id, company)
    return response.data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao deletar Acabamento"
    throw new CustomError(message, statusText, status, errors)
  }
}
