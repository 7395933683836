import { useCallback } from "react"

import { useAppStore } from "../store"

const useHandleModal = () => {
  const { setModalController, pollingController, setPollingController } = useAppStore()
  const handleModal = useCallback(
    async (key = "") => {
      setPollingController(!pollingController)
      setModalController(key)
    },
    [setModalController, setPollingController],
  )
  return handleModal
}

export default useHandleModal
