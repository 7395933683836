import http from "../http-common"

const getAll = (company, name = "", page = 1) => {
  return http.get(`/users?name=${name}&company=${company}&page=${page}`)
}

const login = async (data, headers) => {
  return await http.post("/users/login", data, headers)
}

const create = (data) => {
  return http.post("/users", data)
}

const update = (id, data) => {
  return http.put(`/users/${id}`, data)
}

const remove = (company, email, id) => {
  return http.delete(`/users/${id}?email=${email}&company=${company}`)
}

const UsersDataService = {
  getAll,
  login,
  create,
  update,
  remove,
}

export default UsersDataService
