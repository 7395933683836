class CustomError extends Error {
  constructor(message, statusText, statusCode, errors) {
    super(message);
    this.statusText = statusText;
    this.statusCode = statusCode;
    this.error = errors;
  }
}

export default CustomError;
