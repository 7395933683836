import React from "react"

import PropTypes from "prop-types"

const FilterTitle = ({ text, testId }) => {
  return (
    <h3
      data-testid={testId}
      className="block font-bold text-neutral800 mb-2 text-2xl"
      htmlFor={testId}
    >
      {text}
    </h3>
  )
}

FilterTitle.propTypes = {
  text: PropTypes.string.isRequired,
  testId: PropTypes.string,
}

FilterTitle.defaultProps = {
  testId: "FilterTitle",
}

export default FilterTitle
