/* eslint-disable no-unused-vars */
import React from "react"

const BudgetsViewerRoot = ({ children }) => {
  return (
    <div
      data-testid="budgetViewerRoot"
      aria-label="Tabela de visualização de orçamento"
      className="overflow-x-auto bg-brown700 rounded-md p-3 overflow-visible h-screen"
    >
      {children}
    </div>
  )
}

export default BudgetsViewerRoot
