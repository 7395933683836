import React from "react"

import { useCompanyStore } from "../../../../store"

const InputFileDefaultImg = () => {
  const { company } = useCompanyStore()
  const { logo, fantasy_name } = company
  const testId = `${fantasy_name}InputDefaultImg`

  return (
    <div
      className="w-full flex justify-center items-center bg-stone200"
      data-testid="InputDefaultImgRoot"
    >
      <img
        src={logo}
        alt={fantasy_name}
        data-testid={testId}
        className="w-24 h-24 rounded-full p-4"
      />
    </div>
  )
}

export default InputFileDefaultImg
