import React from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import FormData from "form-data"

import logo from "../../assets/conceitodesign2.png"
import { Input, Modal } from "../../components/Common/Modal"
import Login from "../../components/Login"
import { useHandleSnackbar } from "../../hooks"
import { useUserStore } from "../../store"

const LoginPage = () => {
  const { loginUser } = useUserStore()
  const handleSnackbar = useHandleSnackbar()
  const navigate = useNavigate()
  const buttonText = "Login"

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const onSubmit = async (data) => {
    const loginData = new FormData()
    loginData.append("email", data.email)
    loginData.append("password", data.password)

    try {
      const { message } = await loginUser(loginData)
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      }
      handleSnackbar(snackbarParams)
      navigate("/")
      window.location.reload()
    } catch (errors) {
      const {
        message = "Erro ao fazer login",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
  }
  return (
    <Login.Root action={handleSubmit(onSubmit)}>
      <Login.Image image={logo} />
      <Modal.Block>
        <Input.Root>
          <Input.Label label="E-mail" name="email" />
          <Input.Text
            type="e-mail"
            errors={errors}
            register={register}
            name="email"
            required
            testId="inputEmail"
          />
          {errors.email && <Input.HelperText isError text="Este campo é obrigatório" /> }

        </Input.Root>
      </Modal.Block>
      <Modal.Block>
        <Input.Root>
          <Input.Label label="Senha" name="password" />
          <Input.Text
            type="password"
            errors={errors}
            register={register}
            name="password"
            required
            testId="inputPassword"
          />
          {errors.password && <Input.HelperText isError text="Este campo é obrigatório" /> }
        </Input.Root>
      </Modal.Block>
      <Modal.Block>
        <Input.Button text={buttonText} />
      </Modal.Block>
    </Login.Root>
  )
}

export default LoginPage
