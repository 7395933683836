import React, { useEffect } from "react";

import Bugdets from ".";
// import {
//   useFetchBudgetsData,
// } from "../../hooks";
// import { useAppStore, useBudgetsStore } from "../../store";
import Content from "../Common/Content";

const BugdetsContent = () => {
  // const { pollingController } = useAppStore();
  // const { search } = useBudgetsStore();
  // const fetchBugdetsData = useFetchBudgetsData();

  useEffect(() => {
    // fetchBugdetsData();
    // let interval
    // if (!pollingController) {
    //   interval = setInterval(() => {
    //     fetchBugdetsData(search);
    //   }, 300000);
    // }
    // return () => clearInterval(interval)
  }, []);

  return (
    <Content.Root>
      <Bugdets.Filter />
      <Bugdets.Table />
      <Bugdets.Pagination />
    </Content.Root>
  );
};

export default BugdetsContent;
