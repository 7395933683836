import React from "react";

import Users from ".";
import { ADMIN_TYPES } from "../../constants/roleTypes";
import {
  useHandleModal,
  useHandleSnackbar,
} from "../../hooks";
import { useUserStore, useRolesStore, useAppStore } from "../../store";
import Content from "../Common/Content";

const UsersTable = () => {
  const { user, usersList, deleteUser } = useUserStore();
  const { getRoleLabel } = useRolesStore();
  const { modalController } = useAppStore();

  const handleModal = useHandleModal();
  const handleSnackbar = useHandleSnackbar();

  const { company: companyUser, user_roles: rolesUser } = user || {};

  const isAdmin = ADMIN_TYPES.includes(rolesUser);

  const handleDeleteUser = async (user) => {
    try {
      const { message } = await deleteUser(companyUser, user.email, user.id);
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      };
      handleSnackbar(snackbarParams);
    } catch (errors) {
      const {
        message = "Erro ao deletar Usuário",
        statusText = "",
        statusCode = 422,
        error,
      } = errors;
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      };
      handleSnackbar(snackbarParams);
      console.error("Erro ao deletar usuário!", error);
    }
  };

  return (
    <Content.Table ariaLabel="Table Users">
      <Content.Header>
        <Content.Row key="Users Table Header">
          <Content.HeaderCell text="Foto" />
          <Content.HeaderCell text="Nome" />
          <Content.HeaderCell text="Telefone" />
          <Content.HeaderCell text="E-mail" />
          <Content.HeaderCell text="Cargo" />
          {isAdmin && <Content.HeaderCell text="Ações" />}
        </Content.Row>
      </Content.Header>
      <Content.Body>
        {usersList.map((user) => {
          const roleLabel = getRoleLabel(user.user_roles) || "";
          const modalKey = `UserUpdateModalKey${user.id}`;
          return (
            <Content.Row key={user.id}>
              <Content.Cell
                type="photo"
                text={user.name}
                photo={user.photo}
                hasBorder
              />
              <Content.Cell type="text" text={user.name} />
              <Content.Cell type="text" text={user.telephone} />
              <Content.Cell type="text" text={user.email} />
              <Content.Cell type="text" text={roleLabel.label} />
              {isAdmin && (
                <Content.Cell type="button" align="right">
                  <Content.Edit action={() => handleModal(modalKey)} />
                  <Content.Delete action={() => handleDeleteUser(user)} />
                </Content.Cell>
              )}
              {modalController === modalKey && (
                <Users.Modal showKey={modalKey} user={user} action="update" />
              )}
            </Content.Row>
          );
        })}
      </Content.Body>
    </Content.Table>
  );
};

export default UsersTable;
