import http from "../http-common"

const getAll = (company, name = "", page = 1, category = "", factory = "", highlight = "", type = "") => {
  return http.get(
    `/furnitures?name=${name}&company=${company}&page=${page}&category=${category}&factory=${factory}&highlight=${highlight}&type=${type}`,
  )
}

const get = (company, id) => {
  return http.get(`/furnitures/${id}?company=${company}`)
}

const create = (data) => {
  return http.post("/furnitures", data)
}

const update = (id, data) => {
  return http.put(`/furnitures/${id}`, data)
}

const remove = (company, id) => {
  return http.delete(`/furnitures/${id}?company=${company}`)
}

const FurnituresDataService = {
  getAll,
  get,
  create,
  update,
  remove,
}

export default FurnituresDataService
