import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";

// import { InputCheckBox as InputCheckBoxUI } from "./styles";

const InputCheckBox = ({
  register,
  name,
  // width,
  errors,
  required,
  checked,
  testId,
}) => {
  const registerType = required
    ? { ...register(`${name}`, { required: `Recipe ${name} is required` }) }
    : { ...register(`${name}`) };
  const isError = required && !isEmpty(errors[name]);
  return (
    <input
      {...registerType}
      id={name}
      data-testid={testId}
      name={name}
      defaultChecked={checked}
      type="checkbox"
      className="relative w-8 h-8 my-3 mx-auto block aspect-square !appearance-none !bg-none checked:!bg-gradient-to-tr checked:!from-sky-400 checked:!to-white bg-white border border-gray-300 shadow-sm rounded !outline-none !ring-0 !text-transparent !ring-offset-0 checked:!border-sky-400 hover:!border-sky-400 cursor-pointer transition-all duration-300 ease-in-out focus-visible:!outline-offset-2 focus-visible:!outline-2 focus-visible:!outline-sky-400/30 focus-visible:border-sky-400 after:w-[35%] after:h-[53%] after:absolute after:opacity-0 after:top-[40%] after:left-[50%] after:-translate-x-2/4 after:-translate-y-2/4 after:rotate-[25deg] after:drop-shadow-[1px_0.5px_1px_rgba(56,149,248,0.5)] after:border-r-[0.30em] after:border-r-neutral800 after:border-b-[0.30em] after:border-b-neutral800 after:transition-all after:duration-200 after:ease-linear checked:after:opacity-100 checked:after:rotate-45 hover:opacity-70"
      style={{
        borderColor: isError ? "#EF4444" : "",
      }}
    />
  );
};

InputCheckBox.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  testId: PropTypes.string,
  // width: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.bool,
  checked: PropTypes.bool,
};

InputCheckBox.defaultProps = {
  testId: "inputCheckbox",
  // width: "100%",
  errors: {},
  required: false,
  checked: false,
};

export default InputCheckBox;
