import { UsersDataService } from "../services"
import { CustomError } from "../utils"

export const getAllUsers = async (companyUser, search = "", page = 1) => {
  try {
    const { data } = await UsersDataService.getAll(companyUser, search, page)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao pegar lista de Usuários"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const loginUser = async (dataLogin) => {
  try {
    const { data } = await UsersDataService.login(dataLogin)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao fazer login de Usuário"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const createUser = async (dataUser) => {
  try {
    const { data } = await UsersDataService.create(dataUser)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao criar Usuário"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const updateUser = async (id, dataUser) => {
  try {
    const { data } = await UsersDataService.update(id, dataUser)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao atualizar Usuário"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const deleteUser = async (companyUser, email, id) => {
  try {
    const { data } = await UsersDataService.remove(companyUser, email, id)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao deletar Usuário"
    throw new CustomError(message, statusText, status, errors)
  }
}
