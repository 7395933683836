import MenuComponent from "./MenuComponent"
import MenuHeader from "./MenuHeader"
import MenuList from "./MenuList"
import MenuListItem from "./MenuListItem"
import MenuListItemIcon from "./MenuListItemIcon"
import MenuListItemLink from "./MenuListItemLink"
import MenuListLogout from "./MenuListLogout"
import MenuListRoot from "./MenuListRoot"
import MenuLogo from "./MenuLogo"
import MenuRoot from "./MenuRoot"
import MenuToggleButton from "./MenuToggleButton"
import MenuUserName from "./MenuUserName"
import MenuUserPhoto from "./MenuUserPhoto"
import MenuUserRoot from "./MenuUserRoot"

const Menu = {
  Component: MenuComponent,
  Header: MenuHeader,
  List: MenuList,
  ListItem: MenuListItem,
  ListItemIcon: MenuListItemIcon,
  ListItemLink: MenuListItemLink,
  ListLogout: MenuListLogout,
  ListRoot: MenuListRoot,
  ToggleButton: MenuToggleButton,
  Logo: MenuLogo,
  Root: MenuRoot,
  UserName: MenuUserName,
  UserPhoto: MenuUserPhoto,
  UserRoot: MenuUserRoot,
}

export default Menu
