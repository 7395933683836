/* eslint-disable consistent-return */
import { create } from "zustand"

import {
  getAllProfessionals,
  createProfessionals,
  updateProfessionals,
  deleteProfessionals,
} from "../actions/professionalsActions"
import { sortListBy, CustomError } from "../utils"

const useProfessionalsStore = create((set, get) => ({
  professional: {},
  professionalsList: [],
  professionalsSelect: [],
  totalPages: 1,
  totalItems: 0,
  pageNumber: 1,
  search: "",

  setSearch: (search) => {
    set(() => ({
      search,
    }))
  },
  setPageNumber: (pageNumber) => {
    set(() => ({
      pageNumber,
    }))
  },
  setTotalPages: (totalPages) => {
    set(() => ({
      totalPages,
    }))
  },
  setTotalItems: (totalItems) => {
    set(() => ({
      totalItems,
    }))
  },
  setProfessionalsList: (professionalsData) => {
    set(() => ({
      professionalsList: professionalsData,
    }))
  },
  setProfessionalsSelect: (professionalsData) => {
    set(() => ({
      professionalsSelect: professionalsData,
    }))
  },

  getProfessionals: async (
    company,
    name = "",
    page = 1,
  ) => {
    try {
      const {
        setSearch,
        setProfessionalsList,
        setProfessionalsSelect,
        setTotalPages,
        setTotalItems,
        setPageNumber,
      } = get()

      const response = await getAllProfessionals(
        company,
        name,
        page,
      )
      const {
        totalItems = "0",
        totalPages = "1",
        rows = [],
        currentPage = "1",
      } = response
      let orderedList = []
      let orderedListToSelect = []
      rows.forEach((element) => {
        const responseProfessionals = {
          value: element.id,
          label: element.name,
        }
        orderedList.push(element)
        orderedListToSelect.push(responseProfessionals)
      })
      orderedList = sortListBy(orderedList, "name")
      orderedListToSelect = sortListBy(orderedListToSelect, "label")
      setProfessionalsList(orderedList)
      setProfessionalsSelect(orderedListToSelect)
      setSearch(name)
      setTotalItems(totalItems)
      setTotalPages(totalPages)
      setPageNumber(currentPage)
      const message = "Lista de Profissionais retornada com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Profissionais",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  createProfessionals: async (professionalsData) => {
    try {
      const { professionalsList, setProfessionalsList } = get()
      const response = await createProfessionals(professionalsData)
      let orderedList = []
      professionalsList.push(response)
      orderedList = sortListBy(professionalsList, "name")
      setProfessionalsList(orderedList)
      const message = "Profissional criado com sucesso!"
      return { message, response }
    } catch (errors) {
      const {
        message = "Erro ao criar Profissional",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  updateProfessionals: async (id, finishesData) => {
    try {
      const { professionalsList, setProfessionalsList } = get()
      const response = await updateProfessionals(id, finishesData)
      const { professionals } = response
      let orderedList = []
      const index = professionalsList.findIndex((finishesItem) => finishesItem.id === id)
      professionalsList[index] = professionals
      orderedList = sortListBy(professionalsList, "name")
      setProfessionalsList(orderedList)
      const message = "Profissional atualizado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao atualizar Profissional",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  deleteProfessionals: async (id, company) => {
    try {
      await deleteProfessionals(id, company)
      const { setProfessionalsList, professionalsList } = get()
      const newProfessionalsList = professionalsList.filter(
        (finishes) => finishes.id !== id,
      )
      setProfessionalsList(newProfessionalsList)
      const message = "Profissional deletado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao deletar Profissionals",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },
}))

export default useProfessionalsStore
