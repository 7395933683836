/* eslint-disable max-len */
/* eslint-disable indent */
import React from "react"

import PropTypes from "prop-types"

import UtilsComponents from "../../UtilsComponents"

const InputFile = ({
  register,
  name,
  required,
  multiple,
  action,
  testId,
}) => {
  const registerType = required
    ? { ...register(`${name}`, { required: `Recipe ${name} is required` }) }
    : { ...register(`${name}`) }

  return (
    <div
      className="flex w-full items-center justify-center"
      data-testid="inputFileRoot"
    >
      <label
        className="w-full mt-2 border border-solid border-black flex flex-row items-center flex-nowrap content-center px-2 py-2 bg-stone200 text-neutral800 rounded-lg shadow-lg tracking-wide uppercase cursor-pointer hover:opacity-70 focus:border-yellow800 hover:border-yellow800"
        htmlFor={name}
        data-testid={`label${testId}`}
      >
        <UtilsComponents.Icon
          name="UploadCloud"
          size="24"
          color="#000000"
          className="mr-2"
        />
        <span className="text-sm">Selecione um arquivo</span>
        <input
          id={name}
          type="file"
          className="hidden"
          data-testid={testId}
          name={name}
          {...registerType}
          onChange={action}
          multiple={multiple}
          required={required}
        />
      </label>
    </div>
  )
}

InputFile.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  testId: PropTypes.string,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
}

InputFile.defaultProps = {
  testId: "input-file",
  required: false,
  multiple: false,
}

export default InputFile
