import React from "react"

import PropTypes from "prop-types"

const BudgetsViewerBlock = ({ children, width }) => {
  const stylesValue = `${width} flex items-center justify-center`
  return (
    <div
      className={stylesValue}
    >
      {children}
    </div>
  )
}

BudgetsViewerBlock.propTypes = {
  width: PropTypes.string,
}

BudgetsViewerBlock.defaultProps = {
  width: "w-full",
}

export default BudgetsViewerBlock
