import { useHandleSnackbar } from "."
import { useUserStore, useFurnituresStore } from "../store"

const useFetchFurnituresData = () => {
  const {
    user,
  } = useUserStore()
  const {
    getFurnitureList,
  } = useFurnituresStore()
  const handleSnackbar = useHandleSnackbar()
  const { company } = user
  const fetchFurnituresData = async (
    searchValue = "",
    searchCategory = "",
    searchFactory = "",
    searchHighlight = "",
    searchType = "",
    pageNumber = 1,
  ) => {
    try {
      await getFurnitureList(
        company,
        searchValue,
        searchCategory,
        searchFactory,
        searchHighlight,
        searchType,
        pageNumber,
      )
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Móveis",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
  }
  return fetchFurnituresData
}

export default useFetchFurnituresData
