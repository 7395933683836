import React from "react";

import { TypeList } from "../../constants/typeList"
import { useFetchFurnituresData } from "../../hooks";
import {
  useFurnituresStore,
  useCategoryStore,
} from "../../store";
import Filter from "../Common/Filter";

const FurnituresFilter = () => {
  const { categoriesList } = useCategoryStore();
  const {
    search,
    searchCategory,
    searchFactory,
    searchHighlight,
    searchType,
  } = useFurnituresStore();
  const fetchFurnituresData = useFetchFurnituresData();

  const dataCategoriesList = [
    {
      id: "",
      label: "Nenhuma categoria",
    },
    ...categoriesList,
  ];

  const dataHighlightList = [
    {
      id: "",
      label: "Todos",
    },
    {
      id: "true",
      label: "Destacado",
    },
    {
      id: "false",
      label: "Não destacado",
    },
  ]

  const dataTypeList = [
    {
      id: "",
      label: "Todos",
    },
    ...TypeList,
  ]

  let timeout;
  const handleSearch = (event) => {
    clearTimeout(timeout);
    const searchValue = event.target.value;
    timeout = setTimeout(async () => {
      await fetchFurnituresData(
        searchValue,
        searchCategory,
        searchFactory,
        searchHighlight,
        searchType,
      );
    }, 500);
  };

  const handleFactory = (event) => {
    clearTimeout(timeout);
    const factoryValue = event.target.value;
    timeout = setTimeout(async () => {
      await fetchFurnituresData(
        search,
        searchCategory,
        factoryValue,
        searchHighlight,
        searchType,
      );
    }, 500);
  };

  const handleCategory = async (event) => {
    const categoryId = event.target.value;
    await fetchFurnituresData(
      search,
      categoryId,
      searchFactory,
      searchHighlight,
      searchType,
    );
  };

  const handleHighlight = async (event) => {
    const highlightValue = event.target.value;
    await fetchFurnituresData(
      search,
      searchCategory,
      searchFactory,
      highlightValue,
      searchType,
    )
  };

  const handleType = async (event) => {
    const typeValue = event.target.value;
    await fetchFurnituresData(
      search,
      searchCategory,
      searchFactory,
      searchHighlight,
      typeValue,
    );
  };

  return (
    <Filter.Root>
      <Filter.InputRoot width="100%">
        <Filter.Title text="Filtros" />
      </Filter.InputRoot>
      <Filter.InputRoot
        name="name"
        width="20%"
      >
        <Filter.Label
          text="Nome"
          testId="nameLabel"
        />
        <Filter.Input
          name="name"
          action={handleSearch}
          testId="nameInputFilter"
        />
      </Filter.InputRoot>
      <Filter.InputRoot
        name="factory"
        width="20%"
      >
        <Filter.Label
          text="Fábrica"
          testId="factoryLabel"
        />
        <Filter.Input
          name="factory"
          action={handleFactory}
          testId="factoryInputFilter"
        />
      </Filter.InputRoot>
      <Filter.InputRoot
        name="category"
        width="20%"
      >
        <Filter.Label
          text="Categoria"
          testId="categoryLabel"
        />
        <Filter.Select
          name="category"
          action={handleCategory}
          dataList={dataCategoriesList}
          testId="categoryInputFilter"
        />
      </Filter.InputRoot>
      <Filter.InputRoot
        name="highlight"
        width="20%"
      >
        <Filter.Label
          text="Destaque"
          testId="highlightLabel"
        />
        <Filter.Select
          name="highlight"
          action={handleHighlight}
          dataList={dataHighlightList}
          testId="highlightInputFilter"
        />
      </Filter.InputRoot>
      <Filter.InputRoot
        name="highlight"
        width="20%"
      >
        <Filter.Label
          text="Tipo"
          testId="typeLabel"
        />
        <Filter.Select
          name="type"
          action={handleType}
          dataList={dataTypeList}
          testId="typeInputFilter"
        />
      </Filter.InputRoot>
    </Filter.Root>
  );
};

export default FurnituresFilter;
