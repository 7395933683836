import React from "react";

import Clients from ".";
import { ADMIN_TYPES } from "../../constants/roleTypes";
import {
  useHandleModal,
  useHandleSnackbar,
} from "../../hooks";
import { useClientsStore, useUserStore, useAppStore } from "../../store";
import Content from "../Common/Content";

const ClientsTable = () => {
  const { clientsList, deleteClients } = useClientsStore();
  const { user } = useUserStore();
  const { modalController } = useAppStore();

  const handleModal = useHandleModal();
  const handleSnackbar = useHandleSnackbar();

  const { company: companyUser, user_roles: rolesUser } = user;

  const isAdmin = ADMIN_TYPES.includes(rolesUser);

  const handleDeleteClients = async (id) => {
    try {
      const { message } = await deleteClients(id, companyUser);
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      };
      handleSnackbar(snackbarParams);
    } catch (errors) {
      const {
        message = "Erro ao deletar Cliente",
        statusText = "",
        statusCode = 422,
        error,
      } = errors;
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      };
      handleSnackbar(snackbarParams);
      console.error(message, error);
    }
  };

  return (
    <Content.Table ariaLabel="Table Clients">
      <Content.Header>
        <Content.Row key="Clients Table Header">
          <Content.HeaderCell text="Nome" />
          <Content.HeaderCell text="Telefone" />
          <Content.HeaderCell text="Endereço" />
          {isAdmin && <Content.HeaderCell text="Ações" />}
        </Content.Row>
      </Content.Header>
      <Content.Body>
        {clientsList.map((client) => {
          const modalKey = `ClientsUpdateModal${client.id}`;
          return (
            <Content.Row key={client.id}>
              <Content.Cell type="text" text={client.name} />
              <Content.Cell type="text" text={client.telephone} />
              <Content.Cell type="text" text={`${client.address}, nº ${client.number}, ${client.complement}, ${client.cep}`} />
              {ADMIN_TYPES.includes(rolesUser) && (
                <Content.Cell type="button" align="right">
                  <Content.Edit action={() => handleModal(modalKey)} />
                  <Content.Delete
                    action={() => handleDeleteClients(client.id)}
                  />
                </Content.Cell>
              )}
              {modalController === modalKey && (
                <Clients.Modal
                  showKey={modalKey}
                  client={client}
                  action="update"
                />
              )}
            </Content.Row>
          );
        })}
      </Content.Body>
    </Content.Table>
  );
};

export default ClientsTable;
