import React from "react"

const BudgetsViewerBody = ({
  children,
}) => {
  return (
    <tbody
      className="w-full"
    >
      {children}
    </tbody>
  )
}

export default BudgetsViewerBody
