/* eslint-disable camelcase */
import React, { useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"

import { isEmpty } from "lodash"

import Admin from "../components/Admin"
import Snackbar from "../components/Common/Snackbar"
import Menu from "../components/Menu"
import { ADMIN_TYPES } from "../constants/roleTypes"
import {
  useUserStore,
  useCompanyStore,
  useAppStore,
} from "../store"
import BudgetsPage from "./Budgets"
import BudgetsViewerPage from "./BudgetsViewer"
import CategoryPage from "./Category"
import ClientsPage from "./Clients"
import FinishesPage from "./Finishes"
import FurnituresPage from "./Furnitures"
import LoginPage from "./Login"
import ProfessionalsPage from "./Professionals"
import TablesPage from "./Tables"
import UsersPage from "./Users"

const AdminPage = () => {
  const location = useLocation()
  const pathName = location.pathname || "/moveis"

  const { getCompany } = useCompanyStore()
  const {
    user,
    getCurrentUser,
  } = useUserStore()
  const { snackbarController } = useAppStore()

  const { open } = snackbarController

  const { user_roles = "" } = user
  const isAdmin = ADMIN_TYPES.includes(user_roles)

  const userInfo = useMemo(() => getCurrentUser(), [getCurrentUser])

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      getCompany(userInfo.company)
    }
  }, [getCompany, userInfo])

  return (
    <>
      {isEmpty(user) && <LoginPage />}
      {!isEmpty(user) && (
        <Admin.Root>
          <Menu.Component />
          <Admin.Container>
            {pathName === "/moveis" ? <FurnituresPage /> : null}
            {pathName === "/acabamentos" ? <FinishesPage /> : null}
            {pathName === "/categorias" ? <CategoryPage /> : null}
            {pathName === "/clientes" ? <ClientsPage /> : null}
            {pathName === "/profissionais" ? <ProfessionalsPage /> : null}
            {pathName === "/tabelas" ? <TablesPage /> : null}
            {pathName === "/orcamentos" ? <BudgetsPage /> : null}
            {pathName === "/orcamentos/criar" ? <BudgetsViewerPage /> : null}
            {pathName === "/orcamentos/editar/:id" ? <BudgetsViewerPage /> : null}
            {pathName === "/orcamentos/ver/:id" ? <BudgetsViewerPage /> : null}
            {isAdmin && pathName === "/usuarios" ? <UsersPage /> : null}
          </Admin.Container>
        </Admin.Root>
      )}
      {open && (
        <Snackbar.Root>
          <Snackbar.Alert />
        </Snackbar.Root>
      )}
    </>
  )
}

export default AdminPage
