import React from "react"

import PropTypes from "prop-types"

import UtilsComponents from "../UtilsComponents"

const ContentDelete = ({ action, highlight }) => {
  const styleActive = highlight ? " hover:opacity-30" : " opacity-30 hover:opacity-100";
  const className = `cursor-pointer mx-1 ${styleActive}`
  return (
    <UtilsComponents.Icon
      name="Star"
      size="24"
      color="#000000"
      onClick={action}
      data-testid="contentHighlight"
      className={className}
    />
  )
}

ContentDelete.propTypes = {
  action: PropTypes.func.isRequired,
  highlight: PropTypes.bool.isRequired,
}

export default ContentDelete
