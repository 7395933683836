import React from "react"

import PropTypes from "prop-types"

const InputRoot = ({ children, name, width }) => {
  const testId = `${name}InputRoot`
  return (
    <div
      data-testid={testId}
      className="w-full relative block m-1"
      style={{ width }}
    >
      {children}
    </div>
  )
}

InputRoot.propTypes = {
  name: PropTypes.string,
  width: PropTypes.string,
}

InputRoot.defaultProps = {
  name: "",
  width: "100%",
}

export default InputRoot
