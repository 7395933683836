import { create } from "zustand"

import { getCompany } from "../actions/companyActions"
import { CustomError } from "../utils"

const useCompanyStore = create((set, get) => ({
  company: {},
  setCompany: (company) => {
    set(() => ({
      company,
    }))
  },

  getCompany: async (id) => {
    try {
      const { setCompany } = get()
      const company = await getCompany(id)
      setCompany(company)
    } catch (errors) {
      const {
        message = "Erro ao pegar empresa",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },
}))

export default useCompanyStore
