import React from "react";

import PropTypes from "prop-types";

const InputHelperText = ({ text, isError, name }) => {
  const testId = `${name}InputHelperText`
  return (
    <span
      data-testid={testId}
      className="text-neutral700 font-s text-sm block w-full"
      style={{ color: isError ? "rgb(239 68 68)" : "" }}
    >
      {text}
    </span>
  );
}

InputHelperText.propTypes = {
  text: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  name: PropTypes.string,
}

InputHelperText.defaultProps = {
  isError: false,
  name: "",
}

export default InputHelperText
