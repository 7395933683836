import CategoriesContent from "./CategoriesContent"
import CategoriesFilter from "./CategoriesFilter"
import CategoriesModal from "./CategoriesModal"
import CategoriesPagination from "./CategoriesPagination"
import CategoriesTable from "./CategoriesTable"

const Category = {
  Content: CategoriesContent,
  Filter: CategoriesFilter,
  Modal: CategoriesModal,
  Pagination: CategoriesPagination,
  Table: CategoriesTable,
}

export default Category
