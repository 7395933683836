import React, { useEffect } from "react";

import Furnitures from ".";
import {
  useFetchCategoriesData,
  useFetchFurnituresData,
} from "../../hooks";
import { useFurnituresStore, useCategoryStore, useAppStore } from "../../store";
import Content from "../Common/Content";

const FurnituresContent = () => {
  const fetchCategoriesData = useFetchCategoriesData();
  const fetchFurnituresData = useFetchFurnituresData();
  const { search: searchCategoryStore } = useCategoryStore();
  const {
    search,
    searchCategory,
    searchFactory,
    searchHighlight,
    searchType,
  } = useFurnituresStore();
  const { pollingController } = useAppStore();

  useEffect(() => {
    fetchFurnituresData();
    fetchCategoriesData();

    let interval
    if (!pollingController) {
      interval = setInterval(() => {
        fetchFurnituresData(searchCategoryStore);
        fetchCategoriesData(
          search,
          searchCategory,
          searchFactory,
          searchHighlight,
          searchType,
        );
      }, 5 * 60 * 1000);
    }

    return () => clearInterval(interval);
  }, []);

  return (
    <Content.Root>
      <Furnitures.Filter />
      <Furnitures.Table />
      <Furnitures.Pagination />
    </Content.Root>
  );
};

export default FurnituresContent;
