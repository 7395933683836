import React, { useEffect } from "react";

import Users from ".";
import {
  useFetchUsersData,
  useFetchRolesData,
} from "../../hooks";
import { useUserStore, useAppStore } from "../../store";
import Content from "../Common/Content";

const UsersContent = () => {
  const { search } = useUserStore();

  const fetchUsersData = useFetchUsersData();
  const fetchRolesData = useFetchRolesData();
  const { pollingController } = useAppStore();

  useEffect(() => {
    fetchUsersData();
    fetchRolesData();
    let interval
    if (!pollingController) {
      interval = setInterval(() => {
        fetchUsersData(search);
        fetchRolesData();
      }, 300000);
    }
    return () => clearInterval(interval)
  }, []);

  return (
    <Content.Root>
      <Users.Filter />
      <Users.Table />
      <Users.Pagination />
    </Content.Root>
  );
};

export default UsersContent;
