import React from "react"

import Budgets from "../../components/Budgets"
import Header from "../../components/Common/Header"

const BudgetsPage = () => {
  const title = "Lista de Orçamentos"
  const textButton = "Cadastrar"
  const redirectPage = () => {
    window.location.href = "/orcamentos/criar"
  }
  return (
    <>
      <Header.Root>
        <Header.Title title={title} />
        <Header.Button
          text={textButton}
          action={redirectPage}
        />
      </Header.Root>
      <Budgets.Content />
    </>
  )
}

export default BudgetsPage
