import React, { useEffect } from "react";

import Professionals from ".";
import {
  useFetchProfessionalsData,
} from "../../hooks";
import { useAppStore, useProfessionalsStore } from "../../store";
import Content from "../Common/Content";

const ProfessionalsContent = () => {
  const { pollingController } = useAppStore();
  const { search } = useProfessionalsStore();
  const fetchProfessionalsData = useFetchProfessionalsData();

  useEffect(() => {
    fetchProfessionalsData();
    let interval
    if (!pollingController) {
      interval = setInterval(() => {
        fetchProfessionalsData(search);
      }, 300000);
    }
    return () => clearInterval(interval)
  }, []);

  return (
    <Content.Root>
      <Professionals.Filter />
      <Professionals.Table />
      <Professionals.Pagination />
    </Content.Root>
  );
};

export default ProfessionalsContent;
