/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react"

import PropTypes from "prop-types"

import UtilsComponents from "../../Common/UtilsComponents"

const BudgetsViewerHeaderCell = ({
  bgColor,
  width,
  margin,
  textColor,
  colSpan,
  data,
  text,
  type,
  altTitle,
  srcImg,
  textAlign,
}) => {
  const tableStyle = `px-3 py-2 whitespace-nowrap text-base border border-solid border-[#a8a1a1] ${textAlign} ${bgColor} ${width} ${margin} ${textColor}`
  switch (type) {
  case "infoAddress":
    return (
      <th
        className={tableStyle}
        colSpan={colSpan}
      >
        {data.map((item) => {
          return (
            <span>
              {item}
              <br />
            </span>
          )
        })}
      </th>
    )
  case "infoUser":
    return (
      <th
        className={tableStyle}
        colSpan={colSpan}
      >
        {data.map((item) => {
          return (
            <span>
              {item.icon != null && (
                <UtilsComponents.Icon
                  name={item.icon}
                  size="24"
                  color="#000000"
                  className="hover:opacity-50"
                  style={{
                    margin: "0 0.25rem",
                    float: "left",
                  }}
                />
              )}
              {item.icon == "Instagram" ? (
                <a href={`https://instagram.com/${item.value}`} target="_blank" rel="noreferrer">
                  @
                  {item.value}
                </a>
              ) : (
                <span>
                  {item.value}
                </span>
              )}
              <br />
            </span>
          )
        })}
      </th>
    )
  case "text":
    return (
      <th
        className={tableStyle}
        colSpan={colSpan}
      >
        {text}
      </th>
    )
  case "image":
    return (
      <th
        className={tableStyle}
        colSpan={colSpan}
      >
        <img src={srcImg} alt={altTitle} width="200" />
      </th>
    )
  default:
    return null
  }
}

BudgetsViewerHeaderCell.propTypes = {
  bgColor: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  textColor: PropTypes.string,
  colSpan: PropTypes.string,
  data: PropTypes.array,
  text: PropTypes.string,
  type: PropTypes.string,
  altTitle: PropTypes.string,
  srcImg: PropTypes.string,
  textAlign: PropTypes.string,
};

BudgetsViewerHeaderCell.defaultProps = {
  type: "",
  bgColor: "bg-lightGray",
  width: "",
  margin: "",
  textColor: "text-neutral800",
  colSpan: "1",
  data: [],
  text: "",
  altTitle: "",
  srcImg: "",
  textAlign: "text-left",
}

export default BudgetsViewerHeaderCell
