import React from "react"

import PropTypes from "prop-types"

// import { FilterInputRoot as FilterInputRootUI } from "./styles"

const FilterInputRoot = ({ children, name, width }) => {
  const testId = name ? `${name}FilterInputRoot` : "filterInputRoot"
  return (
    <div
      data-testid={testId}
      className="w-full flex flex-row flex-wrap justify-between items-center py-2 px-2"
      style={{ width }}
    >
      {children}
    </div>
  )
}

FilterInputRoot.propTypes = {
  name: PropTypes.string,
  width: PropTypes.string,
}

FilterInputRoot.defaultProps = {
  name: "",
  width: "39%",
}

export default FilterInputRoot
