import React from "react"

const HeaderRoot = ({ children }) => {
  return (
    <header
      className="w-full flex justify-between items-center bg-stone100 p-4 border-solid border-black border-0 border-b"
      data-testid="headerRoot"
    >
      {children}
    </header>
  )
}

export default HeaderRoot
