import React from "react"

import { useFinishesStore } from "../../store"
import Pagination from "../Common/Pagination"

const FinishesPagination = () => {
  const { pageNumber, totalPages, setPageNumber } = useFinishesStore()

  const handlePagination = (value) => {
    setPageNumber(value)
  }

  return (
    <Pagination.Root spacing={2}>
      <Pagination.Number
        totalPages={totalPages}
        pageNumber={pageNumber}
        action={handlePagination}
      />
    </Pagination.Root>
  )
}

export default FinishesPagination
