import React from "react"

import PropTypes from "prop-types"

const ModalHeaderTitle = ({ title }) => {
  return (
    <h3
      className="text-xl mb-2 font-bold text-neutral800"
      data-testid="modalHeaderTitle"
    >
      {title}
    </h3>
  )
}

ModalHeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default ModalHeaderTitle
