import React from "react"

import { useUserStore } from "../../store"
import Pagination from "../Common/Pagination"

const UsersPagination = () => {
  const { totalPages, pageNumber, setPageNumber } = useUserStore()
  const handlePagination = (e, value) => {
    setPageNumber(value)
  }
  return (
    <Pagination.Root>
      <Pagination.Number
        totalPages={totalPages}
        pageNumber={pageNumber}
        action={handlePagination}
      />
    </Pagination.Root>
  )
}

export default UsersPagination
