import React from "react";

import PropTypes from "prop-types";

const InputFileImg = ({ border, image, altText }) => {
  const testId = `${altText}InputFileImg`
  return (
    <img
      src={image}
      alt={altText}
      className="w-full h-auto my-2"
      style={{ borderRadius: border ? "50%" : "0" }}
      data-testid={testId}
    />
  );
};

InputFileImg.propTypes = {
  image: PropTypes.string.isRequired,
  border: PropTypes.bool,
  altText: PropTypes.string,
};

InputFileImg.defaultProps = {
  border: false,
  altText: "Foto do móvel",
};

export default InputFileImg;
