import React from "react"

import PropTypes from "prop-types"

import UtilsComponents from "../UtilsComponents"

const ContentEdit = ({ action }) => {
  return (
    <UtilsComponents.Icon
      name="Pencil"
      size="24"
      color="#000000"
      onClick={action}
      data-testid="contentEdit"
      className="cursor-pointer hover:opacity-70 mx-1"
    />
  )
}

ContentEdit.propTypes = {
  action: PropTypes.func.isRequired,
}

export default ContentEdit
