import TablesContent from "./TablesContent"
import TablesFilter from "./TablesFilter"
import TablesModal from "./TablesModal"
import TablesPagination from "./TablesPagination"
import TablesTable from "./TablesTable"

const Tables = {
  Content: TablesContent,
  Filter: TablesFilter,
  Modal: TablesModal,
  Pagination: TablesPagination,
  Table: TablesTable,
}

export default Tables
