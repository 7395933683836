import React, { useEffect } from "react";

import Clients from ".";
import {
  useFetchClientsData,
} from "../../hooks";
import { useAppStore, useClientsStore } from "../../store";
import Content from "../Common/Content";

const ClientsContent = () => {
  const { pollingController } = useAppStore();
  const { search } = useClientsStore();
  const fetchClientsData = useFetchClientsData();

  useEffect(() => {
    fetchClientsData();
    let interval
    if (!pollingController) {
      interval = setInterval(() => {
        fetchClientsData(search);
      }, 300000);
    }
    return () => clearInterval(interval)
  }, []);

  return (
    <Content.Root>
      <Clients.Filter />
      <Clients.Table />
      <Clients.Pagination />
    </Content.Root>
  );
};

export default ClientsContent;
