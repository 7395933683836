import React from "react"

import PropTypes from "prop-types"

const ContentTable = ({
  children,
  ariaLabel,
}) => {
  return (
    <table
      data-testid="contentTable"
      aria-label={ariaLabel}
      className="w-full bg-brown700 rounded-md border-separate"
      style={{ minWidth: "700px" }}
    >
      {children}
    </table>
  )
}

ContentTable.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
}

export default ContentTable
