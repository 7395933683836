import Cookies from "js-cookie"

const authHeader = () => {
  const user = JSON.parse(Cookies.get("user") || "{}")

  if (user && user.accessToken) {
    return { "x-access-token": user.accessToken }
  }
  return {}
}

export default authHeader
