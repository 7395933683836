import React from "react"

import PropTypes from "prop-types"

const MenuListItem = ({ children, title }) => {
  return (
    <li
      data-testid="menuListItem"
      className="w-full block py-3"
      key={`menuListItem${title}`}
    >
      {children}
    </li>
  )
}

MenuListItem.propTypes = {
  title: PropTypes.string.isRequired,
}

export default MenuListItem
