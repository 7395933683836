import React from "react"

import PropTypes from "prop-types"

const InputLabel = ({ label, name, align }) => {
  const testId = `${name}InputLabel`
  return (
    <label
      className="font-bold w-full block text-neutral800 text-base"
      htmlFor={name}
      data-testid={testId}
      style={{
        textAlign: align,
      }}
    >
      {label}
    </label>
  )
}

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  align: PropTypes.string,
}

InputLabel.defaultProps = {
  name: "",
  align: "left",
}

export default InputLabel
