import React from "react"

const ContentRoot = ({ children }) => {
  return (
    <section
      data-testid="contentRoot"
      className="w-full flex flex-row flex-wrap justify-between items-center"
    >
      {children}
    </section>
  )
}

export default ContentRoot
