/* eslint-disable no-unused-vars */
import React from "react"

import PropTypes from "prop-types"

const BudgetsViewerButton = ({
  text,
  action,
  width,
  bgColor,
}) => {
  const styleValues = `${width} ${bgColor} h-12 mt-2 mr-1 font-bold text-base rounded-md hover:opacity-70 text-white sticky bottom-0 left-0 min-w-12`
  return (
    <button
      data-testid="inputButton"
      className={styleValues}
      type="submit"
      onClick={() => action("")}
    >
      {text}
    </button>
  )
}

BudgetsViewerButton.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func,
  width: PropTypes.string,
  bgColor: PropTypes.string,
}

BudgetsViewerButton.defaultProps = {
  width: "w-full",
  bgColor: "bg-[#999292]",
  action: () => {},
}

export default BudgetsViewerButton
