import { BudgetsDataService } from "../services"
import { CustomError } from "../utils"

export const getAllBudgets = async (
  company,
  name = "",
  client = "",
  attendant = "",
  professional = "",
  date = "",
  page = 1,
) => {
  try {
    const { data } = await BudgetsDataService.getAllBudgets(
      company,
      name,
      client,
      attendant,
      professional,
      date,
      page,
    )
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao pegar lista de Orçamentos"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const createBudgets = async (dataFinishes) => {
  try {
    const { data } = await BudgetsDataService.createBudgets(dataFinishes)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao criar Cliente"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const updateBudgets = async (id, data) => {
  try {
    const response = await BudgetsDataService.updateBudgets(id, data)
    return response.data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao atualizar Orçamento"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const deleteBudgets = async (id, company) => {
  try {
    const response = await BudgetsDataService.deleteBudgets(id, company)
    return response.data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao deletar Orçamento"
    throw new CustomError(message, statusText, status, errors)
  }
}
