import React from "react";

import PropTypes from "prop-types"

const Root = ({ children, key }) => {
  return (
    <div
      className="relative"
      key={key}
    >
      {children}
    </div>
  );
};

Root.propTypes = {
  key: PropTypes.string,
};

Root.defaultProps = {
  key: "",
};

export default Root;
