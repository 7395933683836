/* eslint-disable consistent-return */
import Cookies from "js-cookie"
import { isEmpty } from "lodash"
import { create } from "zustand"

import {
  getAllTables,
  updateTable,
  createTable,
  deleteTable,
} from "../actions/tablesActions"
import { sortListBy, CustomError } from "../utils"

const useTablesStore = create((set, get) => ({
  search: "",
  pageNumber: 1,
  totalPages: 1,
  totalItems: 0,
  tablesList: [],
  table: {},

  setTable: (dataUser) => {
    set(() => ({
      table: dataUser,
    }))
  },
  setSearch: (search) => {
    set(() => ({
      search,
    }))
  },
  setTotalPages: (totalPages) => {
    set(() => ({
      totalPages,
    }))
  },
  setTotalItems: (totalItems) => {
    set(() => ({
      totalItems,
    }))
  },
  setTablesList: (tablesList) => {
    set(() => ({
      tablesList,
    }))
  },
  setPageNumber: (pageNumber) => {
    set(() => ({
      pageNumber,
    }))
  },

  createTable: async (dataTable) => {
    try {
      const { updateTablesList } = get()
      const data = await createTable(dataTable)
      updateTablesList(data)
      const message = "Tabela criado com sucesso"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao criar Tabela",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  updateTable: async (id, dataTable) => {
    try {
      const { updateTablesList } = get()
      const data = await updateTable(id, dataTable)
      updateTablesList(data)
      const message = "Tabela atualizado com sucesso"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao atualizar Tabela",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  deleteTable: async (companyTable, email, id) => {
    try {
      await deleteTable(companyTable, email, id)
      const { tablesList, setTablesList } = get()
      const newTableList = tablesList.filter((table) => table.id !== id)
      setTablesList(newTableList)
      const message = "Tabela deletada com sucesso"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao deletar Tabela",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  getCurrentTable: () => {
    const { setTable } = get()
    const table = JSON.parse(Cookies.get("table") || "{}")
    setTable(table)
    return table
  },

  getTablesList: async (companyTable, search = "", page = "1") => {
    try {
      let newTablesList = []
      const {
        setTotalItems,
        setTotalPages,
        setTablesList,
        setSearch,
        setPageNumber,
      } = get()
      const data = await getAllTables(companyTable, search, page)
      const {
        rows = [],
        totalItems,
        totalPages,
        currentPage,
      } = data
      newTablesList = rows.map((table) => table)
      setTablesList(newTablesList)
      setSearch(search)
      setPageNumber(currentPage)
      setTotalItems(totalItems)
      setTotalPages(totalPages)
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Tabelas",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  updateTablesList: (newUser = {}) => {
    set((state) => {
      if (!isEmpty(newUser) && newUser.id) {
        let newTableList = state.tablesList.filter(
          (table) => table.id !== newUser.id,
        )
        newTableList.push(newUser)
        newTableList = sortListBy(newTableList, "name")
        return {
          tablesList: newTableList,
        }
      }
    })
  },
}))

export default useTablesStore
