import React from "react";

import Furnitures from ".";
import { TypeList } from "../../constants/typeList"
import {
  useHandleModal,
  useHandleSnackbar,
} from "../../hooks";
import {
  useFurnituresStore,
  useUserStore,
  useCategoryStore,
  useAppStore,
} from "../../store";
import Content from "../Common/Content";

const FurnituresTable = () => {
  const handleSnackbar = useHandleSnackbar();
  const handleModal = useHandleModal();
  const { modalController } = useAppStore();
  const { categoriesList } = useCategoryStore();
  const { user } = useUserStore();
  const {
    furnitureList,
    deleteFurniture,
    updateFurniture,
  } = useFurnituresStore();
  const { company } = user;

  const handleDeleteFurniture = async (id) => {
    try {
      const { message } = await deleteFurniture(company, id);
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      };
      handleSnackbar(snackbarParams);
    } catch (errors) {
      const {
        message = "Erro ao deletar Móvel",
        statusText = "",
        statusCode = 422,
        error,
      } = errors;
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      };
      handleSnackbar(snackbarParams);
      console.error(message, error);
    }
  };

  const handleUpdateHighlight = async (id, furniture, highlight) => {
    const updatedFurniture = new FormData()
    updatedFurniture.append("id", furniture.id)
    updatedFurniture.append("name", furniture.name)
    updatedFurniture.append("factory", furniture.factory)
    updatedFurniture.append("designer", furniture.designer)
    updatedFurniture.append("category", furniture.category)
    updatedFurniture.append("company", furniture.company)
    updatedFurniture.append("sizes", JSON.stringify(furniture.sizes))
    updatedFurniture.append("model", furniture.model)
    updatedFurniture.append("main_photo", furniture.main_photo)
    updatedFurniture.append("showInWeb", furniture.showInWeb)
    updatedFurniture.append("type", furniture.type)
    updatedFurniture.append("finishes", furniture.finishes)
    updatedFurniture.append("highlight", highlight)
    for (let i = 0; i < furniture.gallery.length; i++) {
      updatedFurniture.append("gallery", furniture.gallery[i])
    }
    try {
      const { message } = await updateFurniture(id, updatedFurniture);
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      };
      handleSnackbar(snackbarParams);
    } catch (errors) {
      const {
        message = "Erro ao atualizar Móvel",
        statusText = "",
        statusCode = 422,
        error,
      } = errors;
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      };
      handleSnackbar(snackbarParams);
      console.error(message, error);
    }
  }

  return (
    <Content.Table ariaLabel="Furnitures Table">
      <Content.Header>
        <Content.Row key="Furnitures Table Header">
          <Content.HeaderCell text="Foto" />
          <Content.HeaderCell text="Nome" />
          <Content.HeaderCell text="Categoria" />
          <Content.HeaderCell text="Fábrica" />
          <Content.HeaderCell text="Tipo" />
          <Content.HeaderCell text="Valor" />
          <Content.HeaderCell text="Ações" />
        </Content.Row>
      </Content.Header>
      <Content.Body>
        {furnitureList.map((furniture) => {
          const categoryLabel = categoriesList.find(
            (element) => element.id == furniture.category,
          ) || "";
          const typeLabel = TypeList.find(
            (element) => element.id == furniture.type,
          )

          const modalKey = `FurnitureUpdateModalKey${furniture.id}`;
          return (
            <Content.Row key={furniture.id}>
              <Content.Cell
                type="photo"
                photo={furniture.main_photo}
                image={furniture.main_photo}
              />
              <Content.Cell type="text" text={furniture.name} />
              <Content.Cell type="text" text={categoryLabel.label} />
              <Content.Cell type="text" text={furniture.factory} />
              <Content.Cell type="text" text={typeLabel.label} />
              <Content.Cell type="array" text={furniture.sizes} />
              <Content.Cell type="button" align="right">
                <Content.Highlight
                  highlight={furniture.highlight}
                  // eslint-disable-next-line max-len
                  action={() => handleUpdateHighlight(furniture.id, furniture, !furniture.highlight)}
                />
                <Content.Edit action={() => handleModal(modalKey)} />
                <Content.Delete
                  action={() => handleDeleteFurniture(furniture.id)}
                />
              </Content.Cell>

              {modalController === modalKey && (
                <Furnitures.Modal
                  showKey={modalKey}
                  furniture={furniture}
                  action="update"
                />
              )}
            </Content.Row>
          );
        })}
      </Content.Body>
    </Content.Table>
  );
};

export default FurnituresTable;
