import React from "react"

const BudgetsViewerHeader = ({
  children,
}) => {
  return (
    <thead
      className="w-full"
    >
      {children}
    </thead>
  )
}

export default BudgetsViewerHeader
