import React from "react"

const MenuHeader = ({ children }) => {
  return (
    <div
      data-testid="menuHeader"
      className="block"
    >
      {children}
    </div>
  )
}

export default MenuHeader
