import React from "react"

const ContentBody = ({ children }) => {
  return (
    <tbody
      data-testid="contentBody"
      className="w-full"
    >
      {children}
    </tbody>
  )
}

export default ContentBody
