/* eslint-disable camelcase */
import React, { useState } from "react"
import { useForm } from "react-hook-form"

import PropTypes from "prop-types"

import { TypeList } from "../../constants/typeList"
import {
  useHandleModal,
  useHandleSnackbar,
} from "../../hooks"
import {
  useAppStore,
  useFurnituresStore,
  useUserStore,
  useCategoryStore,
  useFinishesStore,
} from "../../store"
import { maskCurrency } from "../../utils"
import { Modal, ModalHeader, Input } from "../Common/Modal"
import UtilsComponents from "../Common/UtilsComponents"

const FurnituresModal = ({ showKey = "", furniture = {}, action }) => {
  const { modalController } = useAppStore()
  const { createFurniture, updateFurniture } = useFurnituresStore()
  const { categoriesList } = useCategoryStore()
  const { user } = useUserStore()
  const { finishesList } = useFinishesStore()
  const dataFinishesList = finishesList.map((element) => {
    return {
      value: element.id,
      label: element.name,
    }
  })
  const handleModal = useHandleModal()
  const handleSnackbar = useHandleSnackbar()

  const { company: companyUser, user_roles: userRoles } = user || ""
  const {
    id = "",
    name = "",
    main_photo = "",
    factory = "",
    category = undefined,
    designer = "",
    model = "",
    gallery = [],
    showInWeb = false,
    highlight = false,
    type = undefined,
    finishes = [],
    sizes = [
      {
        keyValue: "1",
        quantity: "0",
        width: "0",
        height: "0",
        depth: "0",
        price: "R$ 0,00",
      },
    ],
  } = furniture

  const [previewImage, setPreviewImage] = useState(main_photo)
  const [mainPhotoValue, setMainPhotoValue] = useState(main_photo)
  const [galleryImages, setGalleryImages] = useState(gallery)
  const [finishesValue, setFinishesValue] = useState(finishes)
  const [sizesValue, setSizesValue] = useState(sizes)
  const [sizesLength, setSizesLength] = useState(sizes.length)
  const [previewImagesGalery, setPreviewImagesGalery] = useState(gallery)
  const [modelValue, setModelValue] = useState(model)

  const buttonText = id ? "Atualizar" : "Cadastrar"
  const headerText = id ? "Editar Móvel" : "Cadastrar Móvel"
  const isCreating = action === "create"
  const isUpdating = action === "update"

  const defaultFinishesValue = finishes === null ? [] : finishes.map((id) => {
    const found = finishesList.find((item) => item.id === parseInt(id, 10));
    return found ? { value: found.id, label: found.name } : null;
  });

  const handleFinishesChange = (e) => {
    const dataValues = []
    e.forEach((element) => {
      dataValues.push(element.value.toString())
    })
    setFinishesValue(dataValues)
  }

  const handleModel = async (e) => {
    const file = e.target.files[0]
    setModelValue(file)
  }

  const handleGaleryImages = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImagesGalery((oldImages) => [...oldImages, reader.result]);
      };
      reader.readAsDataURL(file);
    });

    setGalleryImages((oldImages) => [...oldImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setGalleryImages((oldImages) => oldImages.filter((_, i) => i !== index));
    setPreviewImagesGalery((oldImages) => oldImages.filter((_, i) => i !== index));
  };

  const handleMainPhoto = (e) => {
    const file = e.target.files[0]
    setMainPhotoValue(file)
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id,
      name,
      factory,
      category,
      designer,
      model,
      main_photo,
      gallery,
      galleryLength: 0,
      showInWeb,
      highlight,
      type,
      finishes,
      sizes,
    },
  })

  const handleAddSizes = () => {
    const defaultSizes = {
      keyValue: `${sizesLength + 1}`,
      quantity: "0",
      width: "0",
      height: "0",
      depth: "0",
      price: "R$ 0,00",
    }
    setSizesLength(sizesLength + 1)
    setSizesValue([...sizesValue, defaultSizes])
  }

  const handleRemoveSizes = (keyValue) => {
    const newSizesValue = sizesValue.filter(
      (element) => element.keyValue != keyValue,
    )
    setSizesLength(sizesLength - 1)
    setSizesValue(newSizesValue)
    reset({ sizes: newSizesValue });
  }

  const renderSizesInput = (element, index) => {
    const lastElement = index === sizesValue.length - 1
    return (
      <Modal.Block key={`sizes${element.keyValue}`}>
        <Input.Text
          register={register}
          name={`sizes[${index}].keyValue`}
          type="hidden"
          defaultValue={element.keyValue}
        />
        <Input.Root
          name={`sizes[${index}].quantity`}
          width="20%"
        >
          <Input.Label
            label="Quantidade"
            name={`sizes[${index}].quantity`}
          />
          <Input.Text
            register={register}
            name={`sizes[${index}].quantity`}
            type="number"
            testId="inputQuantity"
            defaultValue={element.quantity}
          />
          <Input.HelperText text="Valor em un." name="quantity" />
        </Input.Root>
        <Input.Root
          name={`sizes[${index}].width`}
          width="20%"
        >
          <Input.Label
            label="Largura"
            name={`sizes[${index}].width`}
          />
          <Input.Text
            register={register}
            name={`sizes[${index}].width`}
            testId="inputWidth"
            type="number"
            defaultValue={element.width}
          />
          <Input.HelperText text="Valor em cm" name="width" />
        </Input.Root>
        <Input.Root
          name={`sizes[${index}].height`}
          width="20%"
        >
          <Input.Label
            label="Altura"
            name={`sizes[${index}].height`}
          />
          <Input.Text
            register={register}
            name={`sizes[${index}].height`}
            testId="inputHeight"
            type="number"
            defaultValue={element.height}
            isHelperText
            textToHelp="Valor em cm"
            helperTextColor="#3f3e3e"
          />
          <Input.HelperText text="Valor em cm" name="height" />
        </Input.Root>
        <Input.Root
          name={`sizes[${index}].depth`}
          width="20%"
        >
          <Input.Label
            label="Profundidade"
            name={`sizes[${index}].depth`}
          />
          <Input.Text
            register={register}
            name={`sizes[${index}].depth`}
            testId="inputDepth"
            type="number"
            defaultValue={element.depth}
            isHelperText
            textToHelp="Valor em cm"
            helperTextColor="#3f3e3e"
          />
          <Input.HelperText text="Valor em cm" name="depth" />
        </Input.Root>
        <Input.Root
          name={`sizes[${index}].price`}
        >
          <Input.Label
            label="Preço"
            name={`sizes[${index}].price`}
          />
          <Input.TextWithMask
            register={register}
            name={`sizes[${index}].price`}
            testId="inputPrice"
            value={element.price}
            action={maskCurrency}
          />
        </Input.Root>
        {lastElement && (
          <>
            <Input.Root
              width="10%"
            >
              <UtilsComponents.Button
                child="+"
                action={() => handleAddSizes()}
                style={{ marginTop: "2rem" }}
              />
            </Input.Root>
            <Input.Root
              width="10%"
            >
              <UtilsComponents.Button
                child="-"
                action={() => handleRemoveSizes(element.keyValue, index)}
                style={{ marginTop: "2rem" }}
              />
            </Input.Root>
          </>
        )}

      </Modal.Block>
    )
  }
  const onSubmit = async (data) => {
    const dataFurniture = new FormData()
    dataFurniture.append("id", data.id)
    dataFurniture.append("name", data.name)
    dataFurniture.append("factory", data.factory)
    dataFurniture.append("designer", data.designer)
    dataFurniture.append("category", data.category)
    dataFurniture.append("company", companyUser)
    dataFurniture.append("model", modelValue)
    dataFurniture.append("main_photo", mainPhotoValue)
    dataFurniture.append("galleryLength", galleryImages.length)
    dataFurniture.append("showInWeb", data.showInWeb)
    dataFurniture.append("highlight", data.highlight)
    dataFurniture.append("type", data.type)
    dataFurniture.append("finishes", finishesValue)
    dataFurniture.append("sizes", JSON.stringify(data.sizes))
    for (let i = 0; i < galleryImages.length; i++) {
      dataFurniture.append("gallery", galleryImages[i])
    }
    if (isUpdating) {
      try {
        const { message } = await updateFurniture(id, dataFurniture)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao atualizar o Móvel!",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    } else if (isCreating) {
      try {
        const { message } = await createFurniture(dataFurniture)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao cadastrar o Móvel!",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    }
    reset()
    handleModal()
  }

  return (
    <Modal.Root
      isOpen={modalController === showKey && modalController !== ""}
      maxWidth="940px"
    >
      <ModalHeader.Root>
        <ModalHeader.Title title={headerText} />
      </ModalHeader.Root>
      <Modal.Form action={handleSubmit(onSubmit)}>
        <Modal.Container>
          <Modal.Block>
            <Input.Root
              name="name"
            >
              <Input.Label
                label="Nome"
                name="name"
              />
              <Input.Text
                register={register}
                name="name"
                testId="inputName"
                defaultValue={name}
                errors={errors}
                required
              />
              {errors.name && <Input.HelperText text="Este campo é obrigatório" /> }
            </Input.Root>
            <Input.Root
              name="factory"
            >
              <Input.Label
                label="Fábrica"
                name="factory"
              />
              <Input.Text
                register={register}
                name="factory"
                testId="inputFactory"
                defaultValue={factory}
              />
            </Input.Root>
            <Input.Root
              name="designer"
            >
              <Input.Label
                label="Designer"
                name="designer"
              />
              <Input.Text
                register={register}
                name="designer"
                testId="inputDesigner"
                defaultValue={designer}
              />
            </Input.Root>
          </Modal.Block>

          <Modal.Block>
            <Input.Root
              name="type"
            >
              <Input.Label
                label="Tipo"
                name="type"
              />
              <Input.Select
                register={register}
                name="type"
                testId="inputType"
                defaultValue={type}
                errors={errors}
                required
                dataList={TypeList}
              />
              {errors.type && <Input.HelperText text="Este campo é obrigatório" /> }
            </Input.Root>
            <Input.Root
              name="category"
            >
              <Input.Label
                label="Categoria"
                name="category"
              />
              <Input.Select
                register={register}
                name="category"
                testId="inputCategory"
                defaultValue={category}
                errors={errors}
                required
                disabled={isUpdating && userRoles > 3 && true}
                dataList={categoriesList}
              />
              {errors.category && <Input.HelperText text="Este campo é obrigatório" /> }
            </Input.Root>
            <Input.Root
              name="finishes"
            >
              <Input.Label
                label="Acabamentos"
                name="finishes"
              />
              <Input.MultiSelect
                register={register}
                name="finishes"
                testId="inputFinishes"
                action={handleFinishesChange}
                defaultValue={defaultFinishesValue}
                dataList={dataFinishesList}
              />
            </Input.Root>
            <Input.Root
              width="20%"
              name="showInWeb"
            >
              <Input.Label
                label="Visível"
                name="showInWeb"
                align="center"
              />
              <Input.Checkbox
                register={register}
                name="showInWeb"
                testId="inputShowInWeb"
                errors={errors}
                required
                checked={showInWeb}
              />
              {errors.showInWeb && <Input.HelperText text="Este campo é obrigatório" /> }
            </Input.Root>
            <Input.Root
              width="20%"
              name="highlight"
            >
              <Input.Label
                label="Destaque"
                name="highlight"
                align="center"
              />
              <Input.Checkbox
                register={register}
                name="highlight"
                testId="inputHighlight"
                errors={errors}
                checked={highlight}
              />
            </Input.Root>
          </Modal.Block>

          {sizesValue.map((element, index) => renderSizesInput(element, index))}

          <Modal.Block>
            <Input.Root
              name="main_photo"
            >
              <Input.Label
                label="Foto Destaque"
                name="main_photo"
              />
              <Input.File
                type="file"
                $show
                register={register}
                name="main_photo"
                testId="inputMainPhoto"
                action={handleMainPhoto}
              />
              {previewImage && (
                <Input.FileImg image={previewImage} altText={name || "Foto de móvel"} />
              )}
            </Input.Root>
            <Input.Root
              name="gallery"
            >
              <Input.Label
                label="Galeria de Fotos"
                name="gallery"
              />
              <Input.File
                type="file"
                $show
                register={register}
                multiple
                name="gallery"
                testId="inputGallery"
                action={handleGaleryImages}
              />
              {previewImagesGalery.length > 0 && (
                <Input.GridGallery container sx={{ width: "100%" }}>
                  {previewImagesGalery.map((previewImage, index) => (
                    <UtilsComponents.Root
                      key={previewImage}
                    >
                      <Input.FileImg image={previewImage} altText={name || "Foto de móvel"} />
                      <UtilsComponents.Button
                        child="Remover"
                        action={() => handleRemoveImage(index)}
                      />
                    </UtilsComponents.Root>
                  ))}
                </Input.GridGallery>
              )}
            </Input.Root>
            <Input.Root
              name="model"
            >
              <Input.Label
                label="Modelo 3d"
                name="model"
              />
              <Input.File
                type="file"
                $show
                register={register}
                name="model"
                testId="inputModel"
                action={handleModel}
              />
              {modelValue && (
                <Input.Label label="Este móvel tem um bloco de modelo 3d." name="model3d" />
              )}
            </Input.Root>
          </Modal.Block>

          <Input.Button text={buttonText} />
        </Modal.Container>
      </Modal.Form>

      <Modal.Close action={handleModal} />
    </Modal.Root>
  )
}

FurnituresModal.propTypes = {
  showKey: PropTypes.string.isRequired,
  furniture: PropTypes.object,
  action: PropTypes.string,
};

FurnituresModal.defaultProps = {
  furniture: {},
  action: "",
};

export default FurnituresModal
