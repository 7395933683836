import React from "react"

import PropTypes from "prop-types"

const ModalForm = ({ children, action }) => {
  return (
    <form
      data-testid="modalForm"
      onSubmit={action}
      className="w-full px-3 my-4 overflow-y-auto flex relative h-[90%]"
    >
      {children}
    </form>
  )
}

ModalForm.propTypes = {
  action: PropTypes.func.isRequired,
};

export default ModalForm
