import React from "react"

import { useMenuStore, useUserStore } from "../../store"

const MenuUserName = () => {
  const { user } = useUserStore()
  const { isMenuOpen } = useMenuStore()
  const { name } = user
  return (
    <p
      data-testid="menuUserName"
      className="text-neutral800 font-bold"
      style={{
        visibility: isMenuOpen ? "hidden" : "visible",
        fontSize: isMenuOpen ? "0" : "1.125rem",
      }}
    >
      Olá,
      {" "}
      {name}
    </p>
  )
}

export default MenuUserName
