import React from "react"

import BudgetsViewer from ".."

const BudgetsViewerTableProducts = () => {
  return (
    <BudgetsViewer.Table
      bgColor="bg-white"
      width="w-full"
      margin="mt-5"
    >
      <BudgetsViewer.Body>
        <BudgetsViewer.Row>
          <BudgetsViewer.HeaderCell
            type="text"
            text="Relação de Produtos"
            bgColor="#393939"
            textColor="text-white"
            colSpan="10"
          />
        </BudgetsViewer.Row>

        <BudgetsViewer.Row>
          <BudgetsViewer.HeaderCell
            type="text"
            text="Código"
            bgColor="#393939"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Foto"
            bgColor="#393939"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Produto"
            bgColor="#393939"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Descrição"
            bgColor="#393939"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Qtd"
            bgColor="#393939"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Valor Un."
            bgColor="#393939"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Desc (%)"
            bgColor="#393939"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Valor Un. Desc."
            bgColor="#393939"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Valor Total"
            bgColor="#393939"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Valor Total Desc."
            bgColor="#393939"
            textColor="text-white"
          />
        </BudgetsViewer.Row>

      </BudgetsViewer.Body>
    </BudgetsViewer.Table>
  )
}

export default BudgetsViewerTableProducts
