/* eslint-disable consistent-return */
import Cookies from "js-cookie"
import { isEmpty } from "lodash"
import { create } from "zustand"

import {
  getAllUsers,
  updateUser,
  createUser,
  loginUser,
  deleteUser,
} from "../actions/usersActions"
import { sortListBy, CustomError } from "../utils"

const useUserStore = create((set, get) => ({
  search: "",
  pageNumber: 1,
  totalPages: 1,
  totalItems: 0,
  usersList: [],
  user: {},

  setUser: (dataUser) => {
    set(() => ({
      user: dataUser,
    }))
  },
  setSearch: (search) => {
    set(() => ({
      search,
    }))
  },
  setTotalPages: (totalPages) => {
    set(() => ({
      totalPages,
    }))
  },
  setTotalItems: (totalItems) => {
    set(() => ({
      totalItems,
    }))
  },
  setUsersList: (usersList) => {
    set(() => ({
      usersList,
    }))
  },
  setPageNumber: (pageNumber) => {
    set(() => ({
      pageNumber,
    }))
  },

  loginUser: async (dataLogin) => {
    try {
      const { setUser } = get()
      const data = await loginUser(dataLogin)
      Cookies.set("user", JSON.stringify(data), { expires: 7 })
      setUser(data)
      const message = "Login realizado com sucesso"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao fazer login de Usuário",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  logoutUser: () => {
    const { setUser } = get()
    Cookies.remove("user")
    setUser({})
  },

  createUser: async (dataUser) => {
    try {
      const { updateUsersList } = get()
      const data = await createUser(dataUser)
      updateUsersList(data)
      const message = "Usuário criado com sucesso"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao criar Usuário",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  updateUser: async (id, dataUser) => {
    try {
      const { updateUsersList } = get()
      const data = await updateUser(id, dataUser)
      updateUsersList(data)
      const message = "Usuário atualizado com sucesso"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao atualizar Usuário",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  deleteUser: async (companyUser, email, id) => {
    try {
      await deleteUser(companyUser, email, id)
      const { usersList, setUsersList } = get()
      const newUserList = usersList.filter((user) => user.id !== id)
      setUsersList(newUserList)
      const message = "Usuário deletado com sucesso"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao deletar Usuário",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  getCurrentUser: () => {
    const { setUser } = get()
    const user = JSON.parse(Cookies.get("user") || "{}")
    setUser(user)
    return user
  },

  getUsersList: async (companyUser, search = "", page = "1") => {
    try {
      let newUsersList = []
      const {
        setTotalItems,
        setTotalPages,
        setUsersList,
        setSearch,
        setPageNumber,
      } = get()
      const data = await getAllUsers(companyUser, search, page)
      const {
        rows = [],
        totalItems,
        totalPages,
        currentPage,
      } = data
      newUsersList = rows.map((user) => user)
      setUsersList(newUsersList)
      setSearch(search)
      setPageNumber(currentPage)
      setTotalItems(totalItems)
      setTotalPages(totalPages)
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Usuários",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  updateUsersList: (newUser = {}) => {
    set((state) => {
      if (!isEmpty(newUser) && newUser.id) {
        let newUsersList = state.usersList.filter(
          (user) => user.id !== newUser.id,
        )
        newUsersList.push(newUser)
        newUsersList = sortListBy(newUsersList, "name")
        return {
          usersList: newUsersList,
        }
      }
    })
  },
}))

export default useUserStore
