import React, { useState } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";

const InputTextWithMask = ({
  register,
  name,
  required,
  value,
  action,
  testId,
  errors,
  placeholder,
}) => {
  const registerType = required
    ? { ...register(`${name}`, { required: `Recipe ${name} is required` }) }
    : { ...register(`${name}`) };
  const isError = !isEmpty(errors);

  const [inputValue, setInputValue] = useState(value)
  const handlePriceValue = (e) => {
    const currentValue = action(e.target.value)
    setInputValue(currentValue)
  }
  return (
    <input
      {...registerType}
      id={name}
      data-testid={testId}
      type="text"
      value={inputValue}
      onChange={handlePriceValue}
      placeholder={placeholder}
      name={name}
      className="w-full h-12 p-2 mt-2 border border-solid border-black rounded-md bg-stone200 focus:border-yellow800 hover:border-yellow800"
      style={{
        borderColor: isError ? "#EF4444" : "",
      }}
    />
  );
}

InputTextWithMask.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  testId: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
}

InputTextWithMask.defaultProps = {
  testId: "input-text-with-mask",
  errors: {},
  required: false,
  value: "",
  placeholder: "",
}

export default InputTextWithMask
