import React from "react"
import { Link } from "react-router-dom"

import PropTypes from "prop-types"

import { useMenuStore } from "../../store"

const MenuListItemLink = ({ children, title, location }) => {
  const { isMenuOpen } = useMenuStore()
  return (
    <Link
      data-testid={`menuListItemLink${title}`}
      to={location}
      title={title}
      className="flex items-left font-bold text- content-center justify-flex-start w-full hover:opacity-70"
      style={{
        justifyContent: isMenuOpen ? "flex-start" : "center",
        fontSize: isMenuOpen ? "1.125rem" : "0",
      }}
    >
      {children}
    </Link>
  )
}

MenuListItemLink.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
}

export default MenuListItemLink
