import React from "react"

import PropTypes from "prop-types"

import BudgetsViewer from "../"

const BudgetsViewerTableProfessional = ({ professional, actionRemove }) => {
  return (
    <BudgetsViewer.Table
      bgColor="bg-white"
      width="w-full"
      margin="mt-5"
    >
      <BudgetsViewer.Body>
        <BudgetsViewer.Row>
          <BudgetsViewer.HeaderCell
            type="text"
            text="Dados do Profissional"
            bgColor="lightGray"
            textColor="text-white"
            colSpan="3"
          />

        </BudgetsViewer.Row>
        <BudgetsViewer.Row>
          <BudgetsViewer.HeaderCell
            type="text"
            text="Nome"
            bgColor="lightGray"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Telefone"
            bgColor="lightGray"
            textColor="text-white"
          />
          <BudgetsViewer.HeaderCell
            type="text"
            text="Ações"
            bgColor="lightGray"
            textColor="text-white"
          />
        </BudgetsViewer.Row>
        <BudgetsViewer.Row>
          <BudgetsViewer.Cell
            type="text"
            text={professional.label}
          />
          <BudgetsViewer.Cell
            type="text"
            text={professional.telephone}
          />
          <BudgetsViewer.Cell
            type="add"
            width="w-[10%]"
          >
            <BudgetsViewer.Button
              text="x"
              action={actionRemove}
              bgColor="bg-[#ae3013]"
              width="w-full"
            />
          </BudgetsViewer.Cell>
        </BudgetsViewer.Row>
      </BudgetsViewer.Body>
    </BudgetsViewer.Table>
  )
}

BudgetsViewerTableProfessional.propTypes = {
  professional: PropTypes.object.isRequired,
  actionRemove: PropTypes.func.isRequired,
};

export default BudgetsViewerTableProfessional
