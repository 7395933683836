import React from "react"

import PropTypes from "prop-types"

const HeaderButton = ({ text, action }) => {
  return (
    <button
      data-testid="headerButton"
      className="px-6 py-2 rounded-md text-white bg-brown font-bold text-base hover:opacity-70"
      onClick={action}
      type="button"
    >
      {text}
    </button>
  )
}

HeaderButton.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
}

export default HeaderButton
