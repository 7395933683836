import React from "react"

const AdminRoot = ({ children }) => {
  return (
    <main
      className="flex justify-center flex-row flex-nowrap items-start"
      data-testid="adminRoot"
    >
      {children}
    </main>
  )
}

export default AdminRoot
