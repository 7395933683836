import http from "../http-common"

const getAllProfessionals = (
  company,
  name = "",
  page = 1,
) => {
  return http.get(`/professionals?name=${name}&company=${company}&page=${page}`)
}

const getProfessionals = (id) => {
  return http.get(`/professionals/${id}`)
}

const createProfessionals = (data) => {
  return http.post("/professionals", data)
}

const updateProfessionals = (id, data) => {
  return http.put(`/professionals/${id}`, data)
}

const deleteProfessionals = (id, company) => {
  return http.delete(`/professionals/${id}?company=${company}`)
}

const ProfessionalsDataService = {
  getAllProfessionals,
  getProfessionals,
  createProfessionals,
  updateProfessionals,
  deleteProfessionals,
}

export default ProfessionalsDataService
