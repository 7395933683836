import http from "../http-common"

const getAllCategories = (company, name = "") => {
  return http.get(`/categories?name=${name}&company=${company}`)
}

const getCategory = (id) => {
  return http.get(`/categories/${id}`)
}

const createCategory = (data) => {
  return http.post("/categories", data)
}

const updateCategory = (id, data) => {
  return http.put(`/categories/${id}`, data)
}

const deleteCategory = (id, company) => {
  return http.delete(`/categories/${id}?company=${company}`)
}

const CategoriesDataService = {
  getAllCategories,
  getCategory,
  createCategory,
  updateCategory,
  deleteCategory,
}

export default CategoriesDataService
