import React from "react"

import PropTypes from "prop-types"

const HeaderTitle = ({ title }) => {
  return (
    <h1
      className="text-3xl font-bold text-black"
      data-testid="headerTitle"
    >
      {title}
    </h1>
  )
}

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default HeaderTitle
