import React from "react"

import PropTypes from "prop-types"

const LoginImage = ({ image, text }) => {
  return (
    <img
      data-testid="loginImage"
      src={image}
      alt={text}
      className="mx-auto block w-auto max-h-[150px] transition-[0.3s] mb-4"
    />
  )
}

LoginImage.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string,
}

LoginImage.defaultProps = {
  text: "Logo da Empresa",
}

export default LoginImage
