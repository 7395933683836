import React, { useEffect } from "react";

import Categories from ".";
import {
  useFetchCategoriesData,
} from "../../hooks";
import { useAppStore, useCategoryStore } from "../../store";
import Content from "../Common/Content";

const CategoriesContent = () => {
  const { pollingController } = useAppStore();
  const { search } = useCategoryStore();
  const fetchCategoriesData = useFetchCategoriesData();

  useEffect(() => {
    fetchCategoriesData();
    let interval
    if (!pollingController) {
      interval = setInterval(() => {
        fetchCategoriesData(search);
      }, 300000);
    }
    return () => clearInterval(interval)
  }, []);

  return (
    <Content.Root>
      <Categories.Filter />
      <Categories.Table />
      <Categories.Pagination />
    </Content.Root>
  );
};

export default CategoriesContent;
