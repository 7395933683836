import FilterInput from "./FilterInput"
import FilterInputRoot from "./FilterInputRoot"
import FilterInputSelect from "./FilterInputSelect"
import FilterLabel from "./FilterLabel"
import FilterRoot from "./FilterRoot"
import FilterTitle from "./FilterTitle"

const Filter = {
  Input: FilterInput,
  InputRoot: FilterInputRoot,
  Select: FilterInputSelect,
  Label: FilterLabel,
  Title: FilterTitle,
  Root: FilterRoot,
}

export default Filter
