import { RolesDataService } from "../services";
import { CustomError } from "../utils";

export const getAllRoles = async (company, name = "") => {
  try {
    const response = await RolesDataService.getAll(company, name);
    return response.data;
  } catch (errors) {
    const { response } = errors;
    const { status, statusText } = response;
    const message = "Erro ao pegar lista de Cargos";
    throw new CustomError(message, statusText, status, errors);
  }
}
