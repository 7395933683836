export const MenuTypes = [
  {
    name: "Acabamentos",
    icon: "Brush",
    link: "/acabamentos",
    isAdmin: false,
    active: true,
  },
  {
    name: "Categorias",
    icon: "BookmarkCheck",
    link: "/categorias",
    isAdmin: false,
    active: true,
  },
  {
    name: "Clientes",
    icon: "SquareUser",
    link: "/clientes",
    isAdmin: false,
    active: true,
  },
  {
    name: "Dashboard",
    icon: "DashboardIcon",
    link: "/dashboard",
    isAdmin: true,
    active: false,
  },
  {
    name: "Móveis",
    icon: "Sofa",
    link: "/moveis",
    isAdmin: false,
    active: true,
  },
  {
    name: "Orçamentos",
    icon: "BadgeDollarSign",
    link: "/orcamentos",
    isAdmin: false,
    active: false,
  },
  {
    name: "Profissionais",
    icon: "Briefcase",
    link: "/profissionais",
    isAdmin: false,
    active: true,
  },
  {
    name: "Tabelas",
    icon: "Sheet",
    link: "/tabelas",
    isAdmin: false,
    active: true,
  },
  {
    name: "Usuários",
    icon: "UserRound",
    link: "/usuarios",
    isAdmin: true,
    active: true,
  },
]
