import http from "../http-common"

const getAllCompanies = () => {
  return http.get("/company")
}

const getCompany = (id) => {
  return http.get(`/company/${id}`)
}
const CompanyDataService = {
  getAllCompanies,
  getCompany,
}

export default CompanyDataService
