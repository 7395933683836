import React from "react"

const MenuListRoot = ({ children }) => {
  return (
    <nav
      data-testid="menuListRoot"
      className="w-full block py-4"
    >
      {children}
    </nav>
  )
}

export default MenuListRoot
