import { useEffect, useCallback } from "react"

import { useAppStore } from "../store"

const useHandleSnackbar = () => {
  const { setSnackbarController } = useAppStore()
  const handleSnackbar = useCallback((params = {}) => {
    setSnackbarController(params)
  }, [setSnackbarController])

  useEffect(() => {
    const timer = setTimeout(() => {
      setSnackbarController({})
    }, 5000)

    return () => clearTimeout(timer)
  }, [setSnackbarController])
  return handleSnackbar
}

export default useHandleSnackbar
