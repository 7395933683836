import { CompanyDataService } from "../services";
import { CustomError } from "../utils";

export const getCompany = async (id) => {
  try {
    const response = await CompanyDataService.getCompany(id);
    return response.data;
  } catch (errors) {
    const { response } = errors;
    const { status, statusText } = response;
    const message = "Erro ao pegar empresa";
    throw new CustomError(message, statusText, status, errors);
  }
}
