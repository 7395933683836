import { useHandleSnackbar } from "."
import { useUserStore, useFinishesStore } from "../store"

const useFetchFinishesData = () => {
  const { user } = useUserStore()
  const { getFinishes, pageNumber } = useFinishesStore()
  const handleSnackbar = useHandleSnackbar()
  const { company } = user
  const fetchFinishesData = async (
    searchValue = "",
    searchMaterial = "",
    searchFactory = "",
    searchType = "",
  ) => {
    try {
      await getFinishes(
        company,
        searchValue,
        searchMaterial,
        searchFactory,
        searchType,
        pageNumber,
      )
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Acabamentos",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
  }
  return fetchFinishesData
}

export default useFetchFinishesData
