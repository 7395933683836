import InputActionButton from "./InputActionButton"
import InputButton from "./InputButton"
import InputCheckBox from "./InputCheckBox"
import InputFile from "./InputFile"
import InputFileDefaultImg from "./InputFileDefaultImg"
import InputFileGridImg from "./InputFileGridGallery"
import InputFileImg from "./InputFileImg"
import InputHelperText from "./inputHelperText"
import InputLabel from "./InputLabel"
import InputMultiSelect from "./InputMultiSelect"
import InputRoot from "./InputRoot"
import InputSelect from "./InputSelect"
import InputText from "./InputText"
import InputTextWithMask from "./InputTextWithMask"

export const Input = {
  ActionButton: InputActionButton,
  Button: InputButton,
  Checkbox: InputCheckBox,
  File: InputFile,
  FileDefaultImg: InputFileDefaultImg,
  GridGallery: InputFileGridImg,
  FileImg: InputFileImg,
  HelperText: InputHelperText,
  Label: InputLabel,
  MultiSelect: InputMultiSelect,
  Root: InputRoot,
  Select: InputSelect,
  Text: InputText,
  TextWithMask: InputTextWithMask,
}

export default Input
