import React from "react"

import PropTypes from "prop-types"

import UtilsComponents from "../../UtilsComponents"

const ModalClose = ({ action }) => {
  return (
    <div className="ml-auto">
      <button
        onClick={action}
        type="button"
        className="absolute right-4 top-4 inline-flex flex-shrink-0 justify-center items-center h-4 w-6 rounded-md text-neutral800 hover:opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-600 transition-all text-sm dark:focus:ring-offset-gray-900 dark:focus:ring-gray-800"
        data-testid="modalClose"
      >
        <span className="sr-only">Close</span>
        <UtilsComponents.Icon
          name="XCircle"
          size="24"
          color="#000000"
          className="fill-current stroke-current hover:opacity-70"
        />
      </button>
    </div>
  )
}

ModalClose.propTypes = {
  action: PropTypes.func,
};

ModalClose.defaultProps = {
  action: () => {},
};

export default ModalClose
