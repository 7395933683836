import React from "react"
import { BrowserRouter as Router } from "react-router-dom"

import SiteRoutes from "./routes"

import "./assets/tailwind.css";
import "./styles.css"

const App = () => {
  return (
    <div className="w-full h-screen relative block bg-[#d4cfcf]">
      <Router>
        <SiteRoutes />
      </Router>
    </div>
  )
}

export default App
