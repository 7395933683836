import http from "../http-common"

const getAll = (company, name = "") => {
  return http.get(`/roles?name=${name}&company=${company}`)
}

const RolesDataService = {
  getAll,
}

export default RolesDataService
