/* eslint-disable camelcase */
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import FormData from "form-data";
import PropTypes from "prop-types";

import { TypeList } from "../../constants/typeList"
import { useHandleModal, useHandleSnackbar } from "../../hooks";
import { useAppStore, useFinishesStore, useUserStore } from "../../store";
import { ModalHeader, Input, Modal } from "../Common/Modal";

const FinishesModal = ({ showKey, finishes, action }) => {
  const { modalController } = useAppStore();
  const { createFinishes, updateFinishes } = useFinishesStore();
  const { user: userInfo } = useUserStore()
  const { company: userCompany } = userInfo

  const handleModal = useHandleModal();
  const handleSnackbar = useHandleSnackbar();

  const {
    id = "",
    name = "",
    main_photo = "",
    material = "",
    designer = "",
    factory = "",
    type = "",
    company = userCompany,
  } = finishes;
  const [previewImage, setPreviewImage] = useState(main_photo);
  const [photoPerfil, setPhotoPerfil] = useState(main_photo);

  const buttonText = id ? "Atualizar" : "Cadastrar";
  const headerText = id ? "Editar Acabamento" : "Cadastrar Acabamento";

  const isCreating = action === "create";
  const isUpdating = action === "update";

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id,
      name,
      main_photo: photoPerfil,
      material,
      designer,
      factory,
      type,
      company,
    },
  });

  const handleMainPhoto = async (e) => {
    const file = e.target.files[0];
    setPhotoPerfil(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleResetPhoto = () => {
    setPreviewImage("");
    setPhotoPerfil("");
    reset({ photo: "" });
  };

  const onSubmit = async (data) => {
    const finishesData = new FormData();

    finishesData.append("id", data.id);
    finishesData.append("name", data.name);
    finishesData.append("material", data.material);
    finishesData.append("designer", data.designer);
    finishesData.append("factory", data.factory);
    finishesData.append("type", data.type);
    finishesData.append("company", company);
    finishesData.append("main_photo", photoPerfil);

    if (isUpdating) {
      try {
        const { message } = await updateFinishes(id, finishesData)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao atualizar acabamento",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    } else if (isCreating) {
      try {
        const { message } = await createFinishes(finishesData)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao criar acabamento",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    }
    reset();
    handleModal();
  };

  return (
    <Modal.Root isOpen={modalController === showKey && modalController !== ""}>
      <ModalHeader.Root>
        <ModalHeader.Title title={headerText} />
      </ModalHeader.Root>
      <Modal.Form action={handleSubmit(onSubmit)}>
        <Modal.Container width="20%">
          <Modal.Block>
            <Input.Root
              name="main_photo"
            >
              <Input.Label
                label="Foto"
                name="main_photo"
              />
              {!previewImage && <Input.FileDefaultImg />}
              <Input.File
                register={register}
                name="main_photo"
                action={handleMainPhoto}
                testId="inputPhoto"
              />
              {previewImage && <Input.FileImg border image={previewImage} altText="Foto de Acabamento" />}
            </Input.Root>
          </Modal.Block>
          {previewImage && (
            <Modal.Block>
              <Input.ActionButton
                text="Remover Foto"
                action={handleResetPhoto}
              />
            </Modal.Block>
          )}
        </Modal.Container>
        <Modal.Container width="80%">
          <Modal.Block>
            <Input.Root
              name="name"
            >
              <Input.Label
                label="Nome"
                name="name"
              />
              <Input.Text
                register={register}
                name="name"
                defaultValue={name}
                errors={errors}
                required
                testId="inputName"
              />
            </Input.Root>
            <Input.Root
              name="material"
            >
              <Input.Label
                label="Material"
                name="material"
              />
              <Input.Text
                register={register}
                name="material"
                defaultValue={material}
                testId="inputMaterial"
              />
            </Input.Root>
          </Modal.Block>

          <Modal.Block>
            <Input.Root
              name="factory"
            >
              <Input.Label
                label="Fábrica"
                name="factory"
              />
              <Input.Text
                register={register}
                name="factory"
                type="factory"
                defaultValue={factory}
                errors={errors}
                required
                testId="inputFactory"
              />
            </Input.Root>
            <Input.Root
              name="designer"
            >
              <Input.Label
                label="Designer"
                name="designer"
              />
              <Input.Text
                register={register}
                name="designer"
                type="designer"
                defaultValue={factory}
                errors={errors}
                required
                testId="inputDesigner"
              />
            </Input.Root>
            <Input.Root
              name="type"
            >
              <Input.Label
                label="Tipo"
                name="type"
              />
              <Input.Select
                defaultValue={type}
                errors={errors}
                register={register}
                name="type"
                required
                dataList={TypeList}
                testId="inputType"
              />
            </Input.Root>
          </Modal.Block>

          <Modal.Block>
            <Input.Button type="submit" text={buttonText} />
          </Modal.Block>
        </Modal.Container>
      </Modal.Form>
      <Modal.Close action={handleModal} />
    </Modal.Root>
  );
};

FinishesModal.propTypes = {
  showKey: PropTypes.string.isRequired,
  finishes: PropTypes.object,
  action: PropTypes.string,
};

FinishesModal.defaultProps = {
  finishes: {},
  action: "",
};

export default FinishesModal;
