import { useHandleSnackbar } from "."
import { useUserStore, useRolesStore } from "../store"

const useFetchRolesData = () => {
  const { user } = useUserStore()
  const { getRolesList } = useRolesStore()
  const handleSnackbar = useHandleSnackbar()
  const { company } = user
  const fetchRolesData = async () => {
    try {
      await getRolesList(company)
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Cargos",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
  }
  return fetchRolesData
}

export default useFetchRolesData
