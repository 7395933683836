import { CategoriesDataService } from "../services"
import { CustomError } from "../utils"

export const getAllCategories = async (company, name = "") => {
  try {
    const { data } = await CategoriesDataService.getAllCategories(company, name)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao pegar lista de Categorias"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const createCategory = async (dataCategory) => {
  try {
    const { data } = await CategoriesDataService.createCategory(dataCategory)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao criar categoria"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const updateCategory = async (id, data) => {
  try {
    const response = await CategoriesDataService.updateCategory(id, data)
    return response.data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao atualizar categoria"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const deleteCategory = async (id, company) => {
  try {
    const response = await CategoriesDataService.deleteCategory(id, company)
    return response.data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao deletar categoria"
    throw new CustomError(message, statusText, status, errors)
  }
}
