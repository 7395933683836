import BudgetsViewerAddClient from "./Client/BudgetsViewerAddClient"
import BudgetsViewerTableClient from "./Client/BudgetsViewerTableClient"
import BudgetsViewerBlock from "./Common/BudgetsViewerBlock"
import BudgetsViewerBody from "./Common/BudgetsViewerBody"
import BudgetsViewerButton from "./Common/BudgetsViewerButton"
import BudgetsViewerCell from "./Common/BudgetsViewerCell"
import BudgetsViewerContent from "./Common/BudgetsViewerContent"
import BudgetsViewerForm from "./Common/BudgetsViewerForm"
import BudgetsViewerHeader from "./Common/BudgetsViewerHeader"
import BudgetsViewerHeaderCell from "./Common/BudgetsViewerHeaderCell"
import BudgetsViewerInputSelect from "./Common/BudgetsViewerInputSelect"
import BudgetsViewerRoot from "./Common/BudgetsViewerRoot"
import BudgetsViewerRow from "./Common/BudgetsViewerRow"
import BudgetsViewerTable from "./Common/BudgetsViewerTable"
import BudgetsViewerTableCompany from "./Company/BudgetsViewerTableCompany"
import BudgetsViewerAddFurniture from "./Furniture/BudgetsViewerAddFurniture"
import BudgetsViewerTableFurniture from "./Furniture/BudgetsViewerTableFurniture"
import BudgetsViewerAddProfessional from "./Professional/BudgetsViewerAddProfessional"
import BudgetsViewerTableProfessional from "./Professional/BudgetsViewerTableProfessional"

const BudgetsViewer = {
  AddClient: BudgetsViewerAddClient,
  AddProfessional: BudgetsViewerAddProfessional,
  Block: BudgetsViewerBlock,
  Body: BudgetsViewerBody,
  Button: BudgetsViewerButton,
  Cell: BudgetsViewerCell,
  Content: BudgetsViewerContent,
  Form: BudgetsViewerForm,
  Header: BudgetsViewerHeader,
  HeaderCell: BudgetsViewerHeaderCell,
  Select: BudgetsViewerInputSelect,
  Root: BudgetsViewerRoot,
  Row: BudgetsViewerRow,
  Table: BudgetsViewerTable,
  Client: BudgetsViewerTableClient,
  Company: BudgetsViewerTableCompany,
  AddFurniture: BudgetsViewerAddFurniture,
  Furnitures: BudgetsViewerTableFurniture,
  Professional: BudgetsViewerTableProfessional,
}

export default BudgetsViewer
