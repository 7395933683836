import http from "../http-common"

const getAll = (company, factory = "", page = 1) => {
  return http.get(`/tables?factory=${factory}&company=${company}&page=${page}`)
}

const create = (data) => {
  return http.post("/tables", data)
}

const update = (id, data) => {
  return http.put(`/tables/${id}`, data)
}

const remove = (company, id) => {
  return http.delete(`/tables/${id}?company=${company}`)
}

const TablesDataService = {
  getAll,
  create,
  update,
  remove,
}

export default TablesDataService
