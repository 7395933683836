import FinishesContent from "./FinishesContent"
import FinishesFilter from "./FinishesFilter"
import FinishesModal from "./FinishesModal"
import FinishesPagination from "./FinishesPagination"
import FinishesTable from "./FinishesTable"

const Finishes = {
  Content: FinishesContent,
  Filter: FinishesFilter,
  Modal: FinishesModal,
  Pagination: FinishesPagination,
  Table: FinishesTable,
}

export default Finishes
