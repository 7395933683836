import React from "react"

import { useMenuStore } from "../../store"

const MenuRoot = ({ children }) => {
  const { isMenuOpen } = useMenuStore()
  return (
    <aside
      className="overflow-hidden bg-brown700 border-0 border-r-2 border-solid border-[#a8a1a1] flex flex-col h-screen sticky top-0 let-0"
      data-testid="menuRoot"
      style={{
        width: isMenuOpen ? "auto" : "60px",
        minWidth: isMenuOpen ? "180px" : "60px",
        padding: isMenuOpen ? "15px" : "5px",
      }}
    >
      {children}
    </aside>
  )
}

export default MenuRoot
