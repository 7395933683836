import React from "react"

import { TypeList } from "../../constants/typeList"
import { useFetchFinishesData } from "../../hooks"
import { useFinishesStore } from "../../store"
import Filter from "../Common/Filter"

const UsersFilter = () => {
  const fetchFinishesData = useFetchFinishesData()
  const {
    search,
    searchMaterial,
    searchFactory,
    searchType,
  } = useFinishesStore();

  const dataTypeList = [
    {
      id: "",
      label: "Nenhum",
    },
    ...TypeList,
  ]

  let timeout

  const handleSearch = (event) => {
    clearTimeout(timeout)
    const searchValue = event.target.value
    timeout = setTimeout(async () => {
      fetchFinishesData(
        searchValue,
        searchMaterial,
        searchFactory,
        searchType,
      )
    }, 500)
  }
  const handleSearchMaterial = (event) => {
    clearTimeout(timeout)
    const searchValue = event.target.value
    timeout = setTimeout(async () => {
      fetchFinishesData(
        search,
        searchValue,
        searchFactory,
        searchType,
      )
    }, 500)
  }
  const handleSearchFactory = (event) => {
    clearTimeout(timeout)
    const searchValue = event.target.value
    timeout = setTimeout(async () => {
      fetchFinishesData(
        search,
        searchMaterial,
        searchValue,
        searchType,
      )
    }, 500)
  }
  const handleSearchType = (event) => {
    clearTimeout(timeout)
    const searchValue = event.target.value
    timeout = setTimeout(async () => {
      fetchFinishesData(
        search,
        searchMaterial,
        searchFactory,
        searchValue,
      )
    }, 500)
  }

  return (
    <Filter.Root>
      <Filter.InputRoot width="100%">
        <Filter.Title text="Filtros" />
      </Filter.InputRoot>
      <Filter.InputRoot width="25%">
        <Filter.Label text="Nome" testId="FilterSearchName" />
        <Filter.Input action={handleSearch} />
      </Filter.InputRoot>
      <Filter.InputRoot width="25%">
        <Filter.Label text="Material" testId="FilterSearchMaterial" />
        <Filter.Input action={handleSearchMaterial} />
      </Filter.InputRoot>
      <Filter.InputRoot width="25%">
        <Filter.Label text="Fábrica" testId="FilterSearchFactory" />
        <Filter.Input action={handleSearchFactory} />
      </Filter.InputRoot>
      <Filter.InputRoot width="25%">
        <Filter.Label text="Tipo" testId="FilterSearchType" />
        <Filter.Select
          name="highlight"
          action={handleSearchType}
          dataList={dataTypeList}
          testId="typeInputFilter"
        />
      </Filter.InputRoot>
    </Filter.Root>
  )
}

export default UsersFilter
