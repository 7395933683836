import React from "react"

const MenuList = ({ children }) => {
  return (
    <ul
      data-testid="menuList"
      className="w-full block"
    >
      {children}
    </ul>
  )
}

export default MenuList
