import { TablesDataService } from "../services"
import { CustomError } from "../utils"

export const getAllTables = async (companyTable, search = "", page = 1) => {
  try {
    const { data } = await TablesDataService.getAll(companyTable, search, page)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao pegar lista de Tabelas"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const createTable = async (dataTable) => {
  try {
    const { data } = await TablesDataService.create(dataTable)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao criar Tabela"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const updateTable = async (id, dataTable) => {
  try {
    const { data } = await TablesDataService.update(id, dataTable)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao atualizar Tabela"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const deleteTable = async (companyTable, email, id) => {
  try {
    const { data } = await TablesDataService.remove(companyTable, email, id)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao deletar Tabela"
    throw new CustomError(message, statusText, status, errors)
  }
}
