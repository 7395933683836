import React from "react"

const PaginationRoot = ({ children }) => {
  return (
    <div data-testid="paginationRoot" spacing={2} className="py-3">
      {children}
    </div>
  )
}

export default PaginationRoot
