/* eslint-disable consistent-return */
import { create } from "zustand"

import {
  getAllCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../actions/categoriesActions"
import { sortListBy, CustomError } from "../utils"

const useCategoryStore = create((set, get) => ({
  category: {},
  categoriesList: [],
  totalPages: 1,
  totalItems: 0,
  pageNumber: 1,
  search: "",

  setPageNumber: (pageNumber) => {
    set(() => ({
      pageNumber,
    }))
  },
  setTotalPages: (totalPages) => {
    set(() => ({
      totalPages,
    }))
  },
  setTotalItems: (totalItems) => {
    set(() => ({
      totalItems,
    }))
  },
  setCategoriesList: (categoryData) => {
    set(() => ({
      categoriesList: categoryData,
    }))
  },
  setSearch: (search) => {
    set(() => ({
      search,
    }))
  },

  getCategories: async (company, name = "") => {
    try {
      const {
        setSearch,
        setCategoriesList,
        setTotalPages,
        setTotalItems,
        setPageNumber,
      } = get()

      const response = await getAllCategories(company, name)
      const {
        totalItems = "0",
        totalPages = "1",
        rows = [],
        currentPage = "1",
      } = response
      let orderedCategoryList = []
      rows.forEach((element) => {
        const responseCategories = {
          id: element.id,
          label: element.name,
          description: element.description,
        }
        orderedCategoryList.push(responseCategories)
      })
      orderedCategoryList = sortListBy(orderedCategoryList, "label")
      setCategoriesList(orderedCategoryList)
      setSearch(name)
      setTotalItems(totalItems)
      setTotalPages(totalPages)
      setPageNumber(currentPage)
      const message = "Lista de categorias retornada com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Categorias",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  createCategory: async (categoryData) => {
    try {
      const { categoriesList, setCategoriesList } = get()
      const response = await createCategory(categoryData)
      let orderedCategoryList = []
      const newCategory = {
        id: response.id,
        label: response.name,
        description: response.description,
      }
      categoriesList.push(newCategory)
      orderedCategoryList = sortListBy(categoriesList, "label")
      setCategoriesList(orderedCategoryList)
      const message = "Categoria criada com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao criar Categoria",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  updateCategory: async (id, categoryData) => {
    try {
      const { categoriesList, setCategoriesList } = get()
      const response = await updateCategory(id, categoryData)
      const { category } = response
      let orderedCategoryList = []
      const updatedCategory = {
        id: category.id,
        label: category.name,
        description: category.description,
      }
      const index = categoriesList.findIndex((categoryItem) => categoryItem.id === id)
      categoriesList[index] = updatedCategory
      orderedCategoryList = sortListBy(categoriesList, "label")
      setCategoriesList(orderedCategoryList)
      const message = "Categoria atualizada com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao atualizar Categoria",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  deleteCategories: async (id, company) => {
    try {
      await deleteCategory(id, company)
      const { setCategoriesList, categoriesList } = get()
      const newCategoriesList = categoriesList.filter(
        (category) => category.id !== id,
      )
      setCategoriesList(newCategoriesList)
      const message = "Categoria deletada com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao deletar Categoria",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },
}))

export default useCategoryStore
