export const TypeList = [
  {
    id: 1,
    label: "Indoor",
  },
  {
    id: 2,
    label: "Outdoor",
  },
]
