/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import React, { useMemo } from "react";

import Menu from ".";
import { MenuTypes } from "../../constants/menuTypes";
import { ADMIN_TYPES } from "../../constants/roleTypes"
import { useUserStore } from "../../store"
// import UtilsComponents from "../Common/UtilsComponents"

const MenuComponent = () => {
  const { user } = useUserStore()
  const { user_roles } = user
  const isAdmin = ADMIN_TYPES.includes(user_roles)

  const activeMenuTypes = useMemo(() => {
    return MenuTypes.filter((menuType) => menuType.active);
  }, [])

  return (
    <Menu.Root>
      <Menu.Header>
        <Menu.Logo />
        <Menu.UserRoot>
          <Menu.UserPhoto />
          <Menu.UserName />
        </Menu.UserRoot>
      </Menu.Header>
      <Menu.ListRoot>
        <Menu.List>
          {activeMenuTypes.map((menuItem) => {
            if (menuItem.isAdmin === isAdmin || !menuItem.isAdmin) {
              return (
                <Menu.ListItem key={`Menu${menuItem.name}`} title={menuItem.name}>
                  <Menu.ListItemLink
                    location={menuItem.link}
                    title={menuItem.name}
                  >
                    <Menu.ListItemIcon
                      icon={menuItem.icon}
                      size="24"
                      color="#000000"
                    />
                    {menuItem.name}
                  </Menu.ListItemLink>
                </Menu.ListItem>
              )
            }
          })}
          <Menu.ListItem key="Logout" title="Logout">
            <Menu.ListLogout title="Logout">
              <Menu.ListItemIcon
                icon="LogOut"
                size="24"
                color="#000000"
              />
            </Menu.ListLogout>
          </Menu.ListItem>
        </Menu.List>
      </Menu.ListRoot>
      <Menu.ToggleButton />
    </Menu.Root>
  );
};

export default MenuComponent;
