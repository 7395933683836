import { ProfessionalsDataService } from "../services"
import { CustomError } from "../utils"

export const getAllProfessionals = async (
  company,
  name = "",
  page = 1,
) => {
  try {
    const { data } = await ProfessionalsDataService.getAllProfessionals(
      company,
      name,
      page,
    )
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao pegar lista de Profissionais"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const createProfessionals = async (dataFinishes) => {
  try {
    const { data } = await ProfessionalsDataService.createProfessionals(dataFinishes)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao criar Profissional"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const updateProfessionals = async (id, data) => {
  try {
    const response = await ProfessionalsDataService.updateProfessionals(id, data)
    return response.data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao atualizar Profissional"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const deleteProfessionals = async (id, company) => {
  try {
    const response = await ProfessionalsDataService.deleteProfessionals(id, company)
    return response.data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao deletar Profissional"
    throw new CustomError(message, statusText, status, errors)
  }
}
