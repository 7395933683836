/* eslint-disable camelcase */
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import FormData from "form-data";
import PropTypes from "prop-types";

import { ADMIN_TYPES } from "../../constants/roleTypes";
import { useHandleModal, useHandleSnackbar } from "../../hooks";
import { useUserStore, useAppStore, useRolesStore } from "../../store";
import { maskPhone, maskCPF, maskCurrency } from "../../utils";
import { ModalHeader, Input, Modal } from "../Common/Modal";

const UsersModal = ({ showKey, user, action }) => {
  const { modalController } = useAppStore();
  const { user: userInfo, createUser, updateUser } = useUserStore();
  const { rolesList = [] } = useRolesStore();

  const handleModal = useHandleModal();
  const handleSnackbar = useHandleSnackbar();

  const { company: userCompany, user_roles: userRoles, id: userId } = userInfo;
  const {
    id = "",
    name = "",
    email = "",
    user_roles = undefined,
    telephone = "",
    salary = "R$ 0,00",
    start_date = "",
    end_date = "",
    birthday = "",
    cpf = "",
    company = userCompany,
    photo = "",
  } = user;

  const [previewImage, setPreviewImage] = useState(photo);
  const [photoPerfil, setPhotoPerfil] = useState(photo);
  const [passwordText, setPasswordText] = useState("Senha");
  const [resetPassword, setResetPassword] = useState(false);

  const buttonText = id ? "Atualizar" : "Cadastrar";
  const headerText = id ? "Editar Usuário" : "Cadastrar Usuário";
  const passwordResetText = resetPassword
    ? "Cancelar Alterar Senha"
    : "Alterar Senha";

  const isCreating = action === "create";
  const isUpdating = action === "update";
  const isUserLogedIn = userId === id;
  const isAdmin = ADMIN_TYPES.includes(userRoles)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id,
      name,
      email,
      telephone,
      salary,
      roles: user_roles,
      company,
      start_date,
      end_date,
      birthday,
      cpf,
      photo: photoPerfil,
    },
  });

  const handleMainPhoto = async (e) => {
    const file = e.target.files[0];
    setPhotoPerfil(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setPasswordText("Nova Senha");
    setResetPassword(!resetPassword);
  };

  const handleResetPhoto = () => {
    setPreviewImage("");
    setPhotoPerfil("");
    reset({ photo: "" });
  };

  const onSubmit = async (data) => {
    const userData = new FormData();
    userData.append("id", data.id);
    userData.append("name", data.name);
    userData.append("email", data.email);
    userData.append("telephone", data.telephone);
    userData.append("salary", data.salary);
    userData.append("roles", data.roles);
    userData.append("company", data.company);
    userData.append("start_date", data.start_date);
    userData.append("end_date", data.end_date);
    userData.append("birthday", data.birthday);
    userData.append("cpf", data.cpf);
    userData.append("photo", photoPerfil);

    if (isUpdating) {
      if (resetPassword) userData.append("password", data.password);
      try {
        const { message } = await updateUser(id, userData)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao atualizar usuário",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    } else if (isCreating) {
      userData.append("password", data.password);
      try {
        const { message } = await createUser(userData)
        const snackbarParams = {
          open: true,
          severity: "success",
          message,
        }
        handleSnackbar(snackbarParams)
      } catch (errors) {
        const {
          message = "Erro ao criar usuário",
          statusText = "",
          statusCode = 422,
          error,
        } = errors
        const snackbarParams = {
          open: true,
          severity: "error",
          message,
          statusText,
          statusCode,
        }
        handleSnackbar(snackbarParams)
        console.error(message, error)
      }
    }
    reset();
    handleModal();
  };

  return (
    <Modal.Root isOpen={modalController === showKey && modalController !== ""}>
      <ModalHeader.Root>
        <ModalHeader.Title title={headerText} />
      </ModalHeader.Root>
      <Modal.Form action={handleSubmit(onSubmit)}>
        <Modal.Container width="20%">
          <Modal.Block>
            <Input.Root
              name="photo"
            >
              <Input.Label
                label="Foto"
                name="photo"
              />
              {!previewImage && <Input.FileDefaultImg />}
              <Input.File
                register={register}
                name="photo"
                action={handleMainPhoto}
                testId="inputPhoto"
              />
              {previewImage && <Input.FileImg border image={previewImage} altText="Foto de Usuário" />}
            </Input.Root>
          </Modal.Block>
          {previewImage && (
            <Modal.Block>
              <Input.ActionButton
                text="Remover Foto"
                action={handleResetPhoto}
              />
            </Modal.Block>
          )}
          {isUpdating && (isUserLogedIn || isAdmin) && (
            <Modal.Block>
              <Input.ActionButton
                text={passwordResetText}
                action={handleResetPassword}
              />
            </Modal.Block>
          )}
        </Modal.Container>
        <Modal.Container width="80%">
          <Modal.Block>
            <Input.Root
              name="name"
            >
              <Input.Label
                label="Nome"
                name="name"
              />
              <Input.Text
                register={register}
                name="name"
                defaultValue={name}
                errors={errors}
                required
                testId="inputName"
              />
            </Input.Root>
            <Input.Root
              name="telephone"
            >
              <Input.Label
                label="Telefone"
                name="telephone"
              />
              <Input.TextWithMask
                register={register}
                name="telephone"
                value={telephone}
                action={maskPhone}
                testId="inputTelephone"
              />
            </Input.Root>
          </Modal.Block>

          <Modal.Block>
            <Input.Root
              name="email"
            >
              <Input.Label
                label="E-mail"
                name="email"
              />
              <Input.Text
                register={register}
                name="email"
                type="email"
                defaultValue={email}
                errors={errors}
                required
                testId="inputEmail"
              />
            </Input.Root>
            {(isCreating || resetPassword) && (
              <Input.Root
                name="password"
              >
                <Input.Label
                  label={passwordText}
                  name="password"
                />
                <Input.Text
                  register={register}
                  name="password"
                  type="password"
                  errors={errors}
                  required
                  testId="inputPassword"
                />
              </Input.Root>
            )}
          </Modal.Block>

          <Modal.Block>
            <Input.Root
              name="cpf"
            >
              <Input.Label
                label="CPF"
                name="cpf"
              />
              <Input.TextWithMask
                value={cpf}
                errors={errors}
                register={register}
                name="cpf"
                required
                action={maskCPF}
                testId="inputCpf"
              />
            </Input.Root>
            <Input.Root
              name="birthday"
            >
              <Input.Label
                label="Aniversário"
                name="birthday"
              />
              <Input.Text
                type="date"
                defaultValue={birthday}
                register={register}
                name="birthday"
                testId="inputBirthday"
              />
            </Input.Root>
          </Modal.Block>

          {isAdmin && (
            <Modal.Block>
              <Input.Root
                name="startDate"
              >
                <Input.Label
                  label="Começou a trabalhar"
                  name="startDate"
                />
                <Input.Text
                  type="date"
                  defaultValue={start_date}
                  register={register}
                  name="start_date"
                  testId="inputStartDate"
                />
              </Input.Root>
              <Input.Root
                name="endDate"
              >
                <Input.Label
                  label="Parou de Trabalhar"
                  name="endDate"
                />
                <Input.Text
                  type="date"
                  defaultValue={end_date}
                  register={register}
                  name="end_date"
                  testId="inputEndDate"
                />
              </Input.Root>
            </Modal.Block>
          )}

          <Modal.Block>
            <Input.Root
              name="salary"
            >
              <Input.Label
                label="Salário"
                name="salary"
              />
              <Input.TextWithMask
                value={salary}
                register={register}
                name="salary"
                action={maskCurrency}
                testId="inputSalary"
              />
            </Input.Root>
            <Input.Root
              name="roles"
            >
              <Input.Label
                label="Cargo"
                name="roles"
              />
              <Input.Select
                defaultValue={user_roles}
                errors={errors}
                register={register}
                name="roles"
                required
                disabled={isUpdating && userRoles > 3}
                dataList={rolesList}
                testId="inputRoles"
              />
            </Input.Root>
          </Modal.Block>

          <Modal.Block>
            <Input.Button type="submit" text={buttonText} />
          </Modal.Block>
        </Modal.Container>
      </Modal.Form>
      <Modal.Close action={handleModal} />
    </Modal.Root>
  );
};

UsersModal.propTypes = {
  showKey: PropTypes.string.isRequired,
  user: PropTypes.object,
  action: PropTypes.string,
};

UsersModal.defaultProps = {
  user: {},
  action: "",
};

export default UsersModal;
