import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"

import FormData from "form-data"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"

import BudgetsViewer from "../"
import { useHandleSnackbar } from "../../../hooks"
import { useUserStore, useProfessionalsStore } from "../../../store"
import { maskPhone } from "../../../utils"
import Input from "../../Common/Modal/Input"

const BudgetsViewerAddProfessional = ({ actionRemove, actionSave }) => {
  const [professional, setProfessional] = useState({})
  const handleSnackbar = useHandleSnackbar()
  const { createProfessionals, updateProfessionals } = useProfessionalsStore()
  const { user: userInfo } = useUserStore()
  const { company: userCompany } = userInfo
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      telephone: "",
      company: userCompany,
    },
  })

  const onSubmit = useCallback(async (data) => {
    const professionalData = new FormData()
    professionalData.append("id", data.id)
    professionalData.append("name", data.name)
    professionalData.append("telephone", data.telephone)
    professionalData.append("company", userCompany)
    professionalData.append("cpf", "")
    professionalData.append("cnpj", "")
    professionalData.append("type_person", "")
    professionalData.append("address", "")
    professionalData.append("number", "")
    professionalData.append("cep", "")
    professionalData.append("complement", "")
    professionalData.append("history", [])
    try {
      const { message, response } = await createProfessionals(professionalData)
      setProfessional(response)
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      }
      handleSnackbar(snackbarParams)
    } catch (errors) {
      const {
        message = "Erro ao criar Profissional",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
    // reset()
  // eslint-disable-next-line max-len
  }, [createProfessionals, handleSnackbar, reset, updateProfessionals, userCompany])

  return (
    <BudgetsViewer.Form action={handleSubmit(onSubmit)}>
      <BudgetsViewer.Table
        bgColor="bg-white"
        width="w-full"
        margin="mt-5"
      >
        <BudgetsViewer.Body>
          <BudgetsViewer.Row>
            <BudgetsViewer.HeaderCell
              type="text"
              text="Dados do Profissional"
              textColor="text-white"
              colSpan="3"
            />
          </BudgetsViewer.Row>
          <BudgetsViewer.Row>
            <BudgetsViewer.HeaderCell
              type="text"
              text="Nome"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Telefone"
              textColor="text-white"
            />
            <BudgetsViewer.HeaderCell
              type="text"
              text="Ações"
              textColor="text-white"
            />
          </BudgetsViewer.Row>
          <BudgetsViewer.Row>
            <BudgetsViewer.Cell type="add">
              <Input.Text
                register={register}
                name="name"
                testId="inputProfessionalName"
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              <Input.TextWithMask
                register={register}
                name="telephone"
                testId="inputProfessionalPhone"
                action={maskPhone}
                errors={errors}
                required
              />
            </BudgetsViewer.Cell>
            <BudgetsViewer.Cell type="add">
              {isEmpty(professional) && (
                <BudgetsViewer.Button
                  text="+"
                  width="w-1/2"
                  bgColor="bg-[#007900]"
                  action={actionSave}
                />
              )}
              <BudgetsViewer.Button
                text="x"
                action={actionRemove}
                bgColor="bg-[#ae3013]"
                width="w-1/2"
              />
            </BudgetsViewer.Cell>
          </BudgetsViewer.Row>
        </BudgetsViewer.Body>
      </BudgetsViewer.Table>
    </BudgetsViewer.Form>
  )
}

BudgetsViewerAddProfessional.propTypes = {
  actionRemove: PropTypes.func.isRequired,
  actionSave: PropTypes.func,
};

BudgetsViewerAddProfessional.defaultProps = {
  actionSave: () => {},
}

export default BudgetsViewerAddProfessional
