import { ClientsDataService } from "../services"
import { CustomError } from "../utils"

export const getAllClients = async (
  company,
  name = "",
  page = 1,
) => {
  try {
    const { data } = await ClientsDataService.getAllClients(
      company,
      name,
      page,
    )
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao pegar lista de Clientes"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const createClients = async (dataFinishes) => {
  try {
    const { data } = await ClientsDataService.createClients(dataFinishes)
    return data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao criar Cliente"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const updateClients = async (id, data) => {
  try {
    const response = await ClientsDataService.updateClients(id, data)
    return response.data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao atualizar Cliente"
    throw new CustomError(message, statusText, status, errors)
  }
}

export const deleteClients = async (id, company) => {
  try {
    const response = await ClientsDataService.deleteClients(id, company)
    return response.data
  } catch (errors) {
    const { response } = errors
    const { status, statusText } = response
    const message = "Erro ao deletar Cliente"
    throw new CustomError(message, statusText, status, errors)
  }
}
