import React from "react"

import { useFetchClientsData } from "../../hooks";
import { useClientsStore } from "../../store"
import Pagination from "../Common/Pagination"

const ClientsPagination = () => {
  const {
    search,
    totalPages,
    pageNumber,
    setPageNumber,
  } = useClientsStore()
  const fetchClientsData = useFetchClientsData();
  const handlePagination = (e, value) => {
    setPageNumber(value)
    fetchClientsData(search, value);
  }
  return (
    <Pagination.Root>
      <Pagination.Number
        totalPages={totalPages}
        pageNumber={pageNumber}
        action={handlePagination}
      />
    </Pagination.Root>
  )
}

export default ClientsPagination
