import { create } from "zustand"
import { persist } from "zustand/middleware";

const useMenuStore = create(persist(
  (set) => ({
    isMenuOpen: false,
    toggleMenu: () => set((state) => ({ isMenuOpen: !state.isMenuOpen })),
  }),
  {
    name: "menu_storage",
    getStorage: () => localStorage,
  },
));

export default useMenuStore
