import React from "react"

import PropTypes from "prop-types"

const BudgetsViewerForm = ({ children, action }) => {
  return (
    <form
      data-testid="BudgetsViewerForm"
      onSubmit={action}
      className="w-full"
    >
      {children}
    </form>
  )
}

BudgetsViewerForm.propTypes = {
  action: PropTypes.func.isRequired,
};

export default BudgetsViewerForm
