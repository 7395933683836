import React from "react"

import PropTypes from "prop-types"

const ModalContainer = ({ children, width }) => {
  return (
    <div
      data-testid="modalContainer"
      className="block relative"
      style={{ width }}
    >
      {children}
    </div>
  )
}

ModalContainer.propTypes = {
  width: PropTypes.string,
};

ModalContainer.defaultProps = {
  width: "100%",
};

export default ModalContainer
