/* eslint-disable consistent-return */
import { create } from "zustand"

import {
  getAllClients,
  createClients,
  updateClients,
  deleteClients,
} from "../actions/clientsActions"
import { sortListBy, CustomError } from "../utils"

const useClientsStore = create((set, get) => ({
  client: {},
  clientsList: [],
  clientsSelect: [],
  totalPages: 1,
  totalItems: 0,
  pageNumber: 1,
  search: "",

  setSearch: (search) => {
    set(() => ({
      search,
    }))
  },
  setPageNumber: (pageNumber) => {
    set(() => ({
      pageNumber,
    }))
  },
  setTotalPages: (totalPages) => {
    set(() => ({
      totalPages,
    }))
  },
  setTotalItems: (totalItems) => {
    set(() => ({
      totalItems,
    }))
  },
  setClientsList: (clientsData) => {
    set(() => ({
      clientsList: clientsData,
    }))
  },
  setClientsSelect: (clientsData) => {
    set(() => ({
      clientsSelect: clientsData,
    }))
  },

  getClients: async (
    company,
    name = "",
    page = 1,
  ) => {
    try {
      const {
        setSearch,
        setClientsList,
        setClientsSelect,
        setTotalPages,
        setTotalItems,
        setPageNumber,
      } = get()

      const response = await getAllClients(
        company,
        name,
        page,
      )
      const {
        totalItems = "0",
        totalPages = "1",
        rows = [],
        currentPage = "1",
      } = response
      let orderedList = []
      let orderedListToSelect = []
      rows.forEach((element) => {
        const responseClients = {
          value: element.id,
          label: element.name,
        }
        orderedList.push(element)
        orderedListToSelect.push(responseClients)
      })
      orderedList = sortListBy(orderedList, "name")
      orderedListToSelect = sortListBy(orderedListToSelect, "label")
      setClientsList(orderedList)
      setClientsSelect(orderedListToSelect)
      setSearch(name)
      setTotalItems(totalItems)
      setTotalPages(totalPages)
      setPageNumber(currentPage)
      const message = "Lista de Clientes retornada com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Clientes",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  createClients: async (clientsData) => {
    try {
      const { clientsList, setClientsList } = get()
      const response = await createClients(clientsData)
      let orderedList = []
      clientsList.push(response)
      orderedList = sortListBy(clientsList, "name")
      setClientsList(orderedList)
      const message = "Cliente criado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao criar Cliente",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  updateClients: async (id, finishesData) => {
    try {
      const { clientsList, setClientsList } = get()
      const response = await updateClients(id, finishesData)
      const { client } = response
      let orderedList = []
      const index = clientsList.findIndex((finishesItem) => finishesItem.id === id)
      clientsList[index] = client
      orderedList = sortListBy(clientsList, "name")
      setClientsList(orderedList)
      const message = "Cliente atualizado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao atualizar Cliente",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },

  deleteClients: async (id, company) => {
    try {
      await deleteClients(id, company)
      const { setClientsList, clientsList } = get()
      const newClientsList = clientsList.filter(
        (finishes) => finishes.id !== id,
      )
      setClientsList(newClientsList)
      const message = "Cliente deletado com sucesso!"
      return { message }
    } catch (errors) {
      const {
        message = "Erro ao deletar Clientes",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      throw new CustomError(message, statusText, statusCode, error)
    }
  },
}))

export default useClientsStore
