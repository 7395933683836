import React from "react"

const ModalHeaderRoot = ({ children }) => {
  return (
    <div
      className="w-full border-b border-0 border-solid border-neutral700"
      data-testid="modalHeaderRoot"
    >
      {children}
    </div>
  )
}

export default ModalHeaderRoot
