import React from "react"

const AdminContainer = ({ children }) => {
  return (
    <section
      className="w-full flex-1 p-4 relative overflow-visible"
      data-testid="adminContainer"
    >
      {children}
    </section>
  )
}

export default AdminContainer
