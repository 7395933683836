import React from "react"
import { Routes, Route } from "react-router-dom"

import AdminPage from "../Pages"
import Login from "../Pages/Login"

const SiteRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/moveis" element={<AdminPage />} />
      <Route path="/usuarios" element={<AdminPage />} />
      <Route path="/categorias" element={<AdminPage />} />
      <Route path="/acabamentos" element={<AdminPage />} />
      <Route path="/clientes" element={<AdminPage />} />
      <Route path="/profissionais" element={<AdminPage />} />
      <Route path="/orcamentos" element={<AdminPage />}>
        <Route path="criar" element={<AdminPage />} />
        <Route path="editar/:id" element={<AdminPage />} />
        <Route path="ver/:id" element={<AdminPage />} />
      </Route>
      <Route path="/tabelas" element={<AdminPage />} />
    </Routes>
  )
}

export default SiteRoutes
