import ProfessionalsContent from "./ProfessionalsContent"
import ProfessionalsFilter from "./ProfessionalsFilter"
import ProfessionalsModal from "./ProfessionalsModal"
import ProfessionalsPagination from "./ProfessionalsPagination"
import ProfessionalsTable from "./ProfessionalsTable"

const Professionals = {
  Content: ProfessionalsContent,
  Filter: ProfessionalsFilter,
  Modal: ProfessionalsModal,
  Pagination: ProfessionalsPagination,
  Table: ProfessionalsTable,
}

export default Professionals
