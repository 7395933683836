const sortListBy = (list, field, order = "asc") => {
  return list.sort((a, b) => {
    if (a[field] < b[field]) {
      return order === "desc" ? -1 : 1;
    }
    if (a[field] > b[field]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });
}

export default sortListBy;
