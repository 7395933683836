import React, { useEffect } from "react";

import Finishes from ".";
import {
  useFetchFinishesData,
} from "../../hooks";
import { useAppStore, useFinishesStore } from "../../store";
import Content from "../Common/Content";

const CategoriesContent = () => {
  const fetchFinishesData = useFetchFinishesData();
  const { pollingController } = useAppStore();
  const {
    search,
    searchMaterial,
    searchFactory,
    searchType,
  } = useFinishesStore();

  useEffect(() => {
    fetchFinishesData()
    let interval
    if (!pollingController) {
      interval = setInterval(() => {
        fetchFinishesData(search, searchMaterial, searchFactory, searchType);
      }, 300000);
    }
    return () => clearInterval(interval)
  }, []);

  return (
    <Content.Root>
      <Finishes.Filter />
      <Finishes.Table />
      <Finishes.Pagination />
    </Content.Root>
  );
};

export default CategoriesContent;
