import { useHandleSnackbar } from "."
import { useUserStore, useClientsStore } from "../store"

const useFetchClientsData = () => {
  const { user } = useUserStore()
  const { getClients, search } = useClientsStore()
  const handleSnackbar = useHandleSnackbar()
  const { company } = user
  const fetchClientsData = async (
    searchValue = search,
    pageNumber = 1,
  ) => {
    try {
      await getClients(
        company,
        searchValue,
        pageNumber,
      )
    } catch (errors) {
      const {
        message = "Erro ao pegar lista de Clientes",
        statusText = "",
        statusCode = 422,
        error,
      } = errors
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      }
      handleSnackbar(snackbarParams)
      console.error(message, error)
    }
  }
  return fetchClientsData
}

export default useFetchClientsData
