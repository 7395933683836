import React from "react";

import PropTypes from "prop-types"

const Button = ({ child, action, ...props }) => {
  return (
    <button
      data-testid="button"
      className="w-full h-12 mt-2 bg-brown font-bold text-base rounded-md hover:opacity-70 text-white uppercase sticky bottom-0 left-0"
      type="button"
      onClick={action}
      {...props}
    >
      {child}
    </button>
  );
}

Button.propTypes = {
  action: PropTypes.string,
  child: PropTypes.string,
};

Button.defaultProps = {
  action: "",
  child: "",
};

export default Button;
