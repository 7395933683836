import React from "react";
import { Link } from "react-router-dom"

import { ADMIN_TYPES } from "../../constants/roleTypes";
import {
  useHandleSnackbar,
} from "../../hooks";
import { useBudgetsStore, useUserStore } from "../../store";
import Content from "../Common/Content";
import UtilsComponents from "../Common/UtilsComponents";

const BudgetsTable = () => {
  const { budgetsList, deleteBudgets } = useBudgetsStore();
  const { user } = useUserStore();

  const handleSnackbar = useHandleSnackbar();

  const { company: companyUser, user_roles: rolesUser } = user;

  const isAdmin = ADMIN_TYPES.includes(rolesUser);

  const handleDeleteBudgets = async (id) => {
    try {
      const { message } = await deleteBudgets(id, companyUser);
      const snackbarParams = {
        open: true,
        severity: "success",
        message,
      };
      handleSnackbar(snackbarParams);
    } catch (errors) {
      const {
        message = "Erro ao deletar Orçamento",
        statusText = "",
        statusCode = 422,
        error,
      } = errors;
      const snackbarParams = {
        open: true,
        severity: "error",
        message,
        statusText,
        statusCode,
      };
      handleSnackbar(snackbarParams);
      console.error(message, error);
    }
  };
  return (
    <Content.Table ariaLabel="Table Budgets">
      <Content.Header>
        <Content.Row key="Budgets Table Header">
          <Content.HeaderCell text="Nº" />
          <Content.HeaderCell text="Cliente" />
          <Content.HeaderCell text="Vendedor" />
          <Content.HeaderCell text="Profissional" />
          <Content.HeaderCell text="Data" />
          <Content.HeaderCell text="Valor" />
          {isAdmin && <Content.HeaderCell text="Ações" />}
        </Content.Row>
      </Content.Header>
      <Content.Body>
        {budgetsList.map((bugdet) => {
          return (
            <Content.Row key={bugdet.id}>
              <Content.Cell type="text" text={bugdet.id} />
              <Content.Cell type="text" text={bugdet.client} />
              <Content.Cell type="text" text={bugdet.attendant} />
              <Content.Cell type="text" text={bugdet.professional} />
              <Content.Cell type="text" text={bugdet.date} />
              <Content.Cell type="text" text={bugdet.value} />
              <Content.Cell type="button" align="right">
                <Link
                  to="/orcamentos"
                >
                  <UtilsComponents.Icon
                    name="Eye"
                    size="24"
                    color="#000000"
                    className="hover:opacity-50"
                    style={{
                      margin: "0 0.25rem",
                    }}
                  />
                </Link>
                <Content.Edit />
                <Content.Delete
                  action={() => handleDeleteBudgets(bugdet.id)}
                />
              </Content.Cell>
            </Content.Row>
          );
        })}
      </Content.Body>
    </Content.Table>
  );
};

export default BudgetsTable;
