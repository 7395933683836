import React, { useEffect } from "react";

import Tables from ".";
import {
  useFetchTablesData,
} from "../../hooks";
import { useTablesStore, useAppStore } from "../../store";
import Content from "../Common/Content";

const TablesContent = () => {
  const { search } = useTablesStore();

  const fetchTablesData = useFetchTablesData();
  const { pollingController } = useAppStore();

  useEffect(() => {
    fetchTablesData();
    let interval
    if (!pollingController) {
      interval = setInterval(() => {
        fetchTablesData(search);
      }, 300000);
    }
    return () => clearInterval(interval)
  }, []);

  return (
    <Content.Root>
      <Tables.Filter />
      <Tables.Table />
      <Tables.Pagination />
    </Content.Root>
  );
};

export default TablesContent;
