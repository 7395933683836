import React from "react"

import { isNull } from "lodash"

import { useMenuStore } from "../../store"
import UtilsComponents from "../Common/UtilsComponents"

const MenuToggleButton = () => {
  const { isMenuOpen, toggleMenu } = useMenuStore()
  const iconType = isNull(isMenuOpen) ? "" : isMenuOpen ? "Close" : "Open"
  switch (iconType) {
  case "Close":
    return (
      <UtilsComponents.Icon
        name="PanelLeftClose"
        size="24"
        color="#000000"
        onClick={toggleMenu}
        data-testid="menuToggleClose"
        className="cursor-pointer absolute bottom-4 right-4 hover:opacity-70"
      />
    )
  case "Open":
    return (
      <UtilsComponents.Icon
        name="PanelRightClose"
        size="24"
        color="#000000"
        onClick={toggleMenu}
        data-testid="menuToggleClose"
        className="cursor-pointer absolute bottom-4 right-4 hover:opacity-70"
      />
    )
  default:
    return null
  }
}

export default MenuToggleButton
