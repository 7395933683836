import React from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";

const InputText = ({
  register,
  name,
  required,
  defaultValue,
  action,
  testId,
  errors,
  type,
  placeholder,
}) => {
  const registerType = required
    ? { ...register(`${name}`, { required: `Recipe ${name} is required` }) }
    : { ...register(`${name}`) };
  const isError = required && !isEmpty(errors);
  return (
    <input
      {...registerType}
      id={name}
      data-testid={testId}
      type={type}
      defaultValue={defaultValue}
      onChange={action}
      name={name}
      placeholder={placeholder}
      className="w-full h-12 p-2 mt-2 border border-solid border-black rounded-md bg-stone200 focus:border-yellow800 hover:border-yellow800"
      style={{
        borderColor: isError ? "#EF4444" : "",
      }}
    />
  );
}

InputText.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  testId: PropTypes.string,
  errors: PropTypes.object,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  action: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
}

InputText.defaultProps = {
  testId: "input-text",
  type: "text",
  errors: {},
  required: false,
  defaultValue: "",
  action: () => {},
  placeholder: "",
}

export default InputText
