import React from "react"

import PropTypes from "prop-types"

const InputButton = ({
  text,
}) => {
  return (
    <button
      data-testid="inputButton"
      className="w-full h-12 mt-3 mx-1 bg-brown font-bold text-base rounded-md hover:opacity-70 text-white uppercase sticky bottom-0 left-0"
      type="submit"
    >
      {text}
    </button>
  )
}

InputButton.propTypes = {
  text: PropTypes.string.isRequired,
}

export default InputButton
