import FurnituresContent from "./FurnituresContent"
import FurnituresFilter from "./FurnituresFilter"
import FurnituresModal from "./FurnituresModal"
import FurnituresPagination from "./FurnituresPagination"
import FurnituresTable from "./FurnituresTable"

const Furnitures = {
  Content: FurnituresContent,
  Filter: FurnituresFilter,
  Modal: FurnituresModal,
  Pagination: FurnituresPagination,
  Table: FurnituresTable,
}

export default Furnitures
