import React from "react"

const ContentHeader = ({ children }) => {
  return (
    <thead
      data-testid="contentHeader"
      className="w-full"
    >
      {children}
    </thead>
  )
}

export default ContentHeader
